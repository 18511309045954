// firebase
import { doc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase";

// utils
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

const completeCertificationRequest = async (tagID, id) => {
  try {
    const reqRef = doc(db, "tagsdata", tagID, "signature_requests", id);
    await updateDoc(reqRef, {
      completed: true,
      completed_on: unixTimestampInSeconds(),
    });
  } catch (error) {
    console.error(`Error in completeCertificationRequest for tdr ${tagID} and request ID ${id}:`, error);
  }
};

export default completeCertificationRequest;
