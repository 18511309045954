// firebase
import { doc, getDoc } from "firebase/firestore";
import { db } from "config/firebase";

// A ---------------------------------------------------------------------- M

const getPublicKey = async (uuid) => {
  try {
    const userRef = doc(db, "usercontacts", uuid);
    const userDoc = await getDoc(userRef);

    return userDoc.exists() ? userDoc.data().public_key ?? "" : "";
  } catch (error) {
    console.error(`Error in getPublicKey for uuid ${uuid}:`, error);
    return "";
  }
};

export default getPublicKey;
