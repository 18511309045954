// react
import React, { useEffect, useState } from "react";

// prop-types
import PropTypes from "prop-types";

// react-i18next
import { useTranslation } from "react-i18next";

// context
import { UserAuth } from "context/AuthContext";

// @mui
import { useMediaQuery, Box, SwipeableDrawer, Dialog, DialogTitle, DialogContent, DialogActions, Grid, Button, IconButton, Typography, TextField, Divider, Tooltip } from "@mui/material";
import { CloseOutlined as CloseOutlinedIcon } from "@mui/icons-material";

// ui-components
import { Puller } from "ui-components/Puller";
import { LoadingDialog } from "ui-components/LoadingComponent";

// group-controller
import postGroup from "group-controller/postGroup";

// A ---------------------------------------------------------------------- M

const getButtonStyle = (action) => ({
  py: 0.5,
  borderColor: "divider",
  color: action === "close" ? "error.main" : "primary.main",
  textTransform: "none",
  boxShadow: "none",
  borderRadius: 10,
  "&:hover": {
    borderColor: action === "close" ? "error.dark" : "primary.dark",
    backgroundColor: "action.hover",
  },
});

const GroupGenerator = ({ open, setOpen, handleSuccessful, handleError }) => {
  const { user } = UserAuth();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [groupname, setGroupname] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Close the modal on browser popstate
  useEffect(() => {
    const handlePopstate = () => {
      if (open) handleReset();
    };
    window.addEventListener("popstate", handlePopstate);
    return () => window.removeEventListener("popstate", handlePopstate);
  }, [open]);

  const handleCreateGroup = async () => {
    setIsLoading(true);
    try {
      await postGroup(user.uid, groupname);
      console.log("Group created successfully");
      handleSuccessful();
    } catch (error) {
      console.error("Error in handleCreateGroup:", error);
      handleError();
    } finally {
      setIsLoading(false);
      handleReset();
    }
  };

  const handleReset = () => {
    setGroupname("");
    setOpen(false);
  };

  const DialogTitleContent = () => (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid item>
        <Typography variant="h6" fontWeight="bold">
          {t("new_group")}
        </Typography>
      </Grid>
      <Grid item>
        <Tooltip title={t("close")} placement="top">
          <IconButton onClick={handleReset} color="error" edge="end">
            <CloseOutlinedIcon />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );

  const innerContent = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField id="groupName" name="groupName" label={t("name")} required variant="outlined" fullWidth value={groupname} onChange={(e) => setGroupname(e.target.value)} />
      </Grid>
    </Grid>
  );

  if (isLoading) return <LoadingDialog open={isLoading} />;

  return isMobile ? (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={handleReset}
      onOpen={() => setOpen(true)}
      sx={{
        "& .MuiDrawer-paper": {
          width: "100%",
          height: "45%",
          borderTopLeftRadius: "4%",
          borderTopRightRadius: "4%",
        },
      }}
      data-testid="group-create-modal"
    >
      <Puller />
      <Box sx={{ p: 3 }}>
        <Typography variant="h5" fontWeight="bold" textAlign="center" mt={2} mb={3}>
          {t("new_group")}
        </Typography>
        {innerContent}
        <Grid container spacing={1} mt={5}>
          <Grid item xs={12}>
            <Button fullWidth variant="outlined" sx={getButtonStyle("close")} onClick={handleReset}>
              {t("cancel")}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button fullWidth variant="outlined" sx={getButtonStyle("open")} onClick={handleCreateGroup} disabled={!groupname}>
              {t("create")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </SwipeableDrawer>
  ) : (
    <Dialog
      open={open}
      onClose={handleReset}
      maxWidth="sm"
      fullWidth
      sx={{
        "& .MuiPaper-root": {
          borderRadius: 2,
          p: 2,
          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
        },
      }}
      data-testid="group-create-modal"
    >
      <DialogTitle>
        <DialogTitleContent />
      </DialogTitle>
      <DialogContent dividers>{innerContent}</DialogContent>
      <DialogActions>
        <Button variant="outlined" sx={getButtonStyle("close")} onClick={handleReset}>
          {t("cancel")}
        </Button>
        <Button variant="outlined" sx={getButtonStyle("open")} onClick={handleCreateGroup} disabled={!groupname}>
          {t("create")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

GroupGenerator.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  handleSuccessful: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
};

export default GroupGenerator;
