// firebase
import { getDocs, query, where, collectionGroup, getDoc } from "firebase/firestore";
import { db } from "config/firebase";

// user-controller
import getUserContacts from "user-controller/getUserContacts";

// A ---------------------------------------------------------------------- M

const getUserGroups = async (userID) => {
  try {
    const usersQuery = query(collectionGroup(db, "users"), where("uid", "==", userID));
    const snapshot = await getDocs(usersQuery);
    const groupPromises = snapshot.docs.map(async (user) => {
      const added_on = user.data().added_on;
      const group = await getDoc(user.ref.parent.parent);
      const id = group.id;
      const data = group.data();
      const creator_contacts = await getUserContacts(data.creator_uuid);
      const creator_email = creator_contacts.email;

      return {
        id,
        added_on,
        creator_email,
        ...data,
      };
    });

    const groups = await Promise.all(groupPromises);
    return groups;
  } catch (error) {
    console.error("Error in getUserGroups:", error.message);
    return [];
  }
};

export default getUserGroups;
