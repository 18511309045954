// firebase
import { collection, getDocs } from "firebase/firestore";
import { db } from "config/firebase";

// datakey-controller
import getDataKeyData from "datakey-controller/getDataKeyData.js";

// A ---------------------------------------------------------------------- M

export const getAuthorizedKeys = async (databoxId) => {
  try {
    const authorizedKeysRef = collection(db, "tagsdata", databoxId, "authorized_keys");
    const querySnapshot = await getDocs(authorizedKeysRef);

    const keys = await Promise.all(
      querySnapshot.docs.map(async (docSnap) => {
        const keyData = docSnap.data();
        const keyId = docSnap.id;
        const additionalData = await getDataKeyData(keyId);
        return { id: keyId, ...keyData, ...additionalData };
      })
    );

    return keys;
  } catch (error) {
    console.error(`Error retrieving authorized keys for databox ${databoxId}:`, error);
    return [];
  }
};
