// react
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// context
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// react-flagkit
import Flag from "react-flagkit";

// @mui
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArchiveIcon from "@mui/icons-material/Archive";
import PlaylistAddCheckOutlinedIcon from "@mui/icons-material/PlaylistAddCheckOutlined";
import GroupsIcon from "@mui/icons-material/Groups";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";

// ui-components
import WilktronicsXArmilisSidebar from "./WilktronicsXArmilisSidebar";
import QRCodeScanner from "ui-components/DataboxManagement/QRCodeScanner";

// A ---------------------------------------------------------------------- M

const Logo = () => {
  const logoSrc = "/logo/wilktronics_x_armilis/wilktronics_x_armilis.jpeg";
  return (
    <Link href="/">
      <img src={logoSrc} alt="Company logo" height="55" style={{ marginLeft: -30 }} />
    </Link>
  );
};

const LanguageOption = ({ country, label }) => (
  <Box display="flex" alignItems="center">
    <Flag country={country} style={{ marginRight: 8 }} />
    {label}
  </Box>
);

const WilktronicsXArmilisLayout = (props) => {
  const { user, language, setLanguage, logout, conservSostL1 } = UserAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [openQRCodeScanner, setOpenQRCodeScanner] = useState(false);
  const [bottomNavValue, setBottomNavValue] = useState(0);

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/signin");
    } catch (e) {
      console.error("Error during logout:", e.message);
    }
  };

  const handleMyAccount = () => {
    navigate("/my-account");
  };

  const handleLanguageChange = (lng) => {
    setLanguage(lng);
  };

  const handleBottomNavChange = (event, newValue) => {
    setBottomNavValue(newValue);
    const routes = ["/", "/my-certifications", "/my-groups", "/qr-scanner", "/my-account"];
    if (routes[newValue] === "/qr-scanner") {
      openQRCodeScannerDrawer();
    } else {
      navigate(routes[newValue]);
    }
  };

  const drawerWidth = 250;
  const appBarStyle = {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "white",
    color: "black",
  };

  const gridItemStyle = isMobile ? { width: "100%", p: "3%", pb: 30 } : { width: `calc(100% - ${drawerWidth}px)`, marginLeft: `${drawerWidth}px`, p: "1%" };

  const openQRCodeScannerDrawer = () => {
    setOpenQRCodeScanner(true);
    window.history.pushState(null, "");
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <AppBar position="fixed" sx={appBarStyle}>
            <Toolbar>
              <Logo />
              <Box sx={{ flexGrow: 1 }} />
              <Select
                value={language}
                onChange={(e) => handleLanguageChange(e.target.value)}
                sx={{
                  color: "black",
                  minWidth: 50,
                  ".MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
              >
                <MenuItem value="it">
                  <LanguageOption country="IT" label="Italiano" />
                </MenuItem>
                <MenuItem value="en">
                  <LanguageOption country="GB" label="English" />
                </MenuItem>
              </Select>
              {!isMobile && user && (
                <Typography
                  variant="body2"
                  sx={{
                    mx: 2,
                    maxWidth: "800px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    color: "black",
                  }}
                  fontWeight="bold"
                  title={user.email}
                >
                  {user.email}
                </Typography>
              )}
              {!isMobile && (
                <Tooltip title={t("my_account")} placement="top">
                  <IconButton color="inherit" onClick={handleMyAccount}>
                    <AccountCircleIcon />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title={t("logout")} placement="top">
                <IconButton color="inherit" onClick={handleLogout}>
                  <LogoutIcon />
                </IconButton>
              </Tooltip>
            </Toolbar>
          </AppBar>
          <Toolbar />
        </Grid>
        <Grid item sx={gridItemStyle}>
          {props.children}
        </Grid>
        {isMobile && (
          <Paper sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}>
            <BottomNavigation
              showLabels
              value={bottomNavValue}
              onChange={handleBottomNavChange}
              sx={{
                bgcolor: "#bdbdbd",
                paddingTop: theme.spacing(0.8),
                paddingBottom: theme.spacing(5),
                boxShadow: "0px -2px 8px rgba(0, 0, 0, 0.3)",
                "& .MuiBottomNavigationAction-root": {
                  color: "black",
                  "&.Mui-selected": {
                    color: "#dd2c00",
                  },
                },
              }}
            >
              <BottomNavigationAction label={conservSostL1 ? t("my_archives") : t("my_databoxes")} icon={<ArchiveIcon />} />
              <BottomNavigationAction label={conservSostL1 ? t("my_documents") : t("my_certifications")} icon={<PlaylistAddCheckOutlinedIcon />} />
              <BottomNavigationAction label={t("my_groups")} icon={<GroupsIcon />} />
              <BottomNavigationAction label={t("scan")} icon={<QrCodeScannerIcon />} onClick={openQRCodeScannerDrawer} />
              <BottomNavigationAction label={t("account")} icon={<AccountCircleIcon />} />
            </BottomNavigation>
          </Paper>
        )}
      </Grid>

      {!isMobile && <WilktronicsXArmilisSidebar drawerWidth={drawerWidth} openQRCodeScannerDrawer={openQRCodeScannerDrawer} />}
      {isMobile && <QRCodeScanner open={openQRCodeScanner} setOpen={setOpenQRCodeScanner} />}
    </>
  );
};

export default WilktronicsXArmilisLayout;
