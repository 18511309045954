// firebase
import { doc, collection, getDoc } from "firebase/firestore";
import { db } from "config/firebase";

// A ---------------------------------------------------------------------- M

const getSeenTag = async (uid, tagID) => {
  try {
    const userRef = doc(db, "userdata", uid);
    const seentagsRef = collection(userRef, "seentags");
    const seentagRef = doc(seentagsRef, tagID);
    const seentagDoc = await getDoc(seentagRef);

    if (!seentagDoc.exists()) {
      return null;
    }

    return {
      id: seentagDoc.id,
      ...seentagDoc.data(),
    };
  } catch (error) {
    console.error(`Error in getSeenTag for uid ${uid} and tagID ${tagID}:`, error);
    return null;
  }
};

export default getSeenTag;
