// firebase
import { doc, getDoc, collection, getDocs } from "firebase/firestore";
import { db } from "config/firebase";

// user-controller
import getUserContacts from "user-controller/getUserContacts";

// datakey-controller
import getDataKeyData from "datakey-controller/getDataKeyData";

// A ---------------------------------------------------------------------- M

const fetchSignature = async (id) => {
  try {
    const sigRef = doc(db, "recordsdata", id);
    const sigDoc = await getDoc(sigRef);

    if (!sigDoc.exists()) {
      console.error(`No record found with ID: ${id}`);
      return null;
    }

    const signatureData = sigDoc.data();
    const creator_uuid = signatureData.creator_uuid;

    let creator_source;

    if (creator_uuid) {
      try {
        const creatorContacts = await getUserContacts(creator_uuid);
        if (creatorContacts.email) {
          creator_source = creatorContacts.email;
        } else {
          const keyData = await getDataKeyData(creator_uuid);
          creator_source = keyData.name || "N/A";
        }
      } catch (error) {
        console.error("Unable to fetch record creator:", error);
      }
    } else {
      creator_source = "N/A";
    }

    const forwardingStatusesRef = collection(sigRef, "forwardingStatuses");
    const forwardingStatusesSnapshot = await getDocs(forwardingStatusesRef);
    const forwardingStatuses = forwardingStatusesSnapshot.docs.map((doc) => doc.data());
    const isForwarded = forwardingStatuses.some((status) => status.forwardingStatus === "success");

    const signatureOutput = {
      id: sigDoc.id,
      creator_email: creator_source,
      public: signatureData.public || false,
      forwardingStatuses,
      isForwarded,
      ...signatureData,
    };

    return signatureOutput;
  } catch (error) {
    console.error("Error in fetchSignature:", error);
    return null;
  }
};

export default fetchSignature;
