// react
import React, { useState, useEffect, useCallback } from "react";

// react-router-dom
import { useParams } from "react-router-dom";

// react-i18next
import { useTranslation } from "react-i18next";

// context
import { UserAuth } from "context/AuthContext";

// @mui
import { useMediaQuery, Grid, Card, CardContent, Typography, CircularProgress } from "@mui/material";

// ui-components
import GroupToolbar from "ui-components/GroupManagement/GroupToolbar";
import MembersTable from "ui-components/GroupManagement/MembersTable";
import SharedTagsTable from "ui-components/GroupManagement/SharedTagsTable";
import AddMember from "ui-components/GroupManagement/AddMember";
import { MemberAddedSuccessfulSnackbar, MemberAddedErrorSnackbar, MemberRemovedSuccessfulSnackbar, MemberRemovedErrorSnackbar } from "ui-components/ORFeedbacks";

// firebase
import { doc, collection, onSnapshot, query, where, collectionGroup } from "firebase/firestore";
import { db } from "config/firebase";

// group-controller
import getGroup from "group-controller/getGroup";
import getMembers from "group-controller/getMembers";

// databox-controller
import getTagsByViewGroup from "databox-controller/getTagsByViewGroup";
import getTagsByViewGroupReadOnly from "databox-controller/getTagsByViewGroupReadOnly";

// A ---------------------------------------------------------------------- M

const GroupSettings = () => {
  const { group: groupID } = useParams();
  const { conservSostL1 } = UserAuth();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [group, setGroup] = useState(null);
  const [members, setMembers] = useState([]);
  const [tags, setTags] = useState([]);
  const [openAddMember, setOpenAddMember] = useState(false);
  const [snackbars, setSnackbars] = useState({
    memberAddedSuccess: false,
    memberAddedError: false,
    memberRemovedSuccess: false,
    memberRemovedError: false,
  });
  const [isLoading, setIsLoading] = useState(true);

  // Fetch group and its members
  const fetchGroupMembers = useCallback(async () => {
    try {
      const groupData = await getGroup(groupID);
      const membersList = await getMembers(groupID);
      setGroup(groupData);
      setMembers(membersList);
    } catch (error) {
      console.error("Error fetching group members:", error);
    }
  }, [groupID]);

  // Fetch tags based on groupID and conservation flag
  const fetchGroupTags = useCallback(async () => {
    try {
      const readwriteTags = await getTagsByViewGroup(groupID);
      const readonlyTags = await getTagsByViewGroupReadOnly(groupID);
      const markedReadwrite = readwriteTags.map((tag) => ({ ...tag, source: "readwrite" }));
      const markedReadonly = readonlyTags.map((tag) => ({ ...tag, source: "readonly" }));
      setTags([...markedReadwrite, ...markedReadonly]);
    } catch (error) {
      console.error("Error fetching group tags:", error);
    }
  }, [groupID]);

  // Main data fetch
  const fetchData = useCallback(async () => {
    await fetchGroupMembers();
    await fetchGroupTags();
    setIsLoading(false);
  }, [fetchGroupMembers, fetchGroupTags]);

  useEffect(() => {
    fetchData();

    const currentTimestamp = Math.floor(Date.now() / 1000).toString();

    const groupDocRef = doc(db, "groupsdata", groupID);
    const usersRef = collection(groupDocRef, "users");
    const newMembersQuery = query(usersRef, where("added_on", ">=", currentTimestamp));
    const unsubscribeMembers = onSnapshot(newMembersQuery, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added" || change.type === "removed") {
          fetchGroupMembers();
        }
      });
    });

    // Tags subscription (using collectionGroup)
    const tagsQuery = conservSostL1
      ? query(collectionGroup(db, "viewgroups_readonly"), where("group_id", "==", groupID), where("added_on", ">", currentTimestamp))
      : query(collectionGroup(db, "viewgroups"), where("group_id", "==", groupID), where("added_on", ">", currentTimestamp));
    const unsubscribeTags = onSnapshot(tagsQuery, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          fetchGroupTags();
        }
      });
    });

    return () => {
      unsubscribeMembers();
      unsubscribeTags();
    };
  }, [groupID, conservSostL1, fetchGroupMembers, fetchGroupTags]);

  // Snackbar handlers
  const handleSnackbar = (type, open) => {
    setSnackbars((prev) => ({ ...prev, [type]: open }));
    window.history.pushState(null, "");
  };

  if (isLoading) {
    return (
      <Grid container justifyContent="center" sx={{ mt: "30%" }}>
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Grid container spacing={5}>
      {group && (
        <>
          <Grid item xs={12}>
            <GroupToolbar group={group} />
          </Grid>

          <Grid item container xs={12} spacing={2}>
            <Grid item container alignItems="center">
              <Grid item xs={12} sm={10}>
                <Typography variant="h6" fontWeight="bold">
                  {t("settings")}
                </Typography>
              </Grid>
            </Grid>

            <Grid item container spacing={5}>
              <Grid item xs={12}>
                <Card variant="outlined" sx={{ borderWidth: 2, borderStyle: "solid", borderRadius: 2 }}>
                  <CardContent>
                    <MembersTable
                      group={group}
                      members={members}
                      setOpen={setOpenAddMember}
                      handleSuccessfulRemoved={() => handleSnackbar("memberRemovedSuccess", true)}
                      handleErrorRemoved={() => handleSnackbar("memberRemovedError", true)}
                    />
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Card variant="outlined" sx={{ borderWidth: 2, borderStyle: "solid", borderRadius: 2 }}>
                  <CardContent>
                    <SharedTagsTable tags={tags} />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          <AddMember
            group={group}
            open={openAddMember}
            setOpen={setOpenAddMember}
            handleSuccessfulAdded={() => handleSnackbar("memberAddedSuccess", true)}
            handleErrorAdded={() => handleSnackbar("memberAddedError", true)}
          />

          {/* Snackbar components */}
          <MemberAddedSuccessfulSnackbar open={snackbars.memberAddedSuccess} setOpen={(open) => handleSnackbar("memberAddedSuccess", open)} />
          <MemberAddedErrorSnackbar open={snackbars.memberAddedError} setOpen={(open) => handleSnackbar("memberAddedError", open)} />
          <MemberRemovedSuccessfulSnackbar open={snackbars.memberRemovedSuccess} setOpen={(open) => handleSnackbar("memberRemovedSuccess", open)} />
          <MemberRemovedErrorSnackbar open={snackbars.memberRemovedError} setOpen={(open) => handleSnackbar("memberRemovedError", open)} />
        </>
      )}
    </Grid>
  );
};

export default GroupSettings;
