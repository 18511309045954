// firebase
import { doc, updateDoc, deleteDoc } from "firebase/firestore";
import { db } from "config/firebase";

// utils
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

export const enableRoute = async (tagID, routeID) => {
  try {
    const routeRef = doc(db, "tagsdata", tagID, "forward_routes", routeID);

    await updateDoc(routeRef, {
      enabled: true,
      last_modified: unixTimestampInSeconds(),
    });
  } catch (error) {
    console.error(`Error in enableRoute for tagID ${tagID} and routeID ${routeID}:`, error);
  }
};

export const disableRoute = async (tagID, routeID) => {
  try {
    const routeRef = doc(db, "tagsdata", tagID, "forward_routes", routeID);

    await updateDoc(routeRef, {
      enabled: false,
      last_modified: unixTimestampInSeconds(),
    });
  } catch (error) {
    console.error(`Error in disableRoute for tagID ${tagID} and routeID ${routeID}:`, error);
  }
};

export const removeRoute = async (routeID, tagID) => {
  try {
    const forwardRouteDocRef = doc(db, "tagsdata", tagID, "forward_routes", routeID);
    await deleteDoc(forwardRouteDocRef);
    console.log(`Document with route ID ${routeID} from tag ID ${tagID} has been successfully deleted.`);
  } catch (error) {
    console.error(`Error in removeRoute for route ID ${routeID} and tag ID ${tagID}:`, error);
  }
};
