// react
import React, { useState, useEffect } from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import { Dialog, DialogTitle, DialogContent, Typography, Divider, Grid } from "@mui/material";
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from "@mui/lab";
import {
  CheckCircle as CheckCircleIcon,
  Verified as VerifiedIcon,
  UploadFile as UploadFileIcon,
  OpenInBrowser as OpenInBrowserIcon,
  GroupAdd as GroupAddIcon,
  GroupRemove as GroupRemoveIcon,
  Key as KeyIcon,
  KeyOff as KeyOffIcon,
  FileOpen as FileOpenIcon,
  FileDownload as FileDownloadIcon,
} from "@mui/icons-material";

// databox-controller
import fetchDataboxEvents from "databox-controller/fetchDataboxEvents";

// utils
import convertTimestamp from "utils/convertTimestamp";

// A ---------------------------------------------------------------------- M

const DataboxLogDialog = ({ isOpen, onClose, databoxID }) => {
  const { t } = useTranslation();

  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (isOpen && databoxID) {
      const loadEvents = async () => {
        const fetchedEvents = await fetchDataboxEvents(databoxID);
        setEvents(fetchedEvents);
      };
      loadEvents();
    }
  }, [isOpen, databoxID]);

  const getIcon = (type) => {
    switch (type) {
      case "event_databox_open_success":
        return <OpenInBrowserIcon color="success" />;
      case "event_archive_open_success":
        return <OpenInBrowserIcon color="success" />;
      case "event_add_viewgroup_success":
        return <GroupAddIcon color="success" />;
      case "event_add_viewgroup_error":
        return <GroupAddIcon color="error" />;
      case "event_remove_viewgroup_success":
        return <GroupRemoveIcon color="success" />;
      case "event_remove_viewgroup_error":
        return <GroupRemoveIcon color="error" />;
      case "event_add_datakey_success":
        return <KeyIcon color="success" />;
      case "event_add_datakey_error":
        return <KeyIcon color="error" />;
      case "event_remove_datakey_success":
        return <KeyOffIcon color="success" />;
      case "event_remove_datakey_error":
        return <KeyOffIcon color="error" />;
      case "event_databox_certification_success":
        return <CheckCircleIcon color="success" />;
      case "event_databox_certification_error":
        return <CheckCircleIcon color="error" />;
      case "event_databox_verification_success":
        return <VerifiedIcon color="success" />;
      case "event_databox_verification_error":
        return <VerifiedIcon color="error" />;
      case "event_archive_upload_success":
        return <UploadFileIcon color="success" />;
      case "event_archive_upload_error":
        return <UploadFileIcon color="error" />;
      case "event_archive_verification_success":
        return <VerifiedIcon color="success" />;
      case "event_archive_verification_error":
        return <VerifiedIcon color="error" />;
      case "event_archive_open_file_success":
        return <FileOpenIcon color="success" />;
      case "event_archive_open_file_error":
        return <FileOpenIcon color="error" />;
      case "event_archive_download_file_success":
        return <FileDownloadIcon color="success" />;
      case "event_archive_download_file_error":
        return <FileDownloadIcon color="error" />;
      default:
        return null;
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          backgroundColor: "rgba(38, 50, 56, 0.95)",
          color: "#ffffff",
          borderRadius: "12px",
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.5)",
        },
      }}
    >
      <DialogTitle sx={{ fontWeight: "bold" }}>Event Log</DialogTitle>
      <DialogContent>
        <Divider sx={{ bgcolor: "#ffa500", mb: 3 }} />
        <Timeline position="left">
          {events.map((event, index) => (
            <TimelineItem key={index} sx={{ marginBottom: 2 }}>
              <TimelineSeparator>
                <TimelineDot>{getIcon(event.eventType)}</TimelineDot>
                {index < events.length - 1 && <TimelineConnector sx={{ bgcolor: "#ffa500" }} />}
              </TimelineSeparator>
              <TimelineContent sx={{ minWidth: "600px" }}>
                {event.eventType && (
                  <Typography variant="body2" fontWeight="bold">
                    {t(event.eventType).toUpperCase()}
                  </Typography>
                )}
                {event.timestamp && (
                  <Typography variant="subtitle2" fontWeight="bold">
                    {convertTimestamp(event.timestamp)}
                  </Typography>
                )}
                <Grid container>
                  {event.description && (
                    <Grid item xs={12}>
                      <Typography variant="caption" sx={{ color: "#b0bec5" }}>
                        {event.description}
                      </Typography>
                    </Grid>
                  )}
                  {event.metadata?.certificationID && (
                    <Grid item xs={12}>
                      <Typography variant="caption" sx={{ color: "#b0bec5" }}>
                        Certification ID: {event.metadata?.certificationID}
                      </Typography>
                    </Grid>
                  )}
                  {event.metadata?.documentID && (
                    <Grid item xs={12}>
                      <Typography variant="caption" sx={{ color: "#b0bec5" }}>
                        Document ID: {event.metadata?.documentID}
                      </Typography>
                    </Grid>
                  )}
                  {event.performedBy && (
                    <Grid item xs={12}>
                      <Typography variant="caption" sx={{ color: "#b0bec5" }}>
                        Performed By: {event.performedBy}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </DialogContent>
    </Dialog>
  );
};

export default DataboxLogDialog;
