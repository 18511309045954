// firebase
import { doc, setDoc, deleteDoc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase";

// event-logger
import logEvent from "event-logger/logEvent";

// utils
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

export const addAuthorizedKey = async (tagID, keyID, userID) => {
  const timestamp = unixTimestampInSeconds();
  const timestampMs = Date.now().toString();

  try {
    const authorizedKeyRef = doc(db, `tagsdata/${tagID}/authorized_keys`, keyID);

    await setDoc(authorizedKeyRef, {
      key_id: keyID,
      added_on: timestamp,
      lastEmittedPacket: "",
      lastUpdated: timestampMs,
      prevState: "",
      status: "offline",
    });

    await logEvent(tagID, "event_add_datakey_success", timestamp, `${keyID} added to ${tagID} successfully.`, null, userID, null, "success");
  } catch (error) {
    console.error(`Error in addAuthorizedKey for tagID ${tagID} and keyID ${keyID}:`, error);
    await logEvent(tagID, "event_add_datakey_error", timestamp, `${keyID} added to ${tagID} failed.`, null, userID, null, "success");
  }
};

export const removeAuthorizedKey = async (tagID, keyID, userID) => {
  const timestamp = unixTimestampInSeconds();

  try {
    const authorizedKeyRef = doc(db, "tagsdata", tagID, "authorized_keys", keyID);
    await deleteDoc(authorizedKeyRef);
    await logEvent(tagID, "event_remove_datakey_success", timestamp, `${keyID} removed from ${tagID} successfully.`, null, userID, null, "success");
  } catch (error) {
    console.error(`Error in removeAuthorizedKey for tagID ${tagID} and keyID ${keyID}:`, error.message);
    await logEvent(tagID, "event_remove_datakey_error", timestamp, `${keyID} removed from ${tagID} failed.`, null, userID, null, "error");
  }
};

export const updateKeyTimeout = async (databoxId, keyId, timeout) => {
  try {
    const keyDocRef = doc(db, "tagsdata", databoxId, "authorized_keys", keyId);
    await updateDoc(keyDocRef, { timeout });
    console.log(`Timeout updated for key ${keyId} in databox ${databoxId}: ${timeout} ms`);
  } catch (error) {
    console.error(`Error updating timeout for key ${keyId} in databox ${databoxId}:`, error);
  }
};
