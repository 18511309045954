import React from "react";
import { Chip } from "@mui/material";

// A ---------------------------------------------------------------------- M

export const getStatusChip = (status) => {
  switch (status) {
    case "online":
      return <Chip label="Online" sx={{ backgroundColor: "#00c853", color: "#fff", fontWeight: "bold" }} />;
    case "recovered":
      return <Chip label="Recovered" sx={{ backgroundColor: "#FFA500", color: "#fff", fontWeight: "bold" }} />;
    case "offline":
      return <Chip label="Offline" sx={{ backgroundColor: "#f44336", color: "#fff", fontWeight: "bold" }} />;
    default:
      return <Chip label="Offline" sx={{ backgroundColor: "#f44336", color: "#fff", fontWeight: "bold" }} />;
  }
};

export const getEdgeLabel = (status) =>
  ({
    online: "✔",
    recovered: "↺",
  }[status] || "✖");

export const getEdgeColor = (status) =>
  ({
    online: "#00c853",
    recovered: "#FFA500",
  }[status] || "#f44336");

export const getLabelStyle = (status) => ({
  fill: getEdgeColor(status),
  fontSize: 20,
  fontWeight: "bold",
});

export const getHandleStyle = (status) => {
  const baseStyles = {
    border: "2px solid #ffffff",
    boxShadow: `0 0 6px ${getEdgeColor(status)}`,
  };

  return {
    ...baseStyles,
    background:
      {
        online: "linear-gradient(90deg, #4caf50, #81c784)",
        recovered: "linear-gradient(90deg, #FFA500, #FFC107)",
        offline: "linear-gradient(90deg, #f44336, #e57373)",
      }[status] || "#ccc",
  };
};

export const getGradient = (status, isHovered) => {
  switch (status) {
    case "online":
      return isHovered ? "linear-gradient(135deg, #2e7d32, #66bb6a)" : "linear-gradient(135deg, rgba(46,125,50,0.9), rgba(102,187,106,0.9))";
    case "recovered":
      return isHovered ? "linear-gradient(135deg, #ef6c00, #ff9800)" : "linear-gradient(135deg, rgba(239,108,0,0.9), rgba(255,152,0,0.9))";
    case "offline":
      return isHovered ? "linear-gradient(135deg, #c62828, #e53935)" : "linear-gradient(135deg, rgba(198,40,40,0.9), rgba(229,57,53,0.9))";
    default:
      return isHovered ? "linear-gradient(135deg, #c62828, #e53935)" : "linear-gradient(135deg, rgba(198,40,40,0.9), rgba(229,57,53,0.9))";
  }
};
