// firebase
import { doc, getDoc } from "firebase/firestore";
import { db } from "config/firebase";

// A ---------------------------------------------------------------------- M

const getDataKeyData = async (keyID) => {
  try {
    const keyDocRef = doc(db, "keydata", keyID);
    const keyDocSnap = await getDoc(keyDocRef);

    if (!keyDocSnap.exists()) {
      return {
        name: "",
        owner_uuid: false,
        created_on: "",
      };
    }

    const { name = "", owner_uuid = false, created_on = "" } = keyDocSnap.data();
    return { name, owner_uuid, created_on };
  } catch (error) {
    console.error(`Error in getDataKeyData for keyID ${keyID}:`, error);
    return {
      name: "",
      owner_uuid: false,
      created_on: "",
    };
  }
};

export default getDataKeyData;
