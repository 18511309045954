// react
import React from "react";

// @mui
import { Grid, Button, Typography, Divider } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";

// ui-components
import MetadataField from "./MetadataField";
import SoggettoForm from "./SoggettoForm";
import { metadataSchemaUpload, metadataSections } from "./metadataSchema";

// A ---------------------------------------------------------------------- M

const getButtonStyle = (action) => ({
  py: 0.5,
  borderColor: "divider",
  color: action === "close" ? "error.main" : "primary.main",
  textTransform: "none",
  boxShadow: "none",
  borderRadius: 10,
  "&:hover": {
    borderColor: action === "close" ? "error.dark" : "primary.dark",
    backgroundColor: "action.hover",
  },
});

const CDNFileFormUpload = ({ uploadProperties, setUploadProperties, handleChangeUploadProperties, handleClickOpen }) => {
  const handleAddSoggetto = () => {
    setUploadProperties({
      ...uploadProperties,
      soggetti: [
        ...uploadProperties.soggetti,
        {
          ruolo: "",
          tipoSoggetto: "",
          cognome: "",
          nome: "",
          codiceFiscale: "",
          partitaIva: "",
          indirizziDigitaliDiRiferimento: "",
          denominazioneOrganizzazione: "",
          denominazioneUfficio: "",
          denominazioneAmministrazioneCodiceIPA: "",
          denominazioneAmministrazioneAOOCodiceIPAAOO: "",
          denominazioneAmministrazioneUORCodiceIPAUOR: "",
          denominazioneAmministrazione: "",
          denominazioneSistema: "",
        },
      ],
    });
  };

  const handleSoggettoChange = (index, field, value) => {
    const updatedSoggetti = uploadProperties.soggetti.map((soggetto, i) => (i === index ? { ...soggetto, [field]: value } : soggetto));
    setUploadProperties({ ...uploadProperties, soggetti: updatedSoggetti });
  };

  const handleRemoveSoggetto = (index) => {
    const updatedSoggetti = uploadProperties.soggetti.filter((_, i) => i !== index);
    setUploadProperties({ ...uploadProperties, soggetti: updatedSoggetti });
  };

  return (
    <Grid container spacing={3}>
      {metadataSections.map((section) =>
        section.title === "Soggetti" ? (
          <Grid item container spacing={2} key={section.title}>
            <Grid item xs={12}>
              <Typography variant="h6" fontWeight="bold" color="text.primary">
                {section.title}
              </Typography>
              <Divider sx={{ mt: 1, mb: 2 }} />
            </Grid>
            {uploadProperties.soggetti.map((soggetto, index) => (
              <SoggettoForm
                key={index}
                soggetto={soggetto}
                index={index}
                soggettoFields={section.fields}
                handleSoggettoChange={handleSoggettoChange}
                handleRemoveSoggetto={handleRemoveSoggetto}
                handleClickOpen={handleClickOpen}
              />
            ))}
            <Grid item xs={12}>
              <Button variant="outlined" onClick={handleAddSoggetto} startIcon={<AddIcon />} sx={getButtonStyle("open")}>
                Soggetto
              </Button>
            </Grid>
          </Grid>
        ) : (
          <React.Fragment key={section.title}>
            <Grid item xs={12}>
              <Typography variant="h6" fontWeight="bold" color="text.primary">
                {section.title}
              </Typography>
              <Divider sx={{ mt: 1, mb: 2 }} />
            </Grid>
            {section.fields.map((fieldKey) => (
              <MetadataField
                key={fieldKey}
                fieldKey={fieldKey}
                metadata={metadataSchemaUpload}
                documentProperties={uploadProperties}
                onChange={handleChangeUploadProperties}
                handleClickOpen={handleClickOpen}
              />
            ))}
          </React.Fragment>
        )
      )}
    </Grid>
  );
};

export default CDNFileFormUpload;
