// react
import React, { useState, useEffect, useCallback } from "react";

// context
import { UserAuth } from "context/AuthContext";

// @mui
import { useMediaQuery, Grid, Fab, CircularProgress } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";

// ui-components
import GroupsTable from "ui-components/GroupManagement/GroupsTable";
import GroupGenerator from "ui-components/GroupManagement/GroupGenerator";
import { GroupCreationSuccessfulSnackbar, GroupCreationErrorSnackbar } from "ui-components/ORFeedbacks";

// firebase
import { onSnapshot, query, where, collectionGroup, getDoc } from "firebase/firestore";
import { db } from "config/firebase";

// group-controller
import getUserGroups from "group-controller/getUserGroups";

// user-controller
import getUserContacts from "user-controller/getUserContacts";

// A ---------------------------------------------------------------------- M

const MyGroups = () => {
  const { user, canCreateGroup, conservSostL1 } = UserAuth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [groups, setGroups] = useState([]);
  const [openCreateGroup, setOpenCreateGroup] = useState(false);
  const [openGroupCreationSuccessful, setOpenGroupCreationSuccessful] = useState(false);
  const [openGroupCreationError, setOpenGroupCreationError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Fetch groups and sort them by added_on descending.
  const fetchGroups = useCallback(async () => {
    if (!user) return;
    try {
      setIsLoading(true);
      const fetchedGroups = await getUserGroups(user.uid);
      fetchedGroups.sort((a, b) => b.added_on - a.added_on);
      setGroups(fetchedGroups);
    } catch (error) {
      console.error("Error in getUserGroups:", error.message);
    } finally {
      setIsLoading(false);
    }
  }, [user]);

  useEffect(() => {
    if (user?.uid) {
      fetchGroups();

      // Listen for new group additions in real time.
      const currentTimestamp = Math.floor(Date.now() / 1000).toString();
      const newGroupsQuery = query(collectionGroup(db, "users"), where("uid", "==", user.uid), where("added_on", ">=", currentTimestamp));

      const unsubscribe = onSnapshot(newGroupsQuery, async (snapshot) => {
        // Process only added documents.
        const newGroups = await Promise.all(
          snapshot
            .docChanges()
            .filter((change) => change.type === "added")
            .map(async (change) => {
              const userDoc = change.doc.data();
              const added_on = parseInt(userDoc.added_on, 10);
              const groupDocRef = change.doc.ref.parent.parent;
              const groupDoc = await getDoc(groupDocRef);
              if (!groupDoc.exists()) return null;
              const groupData = groupDoc.data();
              const creatorContacts = await getUserContacts(groupData.creator_uuid);
              return {
                id: groupDoc.id,
                added_on,
                creator_email: creatorContacts.email,
                ...groupData,
              };
            })
        );
        const filtered = newGroups.filter(Boolean);
        if (filtered.length > 0) {
          setGroups((prev) => [...filtered, ...prev].sort((a, b) => b.added_on - a.added_on));
        }
      });
      return () => unsubscribe();
    }
  }, [user, fetchGroups]);

  const handleOpenGroupGenerator = () => {
    setOpenCreateGroup(true);
    window.history.pushState(null, "");
  };

  const handleOpenGroupCreationSuccessful = () => {
    setOpenGroupCreationSuccessful(true);
    window.history.pushState(null, "");
  };

  const handleOpenGroupCreationError = () => {
    setOpenGroupCreationError(true);
    window.history.pushState(null, "");
  };

  if (isLoading) {
    return (
      <Grid item container justifyContent="center" mt="30%">
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <>
      {groups && <GroupsTable groups={groups} handleOpenGroupGenerator={handleOpenGroupGenerator} />}
      {(canCreateGroup || conservSostL1) && (
        <>
          <GroupGenerator open={openCreateGroup} setOpen={setOpenCreateGroup} handleSuccessful={handleOpenGroupCreationSuccessful} handleError={handleOpenGroupCreationError} />
          <GroupCreationSuccessfulSnackbar open={openGroupCreationSuccessful} setOpen={setOpenGroupCreationSuccessful} />
          <GroupCreationErrorSnackbar open={openGroupCreationError} setOpen={setOpenGroupCreationError} />
        </>
      )}
      {isMobile && (canCreateGroup || conservSostL1) && (
        <Fab color="primary" aria-label="add-group" sx={{ position: "fixed", bottom: 120, right: 16 }} onClick={handleOpenGroupGenerator}>
          <AddIcon />
        </Fab>
      )}
    </>
  );
};

export default MyGroups;
