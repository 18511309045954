// react
import React, { useState, useEffect } from "react";

// @mui
import { Grid, TextField, Button, CircularProgress, Snackbar, Alert, Typography, Slide, Backdrop } from "@mui/material";

// components
import DataCard from "./DataCard";

// databox-controller
import { getAuthorizedKeys } from "databox-controller/getAuthorizedKeys";
import { updateKeyTimeout } from "databox-controller/manageAuthorizedKey";

// utils
import { msToHoursMinutes, hoursMinutesToMs } from "utils/timeUtils";

// A ---------------------------------------------------------------------- M

const Transition = (props) => <Slide {...props} direction="left" />;

const SourcesConfiguration = ({ tag }) => {
  const [sources, setSources] = useState([]);
  const [loading, setLoading] = useState(true);
  const [globalUpdating, setGlobalUpdating] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSources = async () => {
      try {
        const keys = await getAuthorizedKeys(tag);
        const keysWithTime = keys.map((key) => {
          const timeout = key.timeout || 14400000;
          const { hours, minutes } = msToHoursMinutes(timeout);
          return { ...key, timeoutHours: hours, timeoutMinutes: minutes };
        });
        setSources(keysWithTime);
      } catch (error) {
        setError("Error retrieving sources.");
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchSources();
  }, [tag]);

  const handleHoursChange = (keyId, newHours) => {
    setSources((prevSources) => prevSources.map((source) => (source.id === keyId ? { ...source, timeoutHours: newHours } : source)));
  };

  const handleMinutesChange = (keyId, newMinutes) => {
    setSources((prevSources) => prevSources.map((source) => (source.id === keyId ? { ...source, timeoutMinutes: newMinutes } : source)));
  };

  const handleSave = async (keyId) => {
    try {
      setGlobalUpdating(true);
      const source = sources.find((s) => s.id === keyId);
      const newTimeoutMs = hoursMinutesToMs(source.timeoutHours, source.timeoutMinutes);
      await updateKeyTimeout(tag, keyId, newTimeoutMs);
      setSnackbar({
        open: true,
        message: `Timeout updated for source <${source.name || keyId}>`,
        severity: "success",
      });
    } catch (error) {
      console.error("Error updating timeout:", error);
      setSnackbar({
        open: true,
        message: `Error updating timeout for source <${keyId}>`,
        severity: "error",
      });
    } finally {
      setGlobalUpdating(false);
    }
  };

  return (
    <>
      {error ? (
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      ) : (
        <Grid container spacing={3}>
          {sources.map((source) => (
            <Grid item xs={12} sm={6} md={4} key={source.id}>
              <DataCard title={source.name || source.id}>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  Current timeout: {source.timeoutHours} hour(s) and {source.timeoutMinutes} minute(s)
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      label="Hours"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      type="number"
                      value={source.timeoutHours}
                      onChange={(e) => handleHoursChange(source.id, e.target.value)}
                      inputProps={{ min: 0 }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Minutes"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      type="number"
                      value={source.timeoutMinutes}
                      onChange={(e) => handleMinutesChange(source.id, e.target.value)}
                      inputProps={{ min: 0, max: 59 }}
                    />
                  </Grid>
                </Grid>
                <Button variant="contained" color="primary" onClick={() => handleSave(source.id)} disabled={globalUpdating} fullWidth>
                  {"Save"}
                </Button>
              </DataCard>
            </Grid>
          ))}
        </Grid>
      )}

      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading || globalUpdating}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        TransitionComponent={Transition}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{ mt: { md: "2.5%" } }}
      >
        <Alert
          severity={snackbar.severity}
          variant="filled"
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          sx={{
            width: "100%",
            borderRadius: 2,
            boxShadow: 3,
            py: 1.5,
            px: 2,
            display: "flex",
            alignItems: "center",
            fontSize: "1rem",
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SourcesConfiguration;
