// firebase
import { doc, getDoc } from "firebase/firestore";
import { db } from "config/firebase";

// A ---------------------------------------------------------------------- M

const isCertificationRequestCompleted = async (tagID, requestID) => {
  try {
    const requestRef = doc(db, "tagsdata", tagID, "signature_requests", requestID);
    const requestSnap = await getDoc(requestRef);
    return requestSnap.exists() && requestSnap.data().completed === true;
  } catch (error) {
    console.error(`Error in isCertificationRequestCompleted for tagID ${tagID} and requestID ${requestID}:`, error);
    return false;
  }
};

export default isCertificationRequestCompleted;
