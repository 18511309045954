import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import formatDate from "utils/formatDate";
import { getStatusChip } from "./utils/styleGetters";

// A ---------------------------------------------------------------------- M

const FlowEventsTable = ({ flowEventsData }) => {
  const { t } = useTranslation();

  const headers = useMemo(() => [t("name"), t("timestamp"), t("transition")], [t]);

  return (
    <Table>
      <TableHead>
        <TableRow>
          {headers.map((header) => (
            <TableCell key={header} sx={{ fontWeight: "bold" }}>
              {header}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {flowEventsData.length > 0 ? (
          flowEventsData
            .filter((event) => event.previousState && event.state)
            .map((event) => (
              <TableRow key={event.id} hover>
                <TableCell>{event.sourceName}</TableCell>
                <TableCell>{event.timestamp ? formatDate(event.timestamp) : "N/A"}</TableCell>
                <TableCell>
                  <Box display="flex" alignItems="center">
                    {getStatusChip(event.previousState)}
                    <ArrowForwardIosIcon sx={{ fontSize: "1rem", mx: 1 }} />
                    {getStatusChip(event.state)}
                  </Box>
                </TableCell>
              </TableRow>
            ))
        ) : (
          <TableRow>
            <TableCell colSpan={3} align="center">
              {t("no_flow_events_available")}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default FlowEventsTable;
