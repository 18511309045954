// firebase
import { doc, collection, setDoc, getDoc, updateDoc, arrayUnion, deleteDoc } from "firebase/firestore";
import { db } from "config/firebase";

// utils
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

export const addAlarmUser = async (userID, tagID) => {
  try {
    const tagDocRef = doc(db, "tagsdata", tagID);
    const tagDocSnapshot = await getDoc(tagDocRef);

    if (tagDocSnapshot.exists()) {
      await updateDoc(tagDocRef, {
        alarmUsers: arrayUnion(userID),
      });
      console.log(`Alarm user added to ${tagID}`);
    }
  } catch (error) {
    console.error(`Error in addAlarmUser for uid ${userID} and tag ${tagID}:`, error);
  }
};

export const removeAlarmUser = async (userID, tagID) => {
  try {
    const tagDocRef = doc(db, "tagsdata", tagID);
    const tagDocSnapshot = await getDoc(tagDocRef);

    if (tagDocSnapshot.exists()) {
      const { alarmUsers = [] } = tagDocSnapshot.data();

      if (alarmUsers.includes(userID)) {
        const updatedAlarmUsersArray = alarmUsers.filter((alarmUserUid) => alarmUserUid !== userID);
        await updateDoc(tagDocRef, { alarmUsers: updatedAlarmUsersArray });
        console.log(`Alarm user removed from ${tagID}`);
      }
    }
  } catch (error) {
    console.error(`Error in removeAlarmUser for uid ${userID} and tag ${tagID}:`, error);
  }
};

export const createAlarmTrigger = async (databoxID, userID, trigger) => {
  try {
    const triggerRef = doc(collection(db, "tagsdata", databoxID, "triggers"));
    await setDoc(triggerRef, {
      ...trigger,
      createdAt: unixTimestampInSeconds(),
      createdBy: userID,
    });
    console.log("Trigger created successfully!");
  } catch (error) {
    console.error("Error creating trigger:", error);
    throw new Error("Failed to create trigger");
  }
};

export const deleteAlarmTrigger = async (databoxID, triggerID) => {
  try {
    const triggerRef = doc(db, "tagsdata", databoxID, "triggers", triggerID);
    await deleteDoc(triggerRef);
    console.log("Trigger deleted successfully!");
  } catch (error) {
    console.error("Error deleting trigger:", error);
    throw new Error("Failed to delete trigger");
  }
};

export const hasUserAlarmOn = async (userID, tagID) => {
  try {
    const tagDocRef = doc(db, "tagsdata", tagID);
    const tagDocSnapshot = await getDoc(tagDocRef);

    if (!tagDocSnapshot.exists()) {
      return false;
    }

    return tagDocSnapshot.data()?.alarmUsers?.includes(userID) || false;
  } catch (error) {
    console.error(`Error in hasUserAlarmOn for uid ${userID} and tag ${tagID}:`, error);
    return false;
  }
};