import React from "react";
import { Table, TableBody, TableCell, TableHead, TableRow, Skeleton } from "@mui/material";

// A ---------------------------------------------------------------------- M

const SkeletonTable = ({ rows, columns }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          {Array.from({ length: columns }).map((_, idx) => (
            <TableCell key={idx}>
              <Skeleton width="80%" />
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {Array.from({ length: rows }).map((_, idx) => (
          <TableRow key={idx}>
            {Array.from({ length: columns }).map((_, colIdx) => (
              <TableCell key={colIdx}>
                <Skeleton width="60%" />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default SkeletonTable;
