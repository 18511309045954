// react
import React, { useState, useMemo } from "react";

// prop-types
import PropTypes from "prop-types";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import { Box, Grid, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, TableFooter, TablePagination, IconButton, Typography, useMediaQuery, useTheme, Tooltip } from "@mui/material";
import { KeyboardArrowLeftOutlined as KeyboardArrowLeftOutlinedIcon, KeyboardArrowRightOutlined as KeyboardArrowRightOutlinedIcon, Edit as EditIcon } from "@mui/icons-material";

// config
import documentMapping from "../../config/documentMapping.json";

// A ---------------------------------------------------------------------- M

const getDocumentTypeLabel = (code) => {
  return documentMapping[code] || code;
};

const ClientPoliciesToolbar = () => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
      <Grid item xs={12} sm={6}>
        <Typography variant="h6" fontWeight="bold">
          {t("policies")}
        </Typography>
      </Grid>
    </Grid>
  );
};

const ClientPoliciesHead = () => {
  const { t } = useTranslation();

  const headCells = useMemo(
    () => [
      { id: "tipologiaDocumentale", label: t("document_type") },
      { id: "condition", label: t("condition") },
      { id: "actions", label: t("actions") },
    ],
    [t]
  );

  return (
    <TableHead sx={{ backgroundColor: "grey.100" }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.id !== "actions" ? "left" : "right"}>
            <Typography variant="body2" fontWeight="bold">
              {headCell.label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const theme = useTheme();
  const isRtl = theme.direction === "rtl";

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={(e) => onPageChange(e, page - 1)} disabled={page === 0} aria-label="previous page">
        {isRtl ? <KeyboardArrowRightOutlinedIcon /> : <KeyboardArrowLeftOutlinedIcon />}
      </IconButton>
      <IconButton onClick={(e) => onPageChange(e, page + 1)} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        {isRtl ? <KeyboardArrowLeftOutlinedIcon /> : <KeyboardArrowRightOutlinedIcon />}
      </IconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const DataRow = ({ agreement, clickEdit }) => {
  const { t } = useTranslation();

  // Mappa il valore della condition in una stringa leggibile
  const conditionDisplay = agreement.condition === "end_of_month" ? t("Monthly") : agreement.condition === "end_of_year" ? t("Yearly") : agreement.condition || "N/A";

  return (
    <TableRow>
      <TableCell align="left">
        {agreement.tipologiaDocumentale ? getDocumentTypeLabel(agreement.tipologiaDocumentale) : agreement.default === true ? "DEFAULT ALL TYPES - ID_POLICY_67890" : "N/A"}
      </TableCell>
      <TableCell align="left">{conditionDisplay}</TableCell>
      <TableCell align="right" size="small">
        <Tooltip title={t("edit")} placement="top">
          <IconButton color="primary" onClick={() => clickEdit(agreement)}>
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

const ClientPolicies = ({ agreements, clickEdit }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - agreements.length) : 0;

  return (
    <Box>
      <ClientPoliciesToolbar />
      <TableContainer>
        <Table>
          <ClientPoliciesHead />
          <TableBody>
            {(rowsPerPage > 0 ? agreements.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : agreements).map((agreement) => {
              return <DataRow key={agreement.id} agreement={agreement} clickEdit={clickEdit} />;
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={5} />
              </TableRow>
            )}
          </TableBody>
          {!isMobile && (
            <TableFooter sx={{ backgroundColor: "grey.100" }}>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20]}
                  colSpan={3}
                  count={agreements.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={t("rows_per_page")}
                  labelDisplayedRows={({ from, to, count }) => t("displayed_rows", { from, to, count: count !== -1 ? count : `more than ${to}` })}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};

ClientPolicies.propTypes = {
  agreements: PropTypes.array.isRequired,
  clickEdit: PropTypes.func.isRequired,
};

export default ClientPolicies;
