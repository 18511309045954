// react
import React, { useState, useEffect } from "react";

// react-router-dom
import { useLocation } from "react-router-dom";

// context
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

// ui-components
import AMGSidebar from "./AMGSidebar";
import QRCodeScanner from "ui-components/DataboxManagement/QRCodeScanner";

// databox-controller
import getTagInfo from "databox-controller/getTagInfo";

// group-controller
import getGroup from "group-controller/getGroup";

// A ---------------------------------------------------------------------- M

const drawerWidth = 243;

const boxStyle = {
  backgroundColor: "rgba(241, 241, 241, 1)",
  height: "45px",
  padding: "5px",
  display: "flex",
  alignItems: "center",
};

const AMGLayout = (props) => {
  const { conservSostL1 } = UserAuth();
  const location = useLocation();
  const { t } = useTranslation();

  const [openQRCodeScanner, setOpenQRCodeScanner] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [pageTitle, setPageTitle] = useState("");

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const openQRCodeScannerDrawer = () => {
    setOpenQRCodeScanner(true);
    window.history.pushState(null, "");
  };

  const gridStyle = drawerOpen
    ? {
        width: `calc(100% - ${drawerWidth + 32}px)`,
        marginLeft: `${drawerWidth + 25}px`,
      }
    : {
        width: "100%",
      };

  useEffect(() => {
    const fetchPageTitle = async () => {
      const pathnames = location.pathname.split("/").filter((x) => x);
      let title = "";

      if (pathnames.length > 0) {
        const firstSegment = `/${pathnames[0]}`;

        if (firstSegment === "/my-certifications") {
          title = conservSostL1 ? t("my_documents") : t("my_certifications");
        } else if (firstSegment === "/my-groups") {
          title = t("my_groups");
        } else if (firstSegment === "/my-account") {
          console.log(firstSegment);
          title = t("my_account");
        } else {
          const firstSegment = pathnames[0];

          const isGroupRoute = pathnames.length > 1 && ["databoxes", "members"].includes(pathnames[1]);

          if (isGroupRoute) {
            const groupInfo = await getGroup(firstSegment);
            const groupName = groupInfo.name || "N/A";

            if (pathnames[1] === "databoxes") {
              title = `${groupName}`;
            } else if (pathnames[1] === "members") {
              title = `${groupName}`;
            }
          } else {
            const tagInfo = await getTagInfo(firstSegment);
            const tagName = tagInfo.name || "N/A";

            if (pathnames.length === 1) {
              title = tagName;
            } else if (pathnames.length > 1 && pathnames[1] === "settings") {
              title = `${tagName}`;
            } else if (pathnames.length > 1 && pathnames[1] === "monitor") {
              title = `${tagName}`;
            }
          }
        }
      } else {
        title = conservSostL1 ? t("my_archives") : t("my_databoxes");
      }

      setPageTitle(title);
    };

    fetchPageTitle();
  }, [location]);

  return (
    <>
      {drawerOpen && <AMGSidebar drawerWidth={drawerWidth} openQRCodeScannerDrawer={openQRCodeScannerDrawer} handleDrawerToggle={handleDrawerToggle} drawerOpen={drawerOpen} />}
      <Grid container sx={gridStyle}>
        {!drawerOpen && (
          <Grid item xs={0.5}>
            <Paper sx={{ width: "3rem" }}>
              <IconButton onClick={handleDrawerToggle}>
                <MenuIcon sx={{ color: "black" }} fontSize="large" />
              </IconButton>
            </Paper>
          </Grid>
        )}
        <Grid item container xs={drawerOpen ? 12 : 11.5}>
          <Grid item xs={12} mb="0.5%">
            <Box sx={boxStyle}>
              <Typography variant="h4" ml="3px">
                <span style={{ color: "black" }}>ARMILIS NIGHTLY</span>
                {" | "}
                <span style={{ color: "red" }}>{pageTitle}</span>
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} mb="2%">
            <Divider sx={{ borderBottomWidth: 2, bgcolor: "black" }} />
          </Grid>

          <Grid item xs={12}>
            {props.children}
          </Grid>
        </Grid>
      </Grid>

      <QRCodeScanner open={openQRCodeScanner} setOpen={setOpenQRCodeScanner} />
    </>
  );
};

export default AMGLayout;
