// react
import React, { useState, useMemo } from "react";

// prop-types
import PropTypes from "prop-types";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableFooter,
  TablePagination,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  Tooltip,
} from "@mui/material";
import {
  KeyboardArrowLeftOutlined as KeyboardArrowLeftOutlinedIcon,
  KeyboardArrowRightOutlined as KeyboardArrowRightOutlinedIcon,
  VisibilityOff as VisibilityOffIcon,
  Visibility as VisibilityIcon,
  Add as AddIcon,
} from "@mui/icons-material";

// datakey-controller
import { setCanCreateDataboxFlag, unsetCanCreateDataboxFlag } from "datakey-controller/manageDataboxFlag";

// utils
import convertTimestamp from "utils/convertTimestamp";

// A ---------------------------------------------------------------------- M

const DataKeysTableToolbar = ({ handleOpen }) => {
  const { t } = useTranslation();

  const buttonStyle = {
    py: 0.5,
    borderColor: "divider",
    color: "text.primary",
    textTransform: "none",
    boxShadow: "none",
    borderRadius: 10,
    "&:hover": {
      borderColor: "primary.main",
      backgroundColor: "action.hover",
    },
  };

  return (
    <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
      <Grid item xs={12} sm={6}>
        <Typography variant="h6" fontWeight="bold">
          {t("datakeys")}
        </Typography>
      </Grid>
      <Grid item container xs={12} sm={6} spacing={1} justifyContent="flex-end">
        <Grid item xs={12} sm={9} />
        <Grid item xs={12} sm={4}>
          <Button variant="outlined" fullWidth startIcon={<AddIcon />} sx={buttonStyle} onClick={handleOpen} data-testid="create-datakey-button">
            {t("new_datakey")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

const DataKeysTableHead = () => {
  const { t } = useTranslation();

  const headCells = useMemo(
    () => [
      { id: "name", label: t("name") },
      { id: "timestamp", label: t("creation_datetime") },
      { id: "value", label: t("value") },
    ],
    [t]
  );

  return (
    <TableHead sx={{ backgroundColor: "grey.100" }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.id !== "canCreateDatabox" ? "left" : "right"}>
            <Typography variant="body2" fontWeight="bold">
              {headCell.label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const theme = useTheme();
  const isRtl = theme.direction === "rtl";

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={(e) => onPageChange(e, page - 1)} disabled={page === 0} aria-label="previous page">
        {isRtl ? <KeyboardArrowRightOutlinedIcon /> : <KeyboardArrowLeftOutlinedIcon />}
      </IconButton>
      <IconButton onClick={(e) => onPageChange(e, page + 1)} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        {isRtl ? <KeyboardArrowLeftOutlinedIcon /> : <KeyboardArrowRightOutlinedIcon />}
      </IconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const DataRow = ({ datakey }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [showDataKey, setShowDataKey] = useState(false);
  const [canCreate, setCanCreate] = useState(datakey.canCreateDatabox || false);

  const toggleDataKeyVisibility = () => {
    setShowDataKey(!showDataKey);
  };

  const handleCheckboxChange = async (event) => {
    const isChecked = event.target.checked;
    setCanCreate(isChecked);
    if (isChecked) {
      await setCanCreateDataboxFlag(datakey.id);
    } else {
      await unsetCanCreateDataboxFlag(datakey.id);
    }
  };

  const DataKeyInfo = ({ name, createdOn }) => (
    <Box sx={{ mb: 1 }}>
      <Typography variant="subtitle1" fontWeight="bold">
        {name || "N/A"}
      </Typography>
      <Typography variant="body2">{createdOn ? convertTimestamp(createdOn) : "N/A"}</Typography>
    </Box>
  );

  const DataKeyVisibility = ({ datakey, showDataKey, toggleVisibility }) => (
    <Box display="flex" alignItems="center" sx={{ mt: 1 }}>
      <Typography variant="body2" sx={{ mr: 1 }}>
        {showDataKey ? datakey : "••••••"}
      </Typography>
      <Tooltip title={showDataKey ? t("hide") : t("show")} placement="top">
        <IconButton onClick={toggleVisibility} color="primary">
          {showDataKey ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </IconButton>
      </Tooltip>
    </Box>
  );

  if (isMobile) {
    return (
      <Box key={datakey.id} sx={{ p: 2, borderBottom: "1px solid #e0e0e0", cursor: "pointer", "&:hover": { backgroundColor: "#f0f0f0" } }}>
        <DataKeyInfo name={datakey.name} createdOn={datakey.created_on} />
        <DataKeyVisibility datakey={datakey.datakey} showDataKey={showDataKey} toggleVisibility={toggleDataKeyVisibility} />
      </Box>
    );
  }

  return (
    <TableRow>
      <TableCell align="left">{datakey.name || "N/A"}</TableCell>
      <TableCell align="left">{datakey.created_on ? convertTimestamp(datakey.created_on) : "N/A"}</TableCell>
      <TableCell align="left" size="small">
        <span style={{ marginRight: "10px" }}>{showDataKey ? datakey.datakey : "••••••"}</span>
        <Tooltip title={showDataKey ? t("hide") : t("show")} placement="top">
          <IconButton onClick={toggleDataKeyVisibility} color="primary">
            {showDataKey ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

const DataKeysTable = ({ dataKeys, handleOpen }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataKeys.length) : 0;

  return (
    <Box>
      <DataKeysTableToolbar handleOpen={handleOpen} />
      <TableContainer>
        <Table data-testid="datakeys-table">
          {!isMobile && <DataKeysTableHead />}
          <TableBody>
            {(isMobile ? dataKeys : dataKeys.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)).map((datakey) => {
              return <DataRow key={datakey.id} datakey={datakey} />;
            })}
            {!isMobile && emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={3} />
              </TableRow>
            )}
          </TableBody>
          {!isMobile && (
            <TableFooter sx={{ backgroundColor: "grey.100" }}>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20]}
                  colSpan={3}
                  count={dataKeys.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={t("rows_per_page")}
                  labelDisplayedRows={({ from, to, count }) => t("displayed_rows", { from, to, count: count !== -1 ? count : `more than ${to}` })}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};

DataKeysTable.propTypes = {
  dataKeys: PropTypes.array.isRequired,
  handleOpen: PropTypes.func.isRequired,
};

export default DataKeysTable;
