// firebase
import { doc, collection, getDocs } from "firebase/firestore";
import { db } from "config/firebase";

// datakey-controller
import getDataKeyData from "datakey-controller/getDataKeyData";

// databox-controller
import getCertificationsByCreator from "databox-controller/getCertificationsByCreator";

// A ---------------------------------------------------------------------- M

const getAuthorizedKeysData = async (databoxId) => {
  try {
    const authorizedKeysRef = collection(doc(db, "tagsdata", databoxId), "authorized_keys");
    const snapshot = await getDocs(authorizedKeysRef);

    if (snapshot.empty) {
      return { authorizedKeysArray: [] };
    }

    const authorizedKeysPromises = snapshot.docs.map(async (datakey) => {
      const { added_on, status = "offline", lastUpdated = "", lastEmittedPacket = "" } = datakey.data();
      const [keyData, keyCertifications] = await Promise.all([getDataKeyData(datakey.id), getCertificationsByCreator(databoxId, datakey.id)]);

      return {
        id: datakey.id,
        name: keyData?.name || "",
        owner_uuid: keyData?.owner_uuid || "",
        added_on,
        status,
        lastUpdated,
        lastEmittedPacket,
        key_certifications: keyCertifications?.certifications || [],
        key_number_of_certifications: keyCertifications?.number_of_certifications || 0,
      };
    });

    const authorizedKeysArray = await Promise.all(authorizedKeysPromises);
    console.log("authorizedKeysArray:", authorizedKeysArray);
    return { authorizedKeysArray };
  } catch (error) {
    console.error("Error in getAuthorizedKeysData:", error);
    return { authorizedKeysArray: [] };
  }
};

export default getAuthorizedKeysData;
