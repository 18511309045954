// SafeTwin
import { compareSHA256 } from "SafeTwin/crypto/cryptolibsodium";

// databox-controller
import getTagInfo from "databox-controller/getTagInfo";

// user-controller
import getPublicKey from "user-controller/getPublicKey";
import getPubKeyUrl from "user-controller/getPubKeyUrl";

// record-controller
import fetchBlockchainRecord from "record-controller/fetchBlockchainRecord";

// event-logger
import logEvent from "event-logger/logEvent";

// utils
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

import { calculateRecordHash } from "./calculateRecordHash";

// A ---------------------------------------------------------------------- M

const verifySignature = async (userId, record, type) => {
  const timestamp = unixTimestampInSeconds();

  try {
    const hash = await calculateRecordHash(record, type);

    if (hash === null) {
      return null;
    }

    const { txid, creator_uuid } = record;
    const pubkey = await getPublicKey(creator_uuid);
    const pubkeyURL = await getPubKeyUrl(creator_uuid);
    const bc_record = await fetchBlockchainRecord(txid, pubkey, pubkeyURL);

    if (bc_record.error) {
      if (record.tdr) {
        await logEvent(
          record.tdr,
          "event_databox_verification_error",
          timestamp,
          `Verification failed for ${type} record.`,
          {
            certificationID: txid,
            dataType: type,
            errorMessage: "Transaction not found in blockchain.",
            verificationStatus: false,
          },
          userId,
          txid,
          "error"
        );
      }

      return { tx_not_found: true };
    }

    // Compare the calculated hash with the blockchain record hash
    const check = compareSHA256(bc_record.txbody.hsh, hash);

    const baseResult = {
      check,
      timestamp: record.data.timestamp,
      record_hash: hash,
      pubkey,
      pubkeyURL,
      confirmed: bc_record.confirmed,
      key_source: bc_record.key_source,
      txid: bc_record.txid,
      tx_timestamp: bc_record.tx_timestamp,
      signature_status: bc_record.signature_status,
      server_id: bc_record.server_id,
      server_id_available: bc_record.server_id_available,
      hrt: bc_record.txbody.hrt,
      hsh: bc_record.txbody.hsh,
      sig: bc_record.txbody.sig,
    };

    if (bc_record.txbody.tdr) {
      const tagInfo = await getTagInfo(bc_record.txbody.tdr);

      await logEvent(
        bc_record.txbody.tdr,
        "event_databox_verification_success",
        timestamp,
        `Verification successful for ${type} record.`,
        {
          certificationID: txid,
          dataType: type,
          hash: hash,
          blockchainHash: bc_record.txbody.hsh,
          verificationStatus: true,
        },
        userId,
        txid,
        "success"
      );

      return { ...baseResult, tagname: tagInfo?.name || "", tdr: bc_record.txbody.tdr };
    }

    return baseResult;
  } catch (error) {
    console.error(`Error in verifySignature for txid ${record.txid}:`, error);

    if (record.tdr) {
      await logEvent(
        record.tdr,
        "event_databox_verification_error",
        timestamp,
        `Verification failed for ${type} record.`,
        {
          certificationID: record.txid,
          dataType: type,
          errorMessage: error.message,
          verificationStatus: false,
        },
        userId,
        record.txid,
        "error"
      );
    }

    return null;
  }
};

export default verifySignature;
