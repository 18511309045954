// react
import React, { useState, useMemo, useCallback } from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import { useMediaQuery, Button, Typography, Menu, MenuItem, Box, SpeedDial, SpeedDialAction } from "@mui/material";
import { Add as AddIcon, Description as DescriptionIcon, Image as ImageIcon, Note as NoteIcon, Settings as SettingsIcon } from "@mui/icons-material";

// A ---------------------------------------------------------------------- M

const CertificationMenu = ({ tag, openDocGenDrawer, openImageGenDrawer, openInfoGenDrawer, openConfigurationGenDrawer }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [speedDialOpen, setSpeedDialOpen] = useState(false);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = useCallback(() => setAnchorEl(null), []);
  const handleSpeedDialOpen = () => setSpeedDialOpen(true);
  const handleSpeedDialClose = () => setSpeedDialOpen(false);

  const handleCertify = useCallback(
    (type) => {
      handleClose();
      setSpeedDialOpen(false);
      const actionMap = {
        PDF: openDocGenDrawer,
        Image: openImageGenDrawer,
        Note: openInfoGenDrawer,
        Configuration: openConfigurationGenDrawer,
      };
      actionMap[type]?.();
    },
    [handleClose, openDocGenDrawer, openImageGenDrawer, openInfoGenDrawer, openConfigurationGenDrawer]
  );

  const menuItems = useMemo(() => {
    const items = [
      { label: t("certify_document"), type: "PDF", icon: <DescriptionIcon fontSize="small" /> },
      { label: t("certify_image"), type: "Image", icon: <ImageIcon fontSize="small" /> },
      { label: t("certify_note"), type: "Note", icon: <NoteIcon fontSize="small" /> },
    ];

    if (tag?.targets?.length) {
      items.push({ label: t("certify_configuration"), type: "Configuration", icon: <SettingsIcon fontSize="small" /> });
    }

    return items;
  }, [t, tag]);

  const buttonStyle = {
    py: 0.5,
    borderColor: "divider",
    color: "text.primary",
    textTransform: "none",
    boxShadow: "none",
    borderRadius: 10,
    "&:hover": {
      borderColor: "primary.main",
      backgroundColor: "action.hover",
    },
  };

  return (
    <>
      {isMobile ? (
        <>
          {speedDialOpen && (
            <Box
              sx={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0, 0, 0, 0.2)",
                zIndex: (theme) => theme.zIndex.speedDial - 1,
              }}
              onClick={handleSpeedDialClose}
            />
          )}
          <SpeedDial
            ariaLabel="certification-options"
            sx={{ position: "fixed", bottom: 16, right: 16 }}
            icon={<AddIcon />}
            onOpen={handleSpeedDialOpen}
            onClose={handleSpeedDialClose}
            open={speedDialOpen}
            data-testid="create-certification-button"
            FabProps={{
              sx: {
                bgcolor: "primary.main",
                "&:hover": { bgcolor: "primary.dark" },
              },
            }}
          >
            {menuItems.map((item) => (
              <SpeedDialAction
                key={item.type}
                icon={item.icon}
                tooltipTitle={
                  <Box sx={{ minWidth: 100 }}>
                    <Typography variant="body2">{item.label}</Typography>
                  </Box>
                }
                tooltipOpen
                onClick={() => handleCertify(item.type)}
                sx={{ mb: 1 }}
              />
            ))}
          </SpeedDial>
        </>
      ) : (
        <Button
          aria-controls="certification-menu"
          aria-haspopup="true"
          aria-label={t("certification")}
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={handleClick}
          sx={buttonStyle}
          data-testid="create-certification-button"
        >
          {t("certification")}
        </Button>
      )}
      <Menu
        id="certification-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        PaperProps={{
          sx: {
            boxShadow: "none",
            border: "1px solid",
            borderColor: "divider",
            minWidth: anchorEl ? anchorEl.clientWidth : 150,
          },
        }}
      >
        {menuItems.map((item) => (
          <MenuItem key={item.type} onClick={() => handleCertify(item.type)} sx={{ py: 1 }}>
            {item.icon}
            <Typography sx={{ ml: 1, fontSize: "0.875rem" }}>{item.label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default CertificationMenu;
