// firebase
import { collection, doc, getDocs } from "firebase/firestore";
import { db } from "config/firebase";

// user-controller
import getUserContacts from "user-controller/getUserContacts";

// A ---------------------------------------------------------------------- M

const getMembers = async (groupID) => {
  try {
    const membersSnapshot = await getDocs(collection(doc(db, "groupsdata", groupID), "users"));
    const memberPromises = membersSnapshot.docs.map(async (member) => {
      const { uid } = member.data();
      const contacts = await getUserContacts(uid);
      const email = contacts.email;

      return {
        uid,
        email,
      };
    });

    const members = await Promise.all(memberPromises);
    return members;
  } catch (error) {
    console.error("Error in getMembers:", error);
    return [];
  }
};

export default getMembers;
