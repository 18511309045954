const formatDate = (timestamp) => {
  if (!timestamp) {
    return "-";
  }
  
  const date = new Date(parseInt(timestamp, 10));
  return date.toLocaleString();
};

export default formatDate;
