// react
import React, { useState, useEffect, useMemo } from "react";

// react-router-dom
import { useNavigate } from "react-router-dom";

// prop-types
import PropTypes from "prop-types";

// context
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableFooter,
  TablePagination,
  Button,
  IconButton,
  TextField,
  InputAdornment,
  Typography,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { KeyboardArrowLeftOutlined as KeyboardArrowLeftOutlinedIcon, KeyboardArrowRightOutlined as KeyboardArrowRightOutlinedIcon, Search as SearchIcon, Add as AddIcon } from "@mui/icons-material";

// utils
import convertTimestamp from "utils/convertTimestamp";

// A ---------------------------------------------------------------------- M

const GroupsTableToolbar = ({ handleOpen, searchQuery, handleSearchChange, isSearchOpen, setIsSearchOpen }) => {
  const { canCreateGroup, conservSostL1 } = UserAuth();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const buttonStyle = {
    py: 0.5,
    borderColor: "divider",
    color: "text.primary",
    textTransform: "none",
    boxShadow: "none",
    borderRadius: 10,
    "&:hover": {
      borderColor: "primary.main",
      backgroundColor: "action.hover",
    },
  };

  const handleSearchIconClick = () => {
    setIsSearchOpen((prev) => !prev);
  };

  return (
    <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
      <Grid item xs={12} sm={6}>
        <Typography variant="h6" fontWeight="bold">
          {t("my_groups")}
        </Typography>
      </Grid>
      <Grid item container xs={12} sm={6} spacing={1} justifyContent="flex-end" alignItems="center">
        {isSearchOpen ? (
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              placeholder={t("search_by_name")}
              value={searchQuery}
              onChange={handleSearchChange}
              variant="outlined"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Tooltip title={t("search")} placement="top">
                      <IconButton onClick={handleSearchIconClick} sx={{ p: 0.5 }}>
                        <SearchIcon fontSize={isMobile ? "small" : "medium"} />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              inputProps={{
                sx: { fontSize: isMobile ? "0.8rem" : "1rem" },
              }}
            />
          </Grid>
        ) : (
          <Grid item>
            <Tooltip title={t("search")} placement="top">
              <IconButton onClick={handleSearchIconClick} sx={{ p: 0.5 }}>
                <SearchIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
        {(canCreateGroup || conservSostL1) && (
          <Grid item container sm={3} justifyContent="flex-end">
            {!isMobile && (
              <Button variant="outlined" fullWidth startIcon={<AddIcon />} sx={buttonStyle} onClick={handleOpen} data-testid="create-group-button">
                {t("group")}
              </Button>
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const GroupsTableHead = () => {
  const { t } = useTranslation();

  const headCells = useMemo(
    () => [
      { id: "name", label: t("name") },
      { id: "timestamp", label: t("creation_datetime") },
    ],
    [t]
  );

  return (
    <TableHead sx={{ backgroundColor: "grey.100" }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align="left">
            <Typography variant="body2" fontWeight="bold">
              {headCell.label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const theme = useTheme();
  const isRtl = theme.direction === "rtl";

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={(e) => onPageChange(e, page - 1)} disabled={page === 0} aria-label="previous page">
        {isRtl ? <KeyboardArrowRightOutlinedIcon /> : <KeyboardArrowLeftOutlinedIcon />}
      </IconButton>
      <IconButton onClick={(e) => onPageChange(e, page + 1)} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        {isRtl ? <KeyboardArrowLeftOutlinedIcon /> : <KeyboardArrowRightOutlinedIcon />}
      </IconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const GroupsTable = ({ groups, handleOpenGroupGenerator }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredGroups, setFilteredGroups] = useState(groups);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  useEffect(() => {
    setFilteredGroups(groups);
  }, [groups]);

  useEffect(() => {
    setFilteredGroups(groups.filter((group) => group.name.toLowerCase().includes(searchQuery.toLowerCase())));
    setPage(0);
  }, [searchQuery, groups]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredGroups.length) : 0;

  return (
    <Box>
      <GroupsTableToolbar handleOpen={handleOpenGroupGenerator} searchQuery={searchQuery} handleSearchChange={handleSearchChange} isSearchOpen={isSearchOpen} setIsSearchOpen={setIsSearchOpen} />
      <TableContainer>
        <Table data-testid="groups-table">
          {!isMobile && <GroupsTableHead />}
          <TableBody>
            {(isMobile ? filteredGroups : filteredGroups.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)).map((group) => {
              return isMobile ? (
                <Box
                  key={group.id}
                  onClick={() => navigate(`/${group.id}/group-settings`)}
                  sx={{
                    padding: 2,
                    borderBottom: "1px solid #e0e0e0",
                    cursor: "pointer",
                  }}
                >
                  <Typography variant="subtitle1" fontWeight="bold">
                    {group.name || "N/A"}
                  </Typography>
                  <Typography variant="body2">{group.created_on ? convertTimestamp(group.created_on) : "N/A"}</Typography>
                </Box>
              ) : (
                <TableRow key={group.id} sx={{ cursor: "pointer", "&:hover": { backgroundColor: "#bbdefb" } }} onClick={() => navigate(`/${group.id}/group-settings`)}>
                  <TableCell align="left">{group.name || "N/A"}</TableCell>
                  <TableCell align="left">{group.created_on ? convertTimestamp(group.created_on) : "N/A"}</TableCell>
                </TableRow>
              );
            })}
            {!isMobile && emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={2} />
              </TableRow>
            )}
          </TableBody>
          {!isMobile && (
            <TableFooter sx={{ backgroundColor: "grey.100" }}>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20]}
                  colSpan={2}
                  count={filteredGroups.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={t("rows_per_page")}
                  labelDisplayedRows={({ from, to, count }) => t("displayed_rows", { from, to, count: count !== -1 ? count : `more than ${to}` })}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};

GroupsTable.propTypes = {
  groups: PropTypes.array.isRequired,
  handleOpenGroupGenerator: PropTypes.func.isRequired,
};

export default GroupsTable;
