// react
import React, { useEffect, useState, useMemo } from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import {
  useMediaQuery,
  Box,
  SwipeableDrawer,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Typography,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

// ui-components
import { Puller } from "ui-components/Puller";
import { LoadingComponent } from "ui-components/LoadingComponent";

// customer-controller
import editCustomerPolicy from "customer-controller/editCustomerPolicy";

// config
import documentMapping from "../../config/documentMapping.json";

// A ---------------------------------------------------------------------- M

const getDocumentTypeLabel = (code) => {
  return documentMapping[code] || code;
};

const getDisplayTipologia = (tipologia) => {
  if (tipologia === "default") return "DEFAULT ALL TYPES - ID_POLICY_67890";
  if (tipologia) return getDocumentTypeLabel(tipologia);
  return "";
};

const getButtonStyle = (action) => ({
  py: 0.5,
  borderColor: "divider",
  color: action === "close" ? "error.main" : "primary.main",
  textTransform: "none",
  boxShadow: "none",
  borderRadius: 10,
  "&:hover": {
    borderColor: action === "close" ? "error.dark" : "primary.dark",
    backgroundColor: "action.hover",
  },
});

const EditCustomerPolicy = ({ client, agreement, open, setOpen }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [tipologiaDocumentale, setTipologiaDocumentale] = useState("");
  const [condition, setCondition] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handlePopstate = () => {
      if (open) setOpen(false);
    };
    window.addEventListener("popstate", handlePopstate);
    return () => window.removeEventListener("popstate", handlePopstate);
  }, [open, setOpen]);

  // Inizializza i campi del form a partire dalla prop agreement.
  useEffect(() => {
    setTipologiaDocumentale(agreement.tipologiaDocumentale ? agreement.tipologiaDocumentale : agreement.default ? "default" : "");
    setCondition(agreement.condition);
  }, [agreement]);

  // Calcola il valore da mostrare nel TextField usando il mapping o la stringa default.
  const displayTipologia = useMemo(() => getDisplayTipologia(tipologiaDocumentale), [tipologiaDocumentale]);

  const handleSave = async () => {
    setIsLoading(true);
    try {
      await editCustomerPolicy(client, tipologiaDocumentale, condition);
    } catch (error) {
      console.error("Error editing customer policy:", error);
    } finally {
      setIsLoading(false);
      setOpen(false);
    }
  };

  const editForm = (
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        <TextField
          fullWidth
          margin="dense"
          id="tipologiaDocumentale"
          name="tipologiaDocumentale"
          label={t("document_type")}
          required
          variant="outlined"
          value={displayTipologia}
          InputProps={{ readOnly: true }}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl fullWidth sx={{ mt: 1, mb: 2 }}>
          <InputLabel id="condition-select-label-edit">{t("condition")}</InputLabel>
          <Select labelId="condition-select-label-edit" id="condition-edit" label={t("condition")} value={condition} onChange={(e) => setCondition(e.target.value)}>
            <MenuItem value="end_of_month">Monthly</MenuItem>
            <MenuItem value="end_of_year">Yearly</MenuItem>
            <MenuItem value="always">Daily</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );

  const DialogTitleContent = () => (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid item>
        <Typography variant="h6" fontWeight="bold" color="text.primary">
          {t("edit_policy")}
        </Typography>
      </Grid>
      <Grid item>
        <IconButton onClick={() => setOpen(false)} color="error">
          <CloseOutlinedIcon />
        </IconButton>
      </Grid>
    </Grid>
  );

  return isMobile ? (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      sx={{
        "& .MuiDrawer-paper": {
          width: "100%",
          height: "70%",
          borderTopLeftRadius: 2,
          borderTopRightRadius: 2,
        },
      }}
    >
      <Puller />
      <Box sx={{ p: 3 }}>
        <Typography variant="h6" fontWeight="bold" textAlign="center" mb={3} color="text.primary">
          {t("edit_policy")}
        </Typography>
        {isLoading ? (
          <LoadingComponent />
        ) : (
          <>
            {editForm}
            <Grid container mt={2}>
              <Grid item xs={12}>
                <Button fullWidth variant="outlined" onClick={handleSave} sx={getButtonStyle("open")}>
                  {t("save")}
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </SwipeableDrawer>
  ) : (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
      <DialogTitle>
        <DialogTitleContent />
      </DialogTitle>
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <>
          <DialogContent dividers>{editForm}</DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={() => setOpen(false)} sx={getButtonStyle("close")}>
              {t("close")}
            </Button>
            <Button variant="outlined" onClick={handleSave} sx={getButtonStyle("open")}>
              {t("save")}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default EditCustomerPolicy;
