// react
import React, { useState, useEffect } from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// context
import { UserAuth } from "context/AuthContext";

// @mui
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";

// ui-components
import HistoryConfig from "./HistoryConfig";
import DatapointIntegrityInspector from "ui-components/CertificationManagement/DatapointIntegrityInspector";
import { LoadingDialog } from "ui-components/LoadingComponent";

// firebase
import { doc, collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "config/firebase";

// record-controller
import fetchRecordsByType from "record-controller/fetchRecordsByType";

// verification
import verifySignature from "verification/verifySignature";

// A ---------------------------------------------------------------------- M

const ConfigViewer = ({ tag, isTagGroupMember }) => {
  const { user } = UserAuth();
  const { t } = useTranslation();

  const [records, setRecords] = useState();
  const [selectedDeviceID, setSelectedDeviceID] = useState("");
  const [verification, setVerification] = useState();
  const [open, setOpen] = useState(false);
  const [verificationLoading, setVerificationLoading] = useState(false);

  useEffect(() => {
    const handlePopState = () => {
      if (open) {
        setOpen(false);
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [open]);

  useEffect(() => {
    const tagsdataDocRef = doc(db, "tagsdata", tag.id);
    const signaturesCollectionRef = collection(tagsdataDocRef, "signatures");
    const currentTimestampInSecondsString = (Date.now() / 1000).toString();

    const newAdditionsQuery = query(signaturesCollectionRef, where("type", "==", "cfg"), where("timestamp", ">=", currentTimestampInSecondsString));
    const newAdditionsUnsubscribe = onSnapshot(newAdditionsQuery, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          getRecords();
        }
      });
    });

    const getRecords = async () => {
      const records = await fetchRecordsByType(tag.id, "cfg", isTagGroupMember);
      records.sort((a, b) => b.data.timestamp - a.data.timestamp);
      const groupedRecords = records.reduce((acc, record) => {
        const target = record.data.target;
        if (!acc[target]) {
          acc[target] = {
            deviceID: target,
            configurations: [],
          };
        }
        acc[target].configurations.push(record);
        return acc;
      }, {});

      const recordsArray = Object.values(groupedRecords);
      setRecords(recordsArray);

      if (recordsArray.length > 0 && !selectedDeviceID) {
        setSelectedDeviceID(recordsArray[0].deviceID);
      }
    };

    getRecords();

    return () => {
      newAdditionsUnsubscribe();
    };
  }, [tag, isTagGroupMember, selectedDeviceID]);

  const handleOpen = () => {
    setOpen(true);
    window.history.pushState(null, "");
  };

  const checkVerification = async (record) => {
    try {
      setVerificationLoading(true);

      let verification;

      if (user) {
        verification = await verifySignature(user.uid, record, record.type);
      } else {
        verification = await verifySignature(false, record, record.type);
      }

      setVerification(verification);

      handleOpen();
    } catch (error) {
      console.error("Error in checkVerification:", error.message);
    } finally {
      setVerificationLoading(false);
    }
  };

  return (
    <>
      {verificationLoading && <LoadingDialog open={verificationLoading} />}

      {records && records.length !== 0 && (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth>
              <InputLabel id="target">{t("target")}</InputLabel>
              <Select labelId={t("target")} id="target" value={selectedDeviceID} onChange={(event) => setSelectedDeviceID(event.target.value)} input={<OutlinedInput label={t("target")} />}>
                <MenuItem value="" disabled>
                  {t("target")}
                </MenuItem>
                {records.map((device) => (
                  <MenuItem key={device.deviceID} value={device.deviceID}>
                    {device.deviceID}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {selectedDeviceID && <HistoryConfig records={records.find((device) => device.deviceID === selectedDeviceID)?.configurations || []} checkVerification={checkVerification} />}
          </Grid>
        </Grid>
      )}

      {verification && <DatapointIntegrityInspector verification={verification} open={open} setOpen={setOpen} />}
    </>
  );
};

export default ConfigViewer;
