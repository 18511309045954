// firebase
import { doc, collection, getDocs } from "firebase/firestore";
import { db } from "config/firebase";

// A ---------------------------------------------------------------------- M

const getActivatedAPIKeys = async (uuid) => {
  try {
    const userRef = doc(db, "enabled_records", uuid);
    const collectionRef = collection(userRef, "enabled_api_keys");
    const snapshot = await getDocs(collectionRef);

    const APIKeysData = snapshot.docs.map((doc) => ({
      ...doc.data(),
    }));

    return APIKeysData;
  } catch (error) {
    console.error(`Error in getActivatedAPIKeys for user ${uuid}:`, error);
    return [];
  }
};

export default getActivatedAPIKeys;
