// react
import React from "react";

// @mui
import { Grid, MenuItem, IconButton, TextField, FormControl, Select, InputLabel, InputAdornment, OutlinedInput } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

// A ---------------------------------------------------------------------- M

const MetadataField = ({ fieldKey, metadata, documentProperties, onChange, handleClickOpen }) => {
  const { label, type, visible, helperText, options, readOnly, required } = metadata[fieldKey];

  const handleFieldChange = (event) => {
    let value = event.target.value;
    if (type === "datetime" && value) {
      value = Math.floor(new Date(value).getTime() / 1000).toString();
    }
    onChange(fieldKey, value);
  };

  if (!visible(documentProperties)) return null;

  const resolvedOptions = typeof options === "function" ? options(documentProperties) : options;
  const isRequired = typeof required === "function" ? required(documentProperties) : required;

  const inputStyles = {
    backgroundColor: readOnly ? "grey.100" : "inherit",
  };

  switch (type) {
    case "text":
      return (
        <Grid item xs={12} sm={4}>
          <TextField
            label={label}
            variant="outlined"
            fullWidth
            required={isRequired}
            value={documentProperties[fieldKey] || ""}
            onChange={handleFieldChange}
            sx={inputStyles}
            InputProps={{
              readOnly,
              endAdornment: helperText && (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={() => handleClickOpen(helperText)}>
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      );
    case "select":
      return (
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth variant="outlined" disabled={readOnly} required={isRequired}>
            <InputLabel>{label}</InputLabel>
            <Select
              value={documentProperties[fieldKey] || ""}
              onChange={handleFieldChange}
              input={
                <OutlinedInput
                  label={label}
                  sx={inputStyles}
                  endAdornment={
                    helperText && (
                      <InputAdornment position="end">
                        <IconButton onClick={() => handleClickOpen(helperText)}>
                          <InfoIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                />
              }
            >
              {resolvedOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      );
    case "datetime":
      const datetimeValue = documentProperties[fieldKey] ? new Date(documentProperties[fieldKey] * 1000).toISOString().slice(0, 16) : "";
      return (
        <Grid item xs={12} sm={5}>
          <TextField
            label={label}
            variant="outlined"
            fullWidth
            required={isRequired}
            type="datetime-local"
            value={datetimeValue}
            onChange={handleFieldChange}
            sx={inputStyles}
            InputProps={{
              readOnly,
              endAdornment: helperText && (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={() => handleClickOpen(helperText)}>
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      );
    default:
      return null;
  }
};

export default MetadataField;
