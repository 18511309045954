// firebase
import { collection, addDoc } from "firebase/firestore";
import { db } from "config/firebase";

// utils
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

const postCustomer = async (newClientData) => {
  try {
    const clientsCollection = collection(db, "clientsdata");

    const newClientRef = await addDoc(clientsCollection, {
      ...newClientData,
      generationTimestamp: unixTimestampInSeconds(),
    });

    const agreementsCollection = collection(newClientRef, "agreements");
    await addDoc(agreementsCollection, {
      condition: "always",
      default: true,
    });

    return newClientRef;
  } catch (error) {
    console.error(`Error adding client: ${newClientData.name}`, error);
    return null;
  }
};

export default postCustomer;
