// react
import React, { useState, useMemo } from "react";

// react-router-dom
import { useNavigate } from "react-router-dom";

// prop-types
import PropTypes from "prop-types";

// react-i18next
import { useTranslation } from "react-i18next";

// context
import { UserAuth } from "context/AuthContext";

// @mui
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableFooter,
  TablePagination,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  Divider,
  Tooltip,
} from "@mui/material";
import { KeyboardArrowLeftOutlined as KeyboardArrowLeftOutlinedIcon, KeyboardArrowRightOutlined as KeyboardArrowRightOutlinedIcon } from "@mui/icons-material";

// A ---------------------------------------------------------------------- M

const SharedTagsTableToolbar = () => {
  const { t } = useTranslation();
  const { conservSostL1 } = UserAuth();

  return (
    <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
      <Grid item xs={12} sm={6}>
        <Typography variant="h6" fontWeight="bold">
          {conservSostL1 ? t("archives") : t("databoxes")}
        </Typography>
      </Grid>
    </Grid>
  );
};

const SharedTagsTableHead = () => {
  const { t } = useTranslation();

  const headCells = useMemo(
    () => [
      { id: "name", label: t("name") },
      { id: "permissions", label: t("permissions") },
    ],
    [t]
  );

  return (
    <TableHead sx={{ backgroundColor: "grey.100" }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align="left">
            <Typography variant="body2" fontWeight="bold">
              {headCell.label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const theme = useTheme();
  const isRtl = theme.direction === "rtl";

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={(e) => onPageChange(e, page - 1)} disabled={page === 0} aria-label="previous page">
        {isRtl ? <KeyboardArrowRightOutlinedIcon /> : <KeyboardArrowLeftOutlinedIcon />}
      </IconButton>
      <IconButton onClick={(e) => onPageChange(e, page + 1)} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        {isRtl ? <KeyboardArrowLeftOutlinedIcon /> : <KeyboardArrowRightOutlinedIcon />}
      </IconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const SharedTagsTable = ({ tags }) => {
  console.log(tags);
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tags.length) : 0;

  return (
    <Box>
      <SharedTagsTableToolbar />
      <TableContainer>
        <Table>
          <SharedTagsTableHead />
          <TableBody>
            {(rowsPerPage > 0 ? tags.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : tags).map((tag) => {
              return (
                <TableRow key={tag.id} sx={{ cursor: "pointer", "&:hover": { backgroundColor: "#bbdefb" } }} onClick={() => navigate(`/${tag.id}`)}>
                  <TableCell align="left">{tag.name || "N/A"}</TableCell>
                  <TableCell align="left" sx={{ color: "gray" }}>
                    {tag.source ? (tag.source === "readonly" ? "read" : "read/write") : "N/A"}
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={4} />
              </TableRow>
            )}
          </TableBody>
          {!isMobile && (
            <TableFooter sx={{ backgroundColor: "grey.100" }}>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20]}
                  colSpan={4}
                  count={tags.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={t("rows_per_page")}
                  labelDisplayedRows={({ from, to, count }) => t("displayed_rows", { from, to, count: count !== -1 ? count : `more than ${to}` })}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
        {isMobile && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <TablePaginationActions count={tags.length} page={page} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} />
          </Box>
        )}
      </TableContainer>
    </Box>
  );
};

SharedTagsTable.propTypes = {
  tags: PropTypes.array.isRequired,
};

export default SharedTagsTable;
