// firebase
import { setDoc, doc } from "firebase/firestore";
import { db } from "config/firebase";

// SafeTwin
import { genRndStringPrintable } from "SafeTwin/crypto/cryptolibsodium";

// utils
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

const sendCertificationRequest = async (type, uid, isPublic, requestData, tdr) => {
  try {
    if (!tdr) {
      return;
    }

    const timestamp = unixTimestampInSeconds();
    const docID = genRndStringPrintable(20);
    const recordRef = doc(db, "recordsdata", docID);

    const recordData = {
      id: docID,
      type,
      version: 1,
      creator_uuid: uid,
      data: requestData,
      public: isPublic,
      timestamp,
      ...(tdr && { tdr }),
    };

    await setDoc(recordRef, recordData);

    const sigReqRef = doc(db, "tagsdata", tdr, "signature_requests", docID);

    const sigReqData = {
      id: docID,
      type,
      creator_uuid: uid,
      db_id: 1,
      public: isPublic,
      timestamp,
      completed: false,
      tdr,
    };

    await setDoc(sigReqRef, sigReqData);
  } catch (error) {
    console.error(`Error in sendCertificationRequest for tdr ${tdr}:`, error);
  }
};

export default sendCertificationRequest;
