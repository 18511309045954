// react
import React from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import { Grid, Button, Typography, Divider } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";

// ui-components
import MetadataField from "./MetadataField";
import { metadataSchemaUpload } from "./metadataSchema";

// A ---------------------------------------------------------------------- M

const getButtonStyle = (action) => ({
  py: 0.5,
  borderColor: "divider",
  color: action === "close" ? "error.main" : "primary.main",
  textTransform: "none",
  boxShadow: "none",
  borderRadius: 10,
  "&:hover": {
    borderColor: action === "close" ? "error.dark" : "primary.dark",
    backgroundColor: "action.hover",
  },
});

const SoggettoForm = ({ soggetto, index, soggettoFields, handleSoggettoChange, handleRemoveSoggetto, handleClickOpen }) => {
  const { t } = useTranslation();

  const handleChange = (fieldKey, value) => {
    handleSoggettoChange(index, fieldKey, value);
  };

  return (
    <Grid container item spacing={3}>
      <Grid item xs={12}>
        <Typography variant="subtitle1" fontWeight="bold" color="text.primary">
          {`${t("Soggetto")} ${index + 1}`}
        </Typography>
        <Divider sx={{ mt: 1, mb: 2 }} />
      </Grid>
      {soggettoFields.map((fieldKey) => (
        <MetadataField key={fieldKey} fieldKey={fieldKey} metadata={metadataSchemaUpload} documentProperties={soggetto} onChange={handleChange} handleClickOpen={handleClickOpen} />
      ))}
      <Grid item xs={12} container justifyContent="flex-end">
        <Button variant="outlined" startIcon={<DeleteIcon fontSize="small" />} sx={getButtonStyle("close")} onClick={() => handleRemoveSoggetto(index)}>
          {t("remove")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default SoggettoForm;
