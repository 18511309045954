// react
import React, { useState } from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import { Grid, Box, Typography, Stack, Accordion, AccordionSummary, AccordionDetails, Divider } from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";

// utils
import convertTimestamp from "utils/convertTimestamp";

// A ---------------------------------------------------------------------- M

const GroupToolbar = ({ group }) => {
  const { t } = useTranslation();

  const [accordionOpen, setAccordionOpen] = useState(false);

  const handleAccordionToggle = () => {
    setAccordionOpen((prev) => !prev);
  };

  return (
    <Grid container spacing={2} data-testid="group-information-card">
      <Grid item xs={12}>
        <Accordion
          expanded={accordionOpen}
          onChange={handleAccordionToggle}
          sx={{
            boxShadow: "none",
            border: "1px solid",
            borderColor: "divider",
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6" fontWeight="bold">
              {group.name || "N/A"}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Divider sx={{ mb: 1 }} />
            <Box>
              <Stack spacing={0.5}>
                <Typography variant="body2">
                  <strong>{t("id")}:</strong> {group.id}
                </Typography>
                <Typography variant="body2">
                  <strong>{t("name")}:</strong> {group.name || "N/A"}
                </Typography>
                <Typography variant="body2">
                  <strong>{t("creation_datetime")}</strong>: {group.created_on ? convertTimestamp(group.created_on) : "N/A"}
                </Typography>
                <Typography variant="body2">
                  <strong>{t("creator")}</strong>: {group.creator_email || "N/A"}
                </Typography>
              </Stack>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default GroupToolbar;
