// react
import React, { useState, useEffect } from "react";

// prop-types
import PropTypes from "prop-types";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Grid, Typography, Tooltip, IconButton, TextField, Box } from "@mui/material";
import { CloseOutlined as CloseOutlinedIcon, Delete as DeleteIcon, Add as AddIcon } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

// A ---------------------------------------------------------------------- M

const getButtonStyle = (action) => ({
  py: 0.5,
  borderColor: "divider",
  color: action === "close" ? "error.main" : "primary.main",
  textTransform: "none",
  boxShadow: "none",
  borderRadius: 10,
  "&:hover": {
    borderColor: action === "close" ? "error.dark" : "primary.dark",
    backgroundColor: "action.hover",
  },
});

const CDNSearchDialog = ({
  open,
  onClose,
  onSearch,
  defaultAttributesList = [
    { key: "documentNames", label: "Nome File", placeholder: "es. fattura.pdf" },
    { key: "soggettiFullName", label: "Nome e Cognome (Soggetto)", placeholder: "es. Mario Rossi" },
    { key: "soggettiCodiceFiscale", label: "Codice Fiscale (Soggetto)", placeholder: "es. MRARSS80A01H501U" },
    { key: "soggettiOrganizzazione", label: "Denominazione Organizzazione (Soggetto)", placeholder: "es. Acme Srl" },
  ],
}) => {
  const { t } = useTranslation();

  const [criteria, setCriteria] = useState({
    startDate: null,
    endDate: null,
    defaultAttributes: {},
    customAttributes: [],
  });

  // Initialize default attributes based on provided list
  useEffect(() => {
    const initDefaults = {};
    defaultAttributesList.forEach((attr) => {
      initDefaults[attr.key] = "";
    });
    setCriteria((prev) => ({ ...prev, defaultAttributes: initDefaults }));
  }, []);

  const handleChange = (name) => (eventOrValue) => {
    const value = eventOrValue?.target ? eventOrValue.target.value : eventOrValue;
    setCriteria((prev) => ({ ...prev, [name]: value }));
  };

  const handleDefaultAttributeChange = (key) => (event) => {
    const value = event.target.value;
    setCriteria((prev) => ({ ...prev, defaultAttributes: { ...prev.defaultAttributes, [key]: value } }));
  };

  const handleCustomAttributeChange = (index, field) => (event) => {
    const value = event.target.value;
    setCriteria((prev) => {
      const updated = [...prev.customAttributes];
      updated[index] = { ...updated[index], [field]: value };
      return { ...prev, customAttributes: updated };
    });
  };

  const addCustomAttribute = () => {
    setCriteria((prev) => ({
      ...prev,
      customAttributes: [...prev.customAttributes, { key: "", value: "" }],
    }));
  };

  const removeCustomAttribute = (index) => {
    setCriteria((prev) => {
      const updated = prev.customAttributes.filter((_, i) => i !== index);
      return { ...prev, customAttributes: updated };
    });
  };

  const handleSearch = () => {
    const unixCriteria = {
      startDate: criteria.startDate ? Math.floor(new Date(criteria.startDate).getTime() / 1000).toString() : null,
      endDate: criteria.endDate ? Math.floor(new Date(criteria.endDate).getTime() / 1000).toString() : null,
    };

    const trimmedDefaults = Object.fromEntries(Object.entries(criteria.defaultAttributes).map(([key, value]) => [key, typeof value === "string" ? value.trim() : value]));

    const trimmedCustomAttributes = criteria.customAttributes.reduce((acc, attr) => {
      if (attr.key) {
        acc[attr.key.trim()] = typeof attr.value === "string" ? attr.value.trim() : attr.value;
      }
      return acc;
    }, {});

    const finalCriteria = {
      ...unixCriteria,
      ...trimmedDefaults,
      ...trimmedCustomAttributes,
    };

    onSearch(finalCriteria);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      sx={{
        "& .MuiPaper-root": {
          borderRadius: 2,
          p: 2,
          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <DialogTitle>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h6" fontWeight="bold" color="text.primary">
              {t("search")}
            </Typography>
          </Grid>
          <Grid item>
            <Tooltip title={t("close")} placement="top">
              <IconButton onClick={onClose} color="error" size="small">
                <CloseOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          {/* Date pickers */}
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker label={t("start_upload")} value={criteria.startDate} onChange={handleChange("startDate")} renderInput={(params) => <TextField {...params} />} />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker label={t("end_upload")} value={criteria.endDate} onChange={handleChange("endDate")} renderInput={(params) => <TextField {...params} />} />
            </LocalizationProvider>
          </Grid>
          {/* Default Attributes */}
          {defaultAttributesList.map((attr) => (
            <Grid item xs={12} key={attr.key}>
              <TextField
                label={t(attr.label)}
                helperText={attr.placeholder ? t(attr.placeholder) : ""}
                fullWidth
                value={criteria.defaultAttributes[attr.key] || ""}
                onChange={handleDefaultAttributeChange(attr.key)}
              />
            </Grid>
          ))}
          {/* Custom Attributes */}
          <Grid item container xs={12} spacing={2}>
            {criteria.customAttributes.map((attr, index) => (
              <Grid item container spacing={2} key={index} alignItems="center">
                <Grid item xs={12} md={5.5}>
                  <TextField label={t("key")} fullWidth value={attr.key} onChange={handleCustomAttributeChange(index, "key")} />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <TextField label={t("value")} fullWidth value={attr.value} onChange={handleCustomAttributeChange(index, "value")} />
                </Grid>
                <Grid item xs={1}>
                  <Tooltip title={t("remove")} placement="top">
                    <IconButton color="error" onClick={() => removeCustomAttribute(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12}>
            <Box mt={2}>
              <Button variant="outlined" onClick={addCustomAttribute} startIcon={<AddIcon />} sx={getButtonStyle("open")}>
                {t("parameter")}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="error" onClick={onClose} sx={getButtonStyle("close")}>
          {t("cancel")}
        </Button>
        <Button variant="outlined" onClick={handleSearch} sx={getButtonStyle("open")}>
          {t("search")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CDNSearchDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  defaultAttributesList: PropTypes.array,
};

export default CDNSearchDialog;
