// react
import React, { useEffect, useState } from "react";

// context
import { UserAuth } from "context/AuthContext";

// @mui
import { ThemeProvider } from "@mui/material/styles";

// ui-components
import DefaultLayout from "./DefaultLayout/DefaultLayout";
import ArmilisLayout from "./ArmilisLayout/ArmilisLayout";
import ConservazioneSostitutivaLayout from "./ConservazioneSostitutivaLayout/ConservazioneSostitutivaLayout";
import AMGLayout from "./AMGLayout/AMGLayout";
import AlsaLayout from "./AlsaLayout/AlsaLayout";
import WilktronicsXArmilisLayout from "./WilktronicsXArmilis/WilktronicsXArmilisLayout";
import _jZP9Zja79jLayout from "./TagsLayout/_jZP9Zja79jLayout/_jZP9Zja79jLayout";
import { DefaultTheme, ArmilisTheme, ConservazioneSostitutivaTheme, AMGTheme, AlsaTheme, WilktronicsXArmilisTheme, _jZP9Zja79jTheme } from "themes/themeConfig";

// A ---------------------------------------------------------------------- M

const LayoutSwitcher = ({ children, tag }) => {
  const { user, userCompany, conservSostL1 } = UserAuth();

  const [layoutConfig, setLayoutConfig] = useState({
    layout: ArmilisLayout,
    theme: ArmilisTheme,
  });

  useEffect(() => {
    const hostname = window.location.hostname;
    let layout, theme;

    if (hostname === "nightly.twins.armilis.com" || hostname === "twins.armilis.com") {
      if (user) {
        if (conservSostL1) {
          layout = ConservazioneSostitutivaLayout;
          theme = ConservazioneSostitutivaTheme;
        } else if (userCompany === "Armilis") {
          layout = ArmilisLayout;
          theme = ArmilisTheme;
        } else if (userCompany === "AMG") {
          layout = AMGLayout;
          theme = AMGTheme;
        } else if (userCompany === "Alsa") {
          layout = AlsaLayout;
          theme = AlsaTheme;
        } else if (userCompany === "wilktronics_x_armilis") {
          layout = WilktronicsXArmilisLayout;
          theme = WilktronicsXArmilisTheme;
        } else {
          layout = ArmilisLayout;
          theme = ArmilisTheme;
        }
      } else {
        if (tag === "jZP9Zja79j" || tag === "buIl6Jhi1o" || tag === "VmRCI2xFOM") {
          layout = _jZP9Zja79jLayout;
          theme = _jZP9Zja79jTheme;
        } else {
          layout = DefaultLayout;
          theme = DefaultTheme;
        }
      }
    } else {
      if (user) {
        if (conservSostL1) {
          layout = ConservazioneSostitutivaLayout;
          theme = ConservazioneSostitutivaTheme;
        } else if (userCompany === "Armilis") {
          layout = ArmilisLayout;
          theme = ArmilisTheme;
        } else if (userCompany === "AMG") {
          layout = AMGLayout;
          theme = AMGTheme;
        } else if (userCompany === "Alsa") {
          layout = AlsaLayout;
          theme = AlsaTheme;
        } else if (userCompany === "wilktronics_x_armilis") {
          layout = WilktronicsXArmilisLayout;
          theme = WilktronicsXArmilisTheme;
        } else {
          layout = ArmilisLayout;
          theme = ArmilisTheme;
        }
      } else {
        if (tag === "jZP9Zja79j" || tag === "buIl6Jhi1o" || tag === "VmRCI2xFOM") {
          layout = _jZP9Zja79jLayout;
          theme = _jZP9Zja79jTheme;
        } else {
          layout = DefaultLayout;
          theme = DefaultTheme;
        }
      }
    }

    setLayoutConfig({ layout, theme });
  }, []);

  const { layout: LayoutComponent, theme } = layoutConfig;

  return (
    <ThemeProvider theme={theme}>
      <LayoutComponent>{children}</LayoutComponent>
    </ThemeProvider>
  );
};

export default LayoutSwitcher;
