// react
import React, { useState, useEffect } from "react";

// prop-types
import PropTypes from "prop-types";

// context
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import {
  useMediaQuery,
  SwipeableDrawer,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  Button,
  IconButton,
  Tooltip,
  Card,
  CardContent,
  CardActions,
  Stack,
} from "@mui/material";
import { CloseOutlined as CloseOutlinedIcon, CheckCircle as CheckCircleIcon, Task as TaskIcon } from "@mui/icons-material";

// ui-components
import { Puller } from "ui-components/Puller";
import { LoadingDialog } from "ui-components/LoadingComponent";
import SimpleBlockchainVerification from "./SimpleBlockchainVerification";
import CDNFileUploadReceipt from "./CDNFileUploadReceipt";

// firebase
import { doc, getDoc } from "firebase/firestore";
import { db } from "config/firebase";

// storage
import downloadXMLFile from "storage/downloadXMLFile";
import downloadAsZip from "storage/downloadAsZip";

// event-logger
import logEvent from "event-logger/logEvent";

// verification
import verifySignatureAIC from "verification/verifySignatureAIC";

// utils
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

const getButtonStyle = (action) => {
  if (action === "verify") {
    return {
      py: 0.5,
      borderColor: "success.main",
      color: "success.main",
      textTransform: "none",
      boxShadow: "none",
      borderRadius: 10,
      "&:hover": {
        borderColor: "success.dark",
        backgroundColor: "action.hover",
      },
    };
  }
  return {
    py: 0.5,
    borderColor: "divider",
    color: action === "close" ? "error.main" : "primary.main",
    textTransform: "none",
    boxShadow: "none",
    borderRadius: 10,
    "&:hover": {
      borderColor: action === "close" ? "error.dark" : "primary.dark",
      backgroundColor: "action.hover",
    },
  };
};

const CDNPreservationProofDialog = ({ open, onClose, record }) => {
  const { user } = UserAuth();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [uploadReceipt, setUploadReceipt] = useState(null);
  const [receiptDialogOpen, setReceiptDialogOpen] = useState(false);
  const [aicData, setAicData] = useState(null);
  const [verification, setVerification] = useState(null);
  const [inspectorOpen, setInspectorOpen] = useState(false);
  const [verificationLoading, setVerificationLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleOpenVerify = () => {
    setInspectorOpen(true);
    window.history.pushState(null, "");
  };

  const checkVerification = async () => {
    try {
      setVerificationLoading(true);
      const verification = await verifySignatureAIC(false, record, record.type);
      console.log(verification);
      setVerification(verification);
      handleOpenVerify();
    } catch (error) {
      console.error("Error in checkVerification:", error.message);
    } finally {
      setVerificationLoading(false);
    }
  };

  useEffect(() => {
    const fetchUploadReceipt = async () => {
      if (record && record.txid) {
        try {
          const docRef = doc(db, "uploadReceipts", record.txid);
          const docSnapshot = await getDoc(docRef);
          if (docSnapshot.exists()) {
            setUploadReceipt({ ...docSnapshot.data() });
          } else {
            console.log("No such document exists.");
          }
        } catch (error) {
          console.error("Error fetching upload receipt:", error);
        }
      }
    };

    const fetchAICData = async () => {
      if (record && record.AIC_ID) {
        try {
          const aicDocRef = doc(db, "aicsdata", record.AIC_ID);
          const aicDocSnapshot = await getDoc(aicDocRef);
          if (aicDocSnapshot.exists()) {
            setAicData(aicDocSnapshot.data());
          }
        } catch (error) {
          console.error("Error fetching AIC data:", error);
        }
      }
    };

    const logger = async () => {
      await logEvent(
        record.tdr,
        "event_archive_verification_success",
        unixTimestampInSeconds(),
        "Verification successfully performed.",
        { documentID: record.txid, verificationStatus: true },
        user.uid,
        record.txid,
        "success"
      );
    };

    const fetchData = async () => {
      if (record) {
        await logger();
        await Promise.all([fetchUploadReceipt(), fetchAICData()]);
        setLoading(false);
      }
    };

    fetchData();
  }, [record, user.uid, t]);

  const handleResetUploadReceipt = () => {
    setReceiptDialogOpen(false);
  };

  const PreservationProofContent = (
    <Stack spacing={3}>
      {record.AIC_ID && aicData && (
        <Card sx={{ borderLeft: "4px solid green", boxShadow: "none", p: 1 }}>
          <CardContent>
            <Stack direction="row" spacing={2} alignItems="center">
              <CheckCircleIcon color="success" fontSize="large" />
              <Typography variant="body1">
                <b>Archival Information Collection (AIC UniSincro)</b> è stato generato correttamente ed assegnato al processo di conservazione corrente.
              </Typography>
            </Stack>
          </CardContent>
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <Button variant="outlined" onClick={() => downloadXMLFile(`AICFiles/${record.AIC_ID}/${record.AIC_ID}_AIC.xml`, "AIC", record.AIC_ID)} sx={{ mr: 1, ...getButtonStyle("open") }}>
              {t("download_aic")}
            </Button>
            <Button
              variant="outlined"
              onClick={() => downloadXMLFile(`AICFiles/${record.AIC_ID}/${record.AIC_ID}_AIC_signed.xml.p7m`, "AIC_signed", record.AIC_ID)}
              sx={{ mr: 1, ...getButtonStyle("open") }}
            >
              {t("download_signed_aic")}
            </Button>
            <Button variant="outlined" onClick={() => downloadXMLFile(`AICFiles/${record.AIC_ID}/${record.AIC_ID}_timestamp.xml`, "AIC_timestamp", record.AIC_ID)} sx={getButtonStyle("open")}>
              {t("download_timestamp")}
            </Button>
            <Button variant="outlined" onClick={() => checkVerification(record.AIC_txid)} sx={getButtonStyle("verify")}>
              {t("verify")}
            </Button>
          </CardActions>
        </Card>
      )}

      <Card sx={{ borderLeft: "4px solid green", boxShadow: "none", p: 1 }}>
        <CardContent>
          <Stack direction="row" spacing={2} alignItems="center">
            <CheckCircleIcon color="success" fontSize="large" />
            <Typography variant="body1">
              <b>Indice Di Conservazione (IdC UniSincro)</b> è stato generato correttamente ed assegnato al processo di conservazione corrente.
            </Typography>
          </Stack>
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button variant="outlined" onClick={() => downloadXMLFile(`${record.uploadID}/${record.id}_IdC.xml`, "IdC", record.id)} sx={{ mr: 1, ...getButtonStyle("open") }}>
            {t("download_idc")}
          </Button>
          <Button variant="outlined" sx={getButtonStyle("open")} onClick={() => downloadXMLFile(`${record.uploadID}/${record.id}_IdC_signed.xml.p7m`, "IdC_signed", record.id)}>
            {t("download_signed_idc")}
          </Button>
        </CardActions>
      </Card>

      <Card sx={{ borderLeft: "4px solid green", boxShadow: "none", p: 1 }}>
        <CardContent>
          <Stack spacing={2}>
            {[
              { name: "gluer.xml", description: t("parameters_file") },
              { name: "metadata.xml", description: t("metadata_file") },
            ].map((file, index) => (
              <Stack key={index} direction="row" spacing={2} alignItems="center">
                <TaskIcon color="success" fontSize="large" />
                <Box flexGrow={1}>
                  <Typography variant="body2">{`${t("file_name")}: ${file.name}`}</Typography>
                  <Typography variant="body2">{`${t("description")}: ${file.description}`}</Typography>
                  <Typography variant="body2">{`${t("mime_type")}: application/xml`}</Typography>
                </Box>
                <Button variant="outlined" sx={getButtonStyle("open")} onClick={() => downloadXMLFile(`${record.uploadID}/${record.id}_${file.name}`, file.name.split(".")[0], record.id)}>
                  {t("download")}
                </Button>
              </Stack>
            ))}
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );

  if (loading) {
    return <LoadingDialog open={loading} />;
  }

  return (
    <>
      {isMobile ? (
        <SwipeableDrawer
          anchor="bottom"
          open={open}
          onClose={onClose}
          onOpen={() => {}}
          sx={{
            "& .MuiDrawer-paper": {
              width: "100%",
              height: "90%",
              borderTopLeftRadius: "4%",
              borderTopRightRadius: "4%",
            },
          }}
        >
          <Puller />
          <Box sx={{ p: 3 }}>
            <Typography variant="h6" fontWeight="bold" textAlign="center" mb={3} color="text.primary">
              {t("preservation_proof")}
            </Typography>
            {PreservationProofContent}
            <Grid container spacing={1} mt={3}>
              <Grid item xs={12}>
                <Button variant="outlined" sx={getButtonStyle("open")} onClick={() => setReceiptDialogOpen(true)}>
                  {t("open_submission_report")}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button variant="outlined" sx={getButtonStyle("open")} onClick={() => downloadAsZip(record.uploadID)}>
                  {t("download_as_zip")}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </SwipeableDrawer>
      ) : (
        <Dialog
          open={open}
          onClose={onClose}
          maxWidth="md"
          fullWidth
          sx={{
            "& .MuiPaper-root": {
              borderRadius: 2,
              p: 2,
              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
            },
          }}
          data-testid="preservation-proof-modal"
        >
          <DialogTitle>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h6" fontWeight="bold" color="text.primary">
                  {t("preservation_proof")}
                </Typography>
              </Grid>
              <Grid item>
                <Tooltip title={t("close")} placement="top">
                  <IconButton onClick={onClose} color="error" edge="end" size="small">
                    <CloseOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>{PreservationProofContent}</DialogContent>
          <DialogActions>
            <Button variant="outlined" sx={getButtonStyle("close")} onClick={onClose}>
              {t("close")}
            </Button>
            <Button variant="outlined" sx={getButtonStyle("open")} onClick={() => setReceiptDialogOpen(true)}>
              {t("open_submission_report")}
            </Button>
            <Button variant="outlined" sx={getButtonStyle("open")} onClick={() => downloadAsZip(record.uploadID)}>
              {t("download_as_zip")}
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {verificationLoading && <LoadingDialog open={verificationLoading} />}

      {receiptDialogOpen && uploadReceipt && <CDNFileUploadReceipt receiptDialogOpen={receiptDialogOpen} handleResetUploadReceipt={handleResetUploadReceipt} uploadReceipt={uploadReceipt} />}

      {<SimpleBlockchainVerification verification={verification} open={inspectorOpen} onClose={() => setInspectorOpen(false)} />}
    </>
  );
};

CDNPreservationProofDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  record: PropTypes.object.isRequired,
};

export default CDNPreservationProofDialog;
