// firebase
import { doc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase";

// A ---------------------------------------------------------------------- M

const updateAlarmTrigger = async (databoxID, triggerID, updatedFields) => {
  try {
    const triggerRef = doc(db, "tagsdata", databoxID, "triggers", triggerID);
    await updateDoc(triggerRef, updatedFields);
    console.log("Trigger updated successfully!");
  } catch (error) {
    console.error("Failed to update trigger:", error);
    throw new Error("Failed to update trigger.");
  }
};

export default updateAlarmTrigger;
