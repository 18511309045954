// react
import React from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// @react-pdf/renderer
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink } from "@react-pdf/renderer";

// @mui
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Typography, Stack, Tooltip, Card, CardContent } from "@mui/material";
import { CloseOutlined as CloseOutlinedIcon, CheckCircle as CheckCircleIcon, ErrorOutline as ErrorOutlineIcon, Download as DownloadIcon } from "@mui/icons-material";

// utils
import convertTimestamp from "utils/convertTimestamp";

// A ---------------------------------------------------------------------- M

const styles = StyleSheet.create({
  page: {
    padding: 20,
    backgroundColor: "#ffffff",
  },
  section: {
    marginBottom: 10,
    padding: 10,
    border: "1pt solid #e0e0e0",
    borderRadius: 5,
  },
  title: {
    fontSize: 18,
    marginBottom: 10,
  },
  successSubheading: {
    color: "green",
    fontSize: 14,
    marginBottom: 5,
  },
  errorSubheading: {
    color: "red",
    fontSize: 14,
    marginBottom: 5,
  },
  subheading: {
    fontSize: 14,
    marginBottom: 5,
  },
  errorSubheading: {
    color: "red",
    fontSize: 14,
    marginBottom: 5,
  },
  text: {
    fontSize: 12,
    marginBottom: 5,
  },
});

const PDFReceiptComponent = ({ uploadReceipt }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View>
        <Text style={styles.title}>Rapporto Di Versamento</Text>
      </View>
      {uploadReceipt.status === "success" ? (
        <>
          <View style={styles.section}>
            <Text style={styles.subheading}>
              Il processo di caricamento è avvenuto con successo. <br />
              L'identificativo assegnato è: <Text>{uploadReceipt.uploadID || "N/A"}</Text>
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.successSubheading}>Dettagli</Text>
            <Text style={styles.text}>Effettuato Da: {uploadReceipt.createdByEmail || "N/A"}</Text>
            <Text style={styles.text}>Stato: {uploadReceipt.status ? uploadReceipt.status.toUpperCase() : "N/A"}</Text>
            <Text style={styles.text}>N. Doc. Totali: {uploadReceipt.totalCount ?? "N/A"}</Text>
            <Text style={styles.text}>N. Doc. Validi: {uploadReceipt.validCount ?? "N/A"}</Text>
            <Text style={styles.text}>N. Doc. Invalidi: {uploadReceipt.invalidCount ?? "N/A"}</Text>
            <Text style={styles.text}>Datetime Rapporto: {uploadReceipt.createdAt ? convertTimestamp(uploadReceipt.createdAt) : "N/A"}</Text>
          </View>
        </>
      ) : (
        <>
          <View style={styles.section}>
            <Text style={styles.subheading}>Errore nel processo di caricamento.</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.errorText}>Dettagli</Text>
            <Text style={styles.text}>Effettuato Da: {uploadReceipt.createdByEmail || "N/A"}</Text>
            <Text style={styles.text}>Stato: {uploadReceipt.status ? uploadReceipt.status.toUpperCase() : "N/A"}</Text>
            <Text style={styles.text}>N. Doc. Totali: {uploadReceipt.totalCount ?? "N/A"}</Text>
            <Text style={styles.text}>N. Doc. Validi: {uploadReceipt.validCount ?? "N/A"}</Text>
            <Text style={styles.text}>N. Doc. Invalidi: {uploadReceipt.invalidCount ?? "N/A"}</Text>
            <Text style={styles.text}>Datetime Rapporto: {uploadReceipt.createdAt ? convertTimestamp(uploadReceipt.createdAt) : "N/A"}</Text>
          </View>
        </>
      )}
    </Page>
  </Document>
);

const getButtonStyle = (action) => ({
  py: 0.5,
  borderColor: "divider",
  color: action === "close" ? "error.main" : "primary.main",
  textTransform: "none",
  boxShadow: "none",
  borderRadius: 10,
  "&:hover": {
    borderColor: action === "close" ? "error.dark" : "primary.dark",
    backgroundColor: "action.hover",
  },
});

const CDNFileUploadReceipt = ({ uploadReceipt, receiptDialogOpen, handleResetUploadReceipt }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={receiptDialogOpen}
      onClose={handleResetUploadReceipt}
      maxWidth="md"
      fullWidth
      sx={{
        "& .MuiPaper-root": {
          borderRadius: 2,
          p: 2,
          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <DialogTitle>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h6" fontWeight="bold" color="text.primary">
              {t("Rapporto Di Versamento")}
            </Typography>
          </Grid>
          <Grid item>
            <Tooltip title={t("close")} placement="top">
              <IconButton onClick={handleResetUploadReceipt} color="error" edge="end">
                <CloseOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        {uploadReceipt.status === "success" ? (
          <Stack spacing={3}>
            <Card sx={{ borderLeft: "4px solid green", boxShadow: "none", p: 1 }}>
              <CardContent>
                <Stack direction="row" spacing={2} alignItems="center">
                  <CheckCircleIcon color="success" fontSize="large" />
                  <Typography variant="body1" color="text.primary">
                    Il processo di caricamento è avvenuto con successo.{"\n"}
                    L'identificativo assegnato è: <strong>{uploadReceipt.uploadID || "N/A"}</strong>
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
            <Card sx={{ borderLeft: "4px solid green", boxShadow: "none", p: 1 }}>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="green" gutterBottom fontWeight="bold">
                      Dettagli
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="text.primary">
                      Effettuato Da: {uploadReceipt.createdByEmail || "N/A"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="text.primary">
                      Stato: {uploadReceipt.status ? uploadReceipt.status.toUpperCase() : "N/A"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="text.primary">
                      N. Doc. Totali: {uploadReceipt.totalCount ?? "N/A"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="text.primary">
                      N. Doc. Validi: {uploadReceipt.validCount ?? "N/A"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="text.primary">
                      N. Doc. Invalidi: {uploadReceipt.invalidCount ?? "N/A"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="text.primary">
                      Datetime Rapporto: {uploadReceipt.createdAt ? convertTimestamp(uploadReceipt.createdAt) : "N/A"}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Stack>
        ) : (
          <Stack spacing={3}>
            <Card sx={{ borderLeft: "4px solid red", boxShadow: "none", p: 1 }}>
              <CardContent>
                <Stack direction="row" spacing={2} alignItems="center">
                  <ErrorOutlineIcon color="error" fontSize="large" />
                  <Typography variant="body1" color="error">
                    Errore nel processo di caricamento.
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
            <Card sx={{ borderLeft: "4px solid red", boxShadow: "none", p: 1 }}>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="error" gutterBottom>
                      Dettagli
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="text.primary">
                      Effettuato Da: {uploadReceipt.createdByEmail || "N/A"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="text.primary">
                      Stato: {uploadReceipt.status ? uploadReceipt.status.toUpperCase() : "N/A"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="text.primary">
                      N. Doc. Totali: {uploadReceipt.totalCount ?? "N/A"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="text.primary">
                      N. Doc. Validi: {uploadReceipt.validCount ?? "N/A"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="text.primary">
                      N. Doc. Invalidi: {uploadReceipt.invalidCount ?? "N/A"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="text.primary">
                      Datetime Rapporto: {uploadReceipt.createdAt ? convertTimestamp(uploadReceipt.createdAt) : "N/A"}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <PDFDownloadLink document={<PDFReceiptComponent uploadReceipt={uploadReceipt} />} fileName="submission_report.pdf" style={{ textDecoration: "none" }}>
          {({ loading }) => (
            <Button variant="outlined" startIcon={<DownloadIcon />} sx={getButtonStyle("open")}>
              {loading ? "Generating PDF..." : "Export As PDF"}
            </Button>
          )}
        </PDFDownloadLink>
      </DialogActions>
    </Dialog>
  );
};

export default CDNFileUploadReceipt;
