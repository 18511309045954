export const initialUploadProperties = {
  soggetti: [],
  oggetto: "",
  tipologiaDocumentale: "",
  documentoPrincipale: "",
  dataInvioInConservazione: "",
  prodottoSoftwareNome: "",
  prodottoSoftwareVersione: "",
  prodottoSoftwareProduttore: "",
  modalitaDiFormazione: "",
  riservato: "",
  verificaFirmaDigitale: "",
  verificaSigilloElettronico: "",
  verificaMarcaturaTemporale: "",
  verificaConformitaCopie: "",
  tipologiaDiFlusso: "",
  tipoRegistro: "",
  documenti: [],
};
