// react
import React, { useEffect, useState } from "react";

// react-router-dom
import { useParams } from "react-router-dom";

// context
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import { Grid, Card, CardContent, Typography, CircularProgress, Divider } from "@mui/material";

// ui-components
import TagToolbar from "ui-components/DataboxManagement/TagToolbar";
import ViewGroupsTable from "ui-components/DataboxManagement/ViewGroupsTable";
import EnabledDataKeysTable from "ui-components/DataboxManagement/EnabledDataKeysTable";
import ForwardRoutesTable from "ui-components/DataboxManagement/ForwardRoutesTable";
import AlarmConfiguration from "ui-components/DataboxManagement/AlarmConfiguration";
import AddViewGroup from "ui-components/DataboxManagement/AddViewGroup";
import AddDataKey from "ui-components/DataboxManagement/AddDataKey";
import NewTargetEndpoint from "ui-components/DataboxManagement/NewTargetEndpoint";
import EditForwardRoute from "ui-components/DataboxManagement/EditForwardRoute";
import {
  GroupAddedSuccessfulSnackbar,
  GroupAddedErrorSnackbar,
  GroupRemovedSuccessfulSnackbar,
  GroupRemovedErrorSnackbar,
  ForwardRouteRemovedSuccessfulSnackbar,
  ForwardRouteRemovedErrorSnackbar,
} from "ui-components/ORFeedbacks";

// firebase
import { doc, collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "config/firebase";

// databox-controller
import { addAlarmUser, removeAlarmUser, hasUserAlarmOn } from "databox-controller/manageAlarm";
import isUserFollowingDatabox from "databox-controller/isUserFollowingDatabox";
import { addFollower, removeFollower } from "databox-controller/manageFollower";
import getAuthorizedDataKeys from "databox-controller/getAuthorizedDataKeys";
import getForwardRoutes from "databox-controller/getForwardRoutes";
import getTag from "databox-controller/getTag";
import getTagOwner from "databox-controller/getTagOwner";
import getViewGroups from "databox-controller/getViewGroups";
import getViewGroupsReadOnly from "databox-controller/getViewGroupsReadOnly";

// user-controller
import getSeenTag from "user-controller/getSeenTag";
import getUserContacts from "user-controller/getUserContacts";

// A ---------------------------------------------------------------------- M

const TagSettings = () => {
  const { user, conservSostL1 } = UserAuth();
  const { tag } = useParams();
  const { t } = useTranslation();

  // Stati principali
  const [seentag, setSeenTag] = useState(null);
  const [viewgroups, setViewGroups] = useState([]);
  const [dataKeys, setDataKeys] = useState([]);
  const [forwardRoutes, setForwardRoutes] = useState([]);
  const [isFollowing, setIsFollowing] = useState(false);
  const [alarmOn, setAlarmOn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Stati per dialogs/snackbars
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [openAddViewGroup, setOpenAddViewGroup] = useState(false);
  const [openAddDataKey, setOpenAddDataKey] = useState(false);
  const [openAddForwardRoute, setOpenAddForwardRoute] = useState(false);
  const [openEditForwardRoute, setOpenEditForwardRoute] = useState(false);
  const [openGroupAddedSuccessful, setOpenGroupAddedSuccessful] = useState(false);
  const [openGroupAddedError, setOpenGroupAddedError] = useState(false);
  const [openGroupRemovedSuccessful, setOpenGroupRemovedSuccessful] = useState(false);
  const [openGroupRemovedError, setOpenGroupRemovedError] = useState(false);
  const [openForwardRouteRemovedSuccessful, setOpenForwardRouteRemovedSuccessful] = useState(false);
  const [openForwardRouteRemovedError, setOpenForwardRouteRemovedError] = useState(false);

  // --- Data fetching functions ---
  const fetchSeenTagData = async () => {
    const seen = await getSeenTag(user.uid, tag);
    const tagData = await getTag(tag);
    const tagOwner = await getTagOwner(tag);
    const tagOwnerData = await getUserContacts(tagOwner);
    setSeenTag({
      ...seen,
      name: tagData.name || "",
      alarmEmails: tagData.alarm_emails || [],
      alarmEnabled: tagData.alarm_enabled || false,
      tagOwner,
      tagOwnerEmail: tagOwnerData.email,
      ...(conservSostL1 && tagData.tipologiaDocumentale ? { tipologiaDocumentale: tagData.tipologiaDocumentale } : {}),
    });
  };

  const fetchViewGroupsData = async () => {
    const groups = conservSostL1 ? [...(await getViewGroupsReadOnly(tag)), ...(await getViewGroups(tag))] : await getViewGroups(tag);
    setViewGroups(groups);
  };

  const fetchDataKeysData = async () => {
    const keys = await getAuthorizedDataKeys(tag);
    setDataKeys(keys.authorizedKeysArray);
  };

  const fetchForwardRoutesData = async () => {
    const routes = await getForwardRoutes(tag);
    setForwardRoutes(routes);
  };

  const fetchAllData = async () => {
    await Promise.all([fetchSeenTagData(), fetchViewGroupsData(), fetchDataKeysData(), fetchForwardRoutesData()]);
    setIsLoading(false);
  };

  // --- Setup initial data and listeners ---
  useEffect(() => {
    fetchAllData();

    // Setup Firestore listeners for real-time updates
    const currentTimestamp = Math.floor(Date.now() / 1000).toString();
    const tagsdataRef = doc(db, "tagsdata", tag);
    const collections = {
      viewgroups: collection(tagsdataRef, "viewgroups"),
      viewgroupsReadOnly: collection(tagsdataRef, "viewgroups_readonly"),
      authorizedKeys: collection(tagsdataRef, "authorized_keys"),
      forwardRoutes: collection(tagsdataRef, "forward_routes"),
    };

    const queries = {
      viewgroups: query(collections.viewgroups, where("added_on", ">=", currentTimestamp)),
      viewgroupsReadOnly: query(collections.viewgroupsReadOnly, where("added_on", ">=", currentTimestamp)),
      authorizedKeys: query(collections.authorizedKeys, where("added_on", ">=", currentTimestamp)),
      forwardRoutes: query(collections.forwardRoutes, where("added_on", ">=", currentTimestamp)),
    };

    const unsubscribes = [
      onSnapshot(queries.viewgroups, (snap) => {
        snap.docChanges().forEach((change) => {
          if (["added", "removed"].includes(change.type)) fetchViewGroupsData();
        });
      }),
      onSnapshot(queries.viewgroupsReadOnly, (snap) => {
        snap.docChanges().forEach((change) => {
          if (["added", "removed"].includes(change.type)) fetchViewGroupsData();
        });
      }),
      onSnapshot(queries.authorizedKeys, (snap) => {
        snap.docChanges().forEach((change) => {
          if (["added", "removed"].includes(change.type)) fetchDataKeysData();
        });
      }),
      onSnapshot(queries.forwardRoutes, (snap) => {
        snap.docChanges().forEach((change) => {
          if (["added", "modified", "removed"].includes(change.type)) fetchForwardRoutesData();
        });
      }),
    ];

    return () => unsubscribes.forEach((unsub) => unsub());
  }, [tag, user, conservSostL1]);

  useEffect(() => {
    // Check following status and alarm state
    const checkStatus = async () => {
      setIsFollowing(await isUserFollowingDatabox(user.uid, tag));
      setAlarmOn(await hasUserAlarmOn(user.uid, tag));
    };
    checkStatus();
  }, [user, tag]);

  // --- Handlers for toggling follow and alarm ---
  const handleFollowToggle = async () => {
    if (isFollowing) await removeFollower(user.uid, tag);
    else await addFollower(user.uid, tag);
    setIsFollowing((prev) => !prev);
  };

  const handleAlarmToggle = async () => {
    if (alarmOn) await removeAlarmUser(user.uid, tag);
    else await addAlarmUser(user.uid, tag);
    setAlarmOn((prev) => !prev);
  };

  // --- Drawer open handlers ---
  const openAddViewGroupDrawer = () => {
    setOpenAddViewGroup(true);
    window.history.pushState(null, "");
  };
  const openAddDataKeyDialog = () => {
    setOpenAddDataKey(true);
    window.history.pushState(null, "");
  };
  const openAddForwardRouteDrawer = () => {
    setOpenAddForwardRoute(true);
    window.history.pushState(null, "");
  };
  const clickEdit = (route) => {
    setSelectedRoute(route);
    setOpenEditForwardRoute(true);
    window.history.pushState(null, "");
  };

  // --- Snackbar feedback handlers ---
  const handleOpenGroupAddedSuccessful = () => {
    setOpenGroupAddedSuccessful(true);
    window.history.pushState(null, "");
  };
  const handleOpenGroupAddedError = () => {
    setOpenGroupAddedError(true);
    window.history.pushState(null, "");
  };
  const handleOpenGroupRemovedSuccessful = () => {
    setOpenGroupRemovedSuccessful(true);
    window.history.pushState(null, "");
  };
  const handleOpenGroupRemovedError = () => {
    setOpenGroupRemovedError(true);
    window.history.pushState(null, "");
  };
  const handleOpenForwardRouteRemovedSuccessful = () => {
    setOpenForwardRouteRemovedSuccessful(true);
    window.history.pushState(null, "");
  };
  const handleOpenForwardRouteRemovedError = () => {
    setOpenForwardRouteRemovedError(true);
    window.history.pushState(null, "");
  };

  if (isLoading) {
    return (
      <Grid container justifyContent="center" mt="30%">
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Grid container spacing={5}>
      {seentag && (
        <>
          <Grid item xs={12}>
            <TagToolbar tag={seentag} isFollowing={isFollowing} handleFollowToggle={handleFollowToggle} alarmOn={alarmOn} handleAlarmToggle={handleAlarmToggle} />
          </Grid>

          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12} sm={10}>
              <Typography variant="h6" fontWeight="bold">
                {t("settings")}
              </Typography>
            </Grid>

            <Grid item container spacing={3}>
              <Grid item xs={12}>
                <Card variant="outlined" sx={{ borderRadius: 1, p: 1 }}>
                  <CardContent>
                    <ViewGroupsTable
                      tag={seentag}
                      groups={viewgroups}
                      openAddViewGroupDrawer={openAddViewGroupDrawer}
                      handleOpenGroupRemovedSuccessful={handleOpenGroupRemovedSuccessful}
                      handleOpenGroupRemovedError={handleOpenGroupRemovedError}
                    />
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Card variant="outlined" sx={{ borderRadius: 1, p: 1 }}>
                  <CardContent>
                    <EnabledDataKeysTable tag={seentag} dataKeys={dataKeys} openAddDataKeyDialog={openAddDataKeyDialog} />
                  </CardContent>
                </Card>
              </Grid>

              {!conservSostL1 && (
                <Grid item xs={12}>
                  <Card variant="outlined" sx={{ borderRadius: 1, p: 1 }}>
                    <CardContent>
                      <ForwardRoutesTable
                        tag={seentag}
                        forwardRoutes={forwardRoutes}
                        openAddForwardRouteDrawer={openAddForwardRouteDrawer}
                        clickEdit={clickEdit}
                        handleSuccessfulRemoved={handleOpenForwardRouteRemovedSuccessful}
                        handleErrorRemoved={handleOpenForwardRouteRemovedError}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              )}

              {!conservSostL1 && (
                <Grid item xs={12}>
                  <AlarmConfiguration tag={seentag} />
                </Grid>
              )}
            </Grid>
          </Grid>

          {/* Render dialogs and snackbars */}
          <AddViewGroup tag={seentag} open={openAddViewGroup} setOpen={setOpenAddViewGroup} handleSuccessfulAdded={handleOpenGroupAddedSuccessful} handleErrorAdded={handleOpenGroupAddedError} />
          <GroupAddedSuccessfulSnackbar open={openGroupAddedSuccessful} setOpen={setOpenGroupAddedSuccessful} />
          <GroupAddedErrorSnackbar open={openGroupAddedError} setOpen={setOpenGroupAddedError} />
          <GroupRemovedSuccessfulSnackbar open={openGroupRemovedSuccessful} setOpen={setOpenGroupRemovedSuccessful} />
          <GroupRemovedErrorSnackbar open={openGroupRemovedError} setOpen={setOpenGroupRemovedError} />
          <AddDataKey tag={seentag} dataKeys={dataKeys} open={openAddDataKey} setOpen={setOpenAddDataKey} />
          <NewTargetEndpoint tag={seentag} open={openAddForwardRoute} setOpen={setOpenAddForwardRoute} />
          {selectedRoute && <EditForwardRoute tag={seentag} route={selectedRoute} open={openEditForwardRoute} setOpen={setOpenEditForwardRoute} />}
          <ForwardRouteRemovedSuccessfulSnackbar open={openForwardRouteRemovedSuccessful} setOpen={setOpenForwardRouteRemovedSuccessful} />
          <ForwardRouteRemovedErrorSnackbar open={openForwardRouteRemovedError} setOpen={setOpenForwardRouteRemovedError} />
        </>
      )}
    </Grid>
  );
};

export default TagSettings;
