// firebase
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getMessaging, getToken } from "firebase/messaging";

// A ---------------------------------------------------------------------- M

// Firebase Configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Init Firebase
const app = initializeApp(firebaseConfig);

// Firebase Services

// Authentication
export const auth = getAuth(app);

// Firestore
export const db = getFirestore(app);

// Storage
export const storage = getStorage(app);

// Cloud Messaging
export const messaging = getMessaging(app);

// Request permission
export const requestPermission = async () => {
  try {
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      const currentToken = await getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY });

      if (currentToken) {
        return currentToken;
      } else {
        console.log("No token available.");
        return null;
      }
    } else {
      console.log("Permission denied - No token available.");
      return null;
    }
  } catch (error) {
    console.error("Error in requestPermission:", error);
    return null;
  }
};

export default app;
