// firebase
import { collection, addDoc, doc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase";

// utils
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

export const addForwardRoute = async (tagID, body) => {
  try {
    const { endpoint, type, status, selectedTypes, headers } = body;
    const tagRef = doc(db, "tagsdata", tagID);
    const forwardRoutesRef = collection(tagRef, "forward_routes");
    const timestamp = unixTimestampInSeconds();

    const docRef = await addDoc(forwardRoutesRef, {
      endpoint,
      type,
      enabled: status,
      types: selectedTypes,
      headers,
      added_on: timestamp,
      last_modified: timestamp,
    });

    console.log(`Document with ID ${docRef.id} has been successfully added to tag ID ${tagID}.`);
  } catch (error) {
    console.error(`Error in addForwardRoute for tagID ${tagID}:`, error);
  }
};

export const updateForwardRoute = async (tagID, route, body) => {
  try {
    const { endpoint, type, status, selectedTypes, headers } = body;
    const forwardRouteDocRef = doc(db, "tagsdata", tagID, "forward_routes", route);

    await updateDoc(forwardRouteDocRef, {
      endpoint,
      type,
      enabled: status,
      types: selectedTypes,
      headers,
      last_modified: unixTimestampInSeconds(),
    });

    console.log(`Document with route ID ${route} in tag ID ${tagID} has been successfully updated.`);
  } catch (error) {
    console.error(`Error in updateForwardRoute for route ID ${route} in tag ID ${tagID}:`, error);
  }
};
