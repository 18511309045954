// firebase
import { collection, doc, setDoc } from "firebase/firestore";
import { db } from "config/firebase";

// A ---------------------------------------------------------------------- M

const logEvent = async (databoxID, eventType, timestamp, description, metadata, performedBy, txid = null, severity = "informative") => {
  try {
    if (!databoxID) {
      console.error("Databox ID is required to log an event");
      return;
    }

    const eventsRef = collection(db, "tagsdata", databoxID, "events");
    const eventDocRef = doc(eventsRef);

    const eventData = {
      eventType,
      timestamp,
      performedBy,
      description,
      metadata,
      databoxID,
      txid,
      severity,
    };

    await setDoc(eventDocRef, eventData);
    console.log(`Event of type '${eventType}' logged successfully for databox '${databoxID}'.`);
  } catch (error) {
    console.error("Error logging event:", error);
  }
};

export default logEvent;
