// react
import React, { useState } from "react";

// react-router-dom
import { useNavigate } from "react-router-dom";

// context
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// react-flagkit
import Flag from "react-flagkit";

// @mui
import { useTheme, useMediaQuery, Link, Box, Grid, Paper, AppBar, Toolbar, Typography, IconButton, Tooltip, Select, MenuItem, BottomNavigation, BottomNavigationAction } from "@mui/material";
import { Logout as LogoutIcon, AccountCircle as AccountCircleIcon, Archive as ArchiveIcon, Groups as GroupsIcon, QrCodeScanner as QrCodeScannerIcon } from "@mui/icons-material";

// ui-components
import ConservazioneSostitutivaSidebar from "./ConservazioneSostitutivaSidebar";
import QRCodeScanner from "ui-components/DataboxManagement/QRCodeScanner";

// A ---------------------------------------------------------------------- M

const Logo = () => (
  <Link href="/">
    <img src="/logo/armilis-logo.png" alt="Company logo" height="30" />
  </Link>
);

const LanguageOption = ({ country, label }) => (
  <Box display="flex" alignItems="center">
    <Flag country={country} style={{ marginRight: 8 }} />
    {label}
  </Box>
);

const bottomNavRoutes = ["/", "/my-certifications", "/my-groups", "/qr-scanner", "/my-account"];

const ConservazioneSostitutivaLayout = ({ children }) => {
  const { user, language, setLanguage, logout } = UserAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [openQRCodeScanner, setOpenQRCodeScanner] = useState(false);
  const [bottomNavValue, setBottomNavValue] = useState(0);

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/signin");
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const handleMyAccount = () => navigate("/my-account");
  const handleLanguageChange = (e) => setLanguage(e.target.value);

  const openQRCodeScannerDrawer = () => {
    setOpenQRCodeScanner(true);
    window.history.pushState(null, "");
  };

  const handleBottomNavChange = (event, newValue) => {
    setBottomNavValue(newValue);
    const route = bottomNavRoutes[newValue];
    if (route === "/qr-scanner") {
      openQRCodeScannerDrawer();
    } else {
      navigate(route);
    }
  };

  const drawerWidth = 230;

  const appBarStyle = {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.primary.main,
    boxShadow: "none",
    color: "#fff",
    padding: "0 16px",
  };

  const gridItemStyle = isMobile ? { width: "100%", p: 2, pb: 8 } : { width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px`, p: 2 };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <AppBar position="fixed" sx={appBarStyle}>
            <Toolbar variant="dense" sx={{ minHeight: 55 }}>
              <Logo />
              <Box sx={{ flexGrow: 1 }} />
              <Select value={language} onChange={handleLanguageChange} variant="standard" disableUnderline sx={{ color: "#fff", minWidth: 50, mr: 2 }}>
                <MenuItem value="it">
                  <LanguageOption country="IT" label="Italiano" />
                </MenuItem>
                <MenuItem value="en">
                  <LanguageOption country="GB" label="English" />
                </MenuItem>
              </Select>
              {!isMobile && user && (
                <Typography
                  variant="body2"
                  sx={{
                    mx: 2,
                    maxWidth: "250px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                  fontWeight="medium"
                  title={user.email}
                >
                  {user.email}
                </Typography>
              )}
              {!isMobile && (
                <Tooltip title={t("my_account")}>
                  <IconButton onClick={handleMyAccount} sx={{ color: "#fff" }}>
                    <AccountCircleIcon />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title={t("logout")}>
                <IconButton onClick={handleLogout} sx={{ color: "#fff" }}>
                  <LogoutIcon />
                </IconButton>
              </Tooltip>
            </Toolbar>
          </AppBar>
          <Toolbar />
        </Grid>
        <Grid item sx={gridItemStyle}>
          {children}
        </Grid>
        {isMobile && (
          <Paper
            sx={{
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              boxShadow: "none",
              borderTop: "1px solid #ccc",
            }}
          >
            <BottomNavigation
              showLabels
              value={bottomNavValue}
              onChange={handleBottomNavChange}
              sx={{
                bgcolor: theme.palette.background.paper,
                "& .MuiBottomNavigationAction-root": {
                  color: theme.palette.text.primary,
                  "&.Mui-selected": {
                    color: theme.palette.primary.main,
                  },
                },
              }}
            >
              <BottomNavigationAction label={t("my_archives")} icon={<ArchiveIcon />} />
              <BottomNavigationAction label={t("my_groups")} icon={<GroupsIcon />} />
              <BottomNavigationAction label={t("scan")} icon={<QrCodeScannerIcon />} />
              <BottomNavigationAction label={t("account")} icon={<AccountCircleIcon />} />
            </BottomNavigation>
          </Paper>
        )}
      </Grid>
      {!isMobile && <ConservazioneSostitutivaSidebar drawerWidth={drawerWidth} openQRCodeScannerDrawer={openQRCodeScannerDrawer} />}
      {isMobile && <QRCodeScanner open={openQRCodeScanner} setOpen={setOpenQRCodeScanner} />}
    </>
  );
};

export default ConservazioneSostitutivaLayout;
