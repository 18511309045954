// firebase
import { doc, collection, getDocs, getDoc } from "firebase/firestore";
import { db } from "config/firebase";

// datakey-controller
import getDataKeyData from "datakey-controller/getDataKeyData";

// A ---------------------------------------------------------------------- M

const getAuthorizedDataKeys = async (databoxID) => {
  try {
    const authorizedKeysRef = collection(db, "tagsdata", databoxID, "authorized_keys");
    const snapshot = await getDocs(authorizedKeysRef);

    const authorizedKeysPromises = snapshot.docs.map(async (authKey) => {
      const keyData = await getDataKeyData(authKey.id);
      const { added_on: keyAddedOn } = authKey.data();
      const { name: keyName = "", owner_uuid: keyOwner = "" } = keyData || {};

      const keySecretDocRef = doc(db, "keysecrets", authKey.id);
      const keySecretSnapshot = await getDoc(keySecretDocRef);
      const keySecretData = keySecretSnapshot.exists() ? keySecretSnapshot.data() : {};

      return {
        id: authKey.id,
        name: keyName,
        added_on: keyAddedOn,
        owner_uuid: keyOwner,
        datakey: keySecretData.datakey || "",
      };
    });

    const authorizedKeysArray = await Promise.all(authorizedKeysPromises);
    return { authorizedKeysArray };
  } catch (error) {
    console.error(`Error in getAuthorizedDataKeys for databoxID ${databoxID}:`, error);
    return { authorizedKeysArray: [] };
  }
};

export default getAuthorizedDataKeys;
