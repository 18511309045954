// firebase
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "config/firebase";

// A ---------------------------------------------------------------------- M

const fetchTotalRecordsCount = async (tagId, type, isTagGroupMember, criteria) => {
  console.log(tagId, type, isTagGroupMember, criteria);

  try {
    const recordsRef = collection(db, "tagsdata", tagId, "signatures");
    let baseQuery = query(recordsRef, where("type", "==", type));

    if (!isTagGroupMember) {
      baseQuery = query(baseQuery, where("public", "==", true));
    }

    // Array per raccogliere i filtri "array-contains" da applicare
    let arrayFilters = [];

    // Applichiamo i filtri diretti (ad es. date) e raccogliamo quelli che devono essere applicati con "array-contains"
    if (criteria) {
      Object.entries(criteria).forEach(([key, value]) => {
        if (value) {
          if (typeof value === "string") {
            value = value.trim();
          }
          if (key === "startDate") {
            baseQuery = query(baseQuery, where("timestamp", ">=", value));
          } else if (key === "endDate") {
            baseQuery = query(baseQuery, where("timestamp", "<=", value));
          } else if (key === "documentNames") {
            arrayFilters.push({ field: "searchIndex.documentNames", value });
          } else if (key === "soggettiFullName") {
            arrayFilters.push({ field: "searchIndex.soggettiFullName", value });
          } else if (key === "soggettiCodiceFiscale") {
            arrayFilters.push({ field: "searchIndex.soggettiCodiceFiscale", value });
          } else if (key === "soggettiOrganizzazione") {
            arrayFilters.push({ field: "searchIndex.soggettiOrganizzazione", value });
          } else {
            // Per i custom params costruiamo "key:value"
            arrayFilters.push({ field: "searchIndex.customParams", value: `${key}:${value}` });
          }
        }
      });
    }

    // Mappa di priorità per decidere quale filtro "array-contains" applicare direttamente
    const priority = {
      soggettiCodiceFiscale: 1,
      soggettiFullName: 2,
      documentNames: 3,
      soggettiOrganizzazione: 4,
      customParams: 5,
    };

    let primaryFilter = null;
    if (arrayFilters.length > 0) {
      // Selezioniamo il filtro più selettivo in base alla priorità
      primaryFilter = arrayFilters.reduce((best, current) => {
        const bestField = best.field.split(".")[1];
        const currentField = current.field.split(".")[1];
        return priority[currentField] < priority[bestField] ? current : best;
      });
      // Rimuoviamo il filtro primario dall'array di filtri
      arrayFilters = arrayFilters.filter((filter) => filter !== primaryFilter);
      // Applichiamo il filtro primario direttamente nella query Firestore
      baseQuery = query(baseQuery, where(primaryFilter.field, "array-contains", primaryFilter.value));
    }

    // Recuperiamo tutti i documenti che rispettano la query
    const snapshot = await getDocs(baseQuery);
    let rawDocs = snapshot.docs.map((doc) => doc.data());

    // Applichiamo manualmente il filtering per gli altri filtri "array-contains" (logica AND)
    if (arrayFilters.length > 0) {
      rawDocs = rawDocs.filter((doc) => {
        return arrayFilters.every((filter) => {
          const fieldName = filter.field.split(".")[1];
          const fieldValue = doc.searchIndex[fieldName];
          return Array.isArray(fieldValue) ? fieldValue.includes(filter.value) : false;
        });
      });
    }

    return rawDocs.length;
  } catch (error) {
    console.error("Error fetching total record count:", error);
    return 0;
  }
};

export default fetchTotalRecordsCount;
