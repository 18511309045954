export const initialDocumentProperties = {
  idDocumento: "",
  nomeDocumento: "",
  hash: "",
  algoritmo: "",
  formatoDocumento: "",
  versioneDocumento: "",
  numeroAllegati: "",
  descrizioneAllegato: "",
  idDocumentoPrimario: "",
};
