// firebase
import { collection, getDocs } from "firebase/firestore";
import { db } from "config/firebase";

// datakey-controller
import getDataKeyData from "datakey-controller/getDataKeyData";

// A ---------------------------------------------------------------------- M

const getFlowEvents = async (databoxId) => {
  try {
    const keysRef = collection(db, "tagsdata", databoxId, "authorized_keys");
    const keysSnapshot = await getDocs(keysRef);

    if (keysSnapshot.empty) {
      return { sources: [], events: [] };
    }

    const sources = await Promise.all(
      keysSnapshot.docs.map(async (keyDoc) => {
        const keyId = keyDoc.id;
        const keyData = keyDoc.data();
        const [extraKeyData, eventsSnapshot] = await Promise.all([getDataKeyData(keyId), getDocs(collection(db, "tagsdata", databoxId, "authorized_keys", keyId, "flow_events"))]);

        const mergedKeyData = { ...keyData, ...extraKeyData };
        const flow_events = eventsSnapshot.docs
          .map((evtDoc) => ({
            id: evtDoc.id,
            ...evtDoc.data(),
          }))
          .sort((a, b) => b.timestamp - a.timestamp);

        return { keyId, ...mergedKeyData, flow_events };
      })
    );

    const events = sources
      .flatMap((source) =>
        source.flow_events.map((evt) => ({
          ...evt,
          sourceName: source.name || "",
        }))
      )
      .sort((a, b) => b.timestamp - a.timestamp);

    return { sources, events };
  } catch (error) {
    console.error("Error in getFlowEvents:", error);
    return { sources: [], events: [] };
  }
};

export default getFlowEvents;
