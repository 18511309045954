// react
import React, { useEffect, memo, useMemo } from "react";

// prop-types
import PropTypes from "prop-types";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import { Dialog, DialogTitle, DialogContent, Typography, Box, Grid, IconButton, Paper, SwipeableDrawer, useMediaQuery, Button, Tooltip } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

// ui-components
import { Puller } from "../Puller";

// utils
import convertTimestamp from "utils/convertTimestamp";

// A ---------------------------------------------------------------------- M

const RecursiveDisplay = memo(({ data, level = 0, handleFileClick }) => {
  const { t } = useTranslation();

  const content = useMemo(() => {
    if (!data || typeof data !== "object") {
      return null;
    }

    const sortedEntries = Object.entries(data).sort(([keyA], [keyB]) => keyA.localeCompare(keyB));
    return sortedEntries.map(([key, value]) => <RecursiveDisplayEntry key={key} keyName={key} value={value} level={level} handleFileClick={handleFileClick} t={t} />);
  }, [data, level, handleFileClick, t]);

  return <>{content}</>;
});

const RecursiveDisplayEntry = ({ keyName, value, level, handleFileClick, t }) => {
  const isAttachment = keyName === "attachment";
  const isObject = typeof value === "object" && value !== null;

  return (
    <Box
      sx={{
        marginBottom: 1,
        paddingLeft: level * 2,
        borderLeft: level > 0 ? "1px solid #ddd" : "none",
      }}
    >
      <Typography
        variant="body1"
        sx={{
          fontWeight: "bold",
          marginBottom: 0.5,
          marginRight: 1,
        }}
      >
        {keyName}:
      </Typography>
      {isAttachment ? (
        <Button variant="outlined" onClick={() => handleFileClick(value)}>
          {t("open_file")}
        </Button>
      ) : isObject ? (
        <RecursiveDisplay data={value} level={level + 1} handleFileClick={handleFileClick} />
      ) : (
        <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
          {value ?? "N/A"}
        </Typography>
      )}
    </Box>
  );
};

const DataDialog = ({ data, open, onClose, handleFileClick }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    const handlePopstate = () => {
      if (open) {
        onClose();
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [open, onClose]);

  const typeMap = {
    info: t("note"),
    doc: t("document"),
    img: t("image"),
    mps: t("mps"),
    CDNFile: t("file"),
    sensor_data_aggregated: t("sensor_data_aggregated"),
    ai_data_out: t("ai_data_out"),
    like_sirti: t("like_sirti"),
  };

  const type = typeMap[data.type] || t("custom");

  const renderDetailItem = (label, value) => (
    <Grid item xs={12}>
      <Paper sx={{ padding: 2 }}>
        <Typography fontWeight="bold">
          {label}
          <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
            {value ?? "N/A"}
          </Typography>
        </Typography>
      </Paper>
    </Grid>
  );

  const DetailsForm = (
    <Grid container spacing={3}>
      {renderDetailItem(t("record_id"), data?.id)}
      {renderDetailItem(t("databox"), data?.databoxName)}
      {renderDetailItem(t("generation_datetime"), data?.timestamp ? convertTimestamp(data.timestamp) : "N/A")}
      {renderDetailItem(t("creator"), data?.creator_email || data?.creator_uuid)}
      {renderDetailItem(t("type"), type)}
      {renderDetailItem(t("visibility"), data?.public ? t("public") : t("private"))}
      <Grid item xs={12}>
        <Paper>
          <Typography fontWeight="bold" gutterBottom>
            {t("data")}
          </Typography>
          <Box
            sx={{
              padding: 2,
              backgroundColor: "#f5f5f5",
              borderRadius: 2,
              maxHeight: "300px",
              overflow: "auto",
            }}
          >
            <RecursiveDisplay data={data?.data || {}} handleFileClick={handleFileClick} type={data?.type} />
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );

  return isMobile ? (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={() => {}}
      sx={{
        "& .MuiDrawer-paper": {
          width: "100%",
          height: "90%",
          borderTopLeftRadius: "4%",
          borderTopRightRadius: "4%",
        },
      }}
    >
      <Puller />
      <Box sx={{ p: "5%" }}>
        <Grid container alignItems="center" justifyContent="center" mt="5%" mb="10%">
          <Grid item>
            <Typography variant="h6" fontWeight="bold">
              {t("record_details")}
            </Typography>
          </Grid>
        </Grid>
        {DetailsForm}
      </Box>
    </SwipeableDrawer>
  ) : (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "10px",
          padding: 2,
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
        },
      }}
    >
      <DialogTitle>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5" fontWeight="bold">
              {t("record_details")}
            </Typography>
          </Grid>
          <Grid item>
            <Tooltip title={t("close")} placement="top">
              <IconButton onClick={onClose} color="error" edge="end">
                <CloseOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>{DetailsForm}</DialogContent>
    </Dialog>
  );
};

DataDialog.propTypes = {
  data: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleFileClick: PropTypes.func.isRequired,
};

export default DataDialog;
