import sendDataStreamCDNFile from "./sendDataStreamCDNFile";

// event-logger
import logEvent from "event-logger/logEvent";

// utils
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

const genCDNFileRecord = async (userID, dataBody, databoxID) => {
  const timestamp = unixTimestampInSeconds();

  try {
    if (!databoxID) {
      console.error("Error in genCDNFileRecord: missing databoxID");
      return {
        success: false,
        error: "Error in genCDNFileRecord: missing databoxID",
      };
    }

    // Data stream
    const result = await sendDataStreamCDNFile(userID, dataBody, timestamp, databoxID);

    if (!result.success) {
      // Log data stream error
      await logEvent(
        databoxID,
        "event_archive_upload_error",
        timestamp,
        "Upload failed.",
        {
          dataType: "CDNFile",
          errorMessage: result.error,
          uploadStatus: false,
        },
        userID
      );
    } else {
      // Log data stream success
      await logEvent(
        databoxID,
        "event_archive_upload_success",
        timestamp,
        "Upload successfully performed.",
        {
          documentID: result.txid,
          dataType: "CDNFile",
          uploadStatus: true,
        },
        userID
      );
    }

    console.log(result);
    return result;
  } catch (error) {
    console.error("Error in genCDNFileRecord:", error);

    if (databoxID) {
      // Log a generic error for the databox
      await logEvent(
        databoxID,
        "event_archive_upload_error",
        timestamp,
        "An unexpected error occurred during the upload process.",
        {
          dataType: "CDNFile",
          errorMessage: error.message,
          uploadStatus: false,
        },
        userID
      );
    }

    const result = {
      success: false,
      error: error.message,
    };

    console.log(result);
    return result;
  }
};

export default genCDNFileRecord;
