// firebase
import { doc, getDoc } from "firebase/firestore";
import { db } from "config/firebase";

// A ---------------------------------------------------------------------- M

const isUserFollowingDatabox = async (userID, tagID) => {
  try {
    const tagDocRef = doc(db, "tagsdata", tagID);
    const tagDocSnapshot = await getDoc(tagDocRef);

    if (!tagDocSnapshot.exists()) {
      return false;
    }

    return tagDocSnapshot.data()?.followers?.includes(userID) || false;
  } catch (error) {
    console.error(`Error in isUserFollowingDatabox for uid ${userID} and tag ${tagID}:`, error);
    return false;
  }
};

export default isUserFollowingDatabox;
