// firebase
import { doc, getDoc } from "firebase/firestore";
import { db } from "config/firebase";

// user-controller
import getUserContacts from "user-controller/getUserContacts";

// A ---------------------------------------------------------------------- M

const getGroup = async (groupID) => {
  try {
    const groupRef = doc(db, "groupsdata", groupID);
    const group = await getDoc(groupRef);
    const groupData = group.data();
    const creator_uuid = groupData.creator_uuid;
    const userContacts = await getUserContacts(creator_uuid);
    const creator_email = userContacts.email || "N/A";

    return {
      id: groupID,
      creator_email: creator_email,
      ...groupData,
    };
  } catch (error) {
    console.error("Error in getGroup:", error);
    return {};
  }
};

export default getGroup;
