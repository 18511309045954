// firebase
import { getStorage, ref, getDownloadURL } from "firebase/storage";

// A ---------------------------------------------------------------------- M

const downloadXMLFile = async (filePath, type, ID) => {
  const storage = getStorage();
  const fileRef = ref(storage, filePath);

  try {
    const url = await getDownloadURL(fileRef);
    const response = await fetch(url);
    const blob = await response.blob();
    const urlObject = URL.createObjectURL(blob);

    let fileName;

    switch (type) {
      case "AIC":
        fileName = `${ID}_AIC.xml`;
        break;
      case "AIC_signed":
        fileName = `${ID}_AIC_signed.xml.p7m`;
        break;
      case "AIC_timestamp":
        fileName = `${ID}_AIC_timestamp.xml`;
        break;
      case "IdC":
        fileName = `${ID}_IdC.xml`;
        break;
      case "IdC_signed":
        fileName = `${ID}_IdC_signed.xml.p7m`;
        break;
      case "gluer":
        fileName = `${ID}_gluer.xml`;
        break;
      case "metadata":
        fileName = `${ID}_metadata.xml`;
        break;
      default:
        fileName = filePath.split("/").pop();
    }

    const a = document.createElement("a");
    a.href = urlObject;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(urlObject);
    console.log("File downloaded successfully");
  } catch (error) {
    console.error("Error downloading the file:", error);
  }
};

export default downloadXMLFile;
