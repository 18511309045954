import { db } from "config/firebase";
import { doc, setDoc, onSnapshot } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { genRndString } from "SafeTwin/crypto/cryptolibsodium";

// A ---------------------------------------------------------------------- M

const uploadFile = async (fileByteArray, fileName, fileType, tagID, isPublic = false, user) => {
  return new Promise(async (resolve, reject) => {
    try {
      const uploadID = genRndString(32);
      const storage = getStorage();
      const storageRef = ref(storage, `${uploadID}/${fileName}`);
      const statusRef = doc(db, "uploadStatuses", uploadID);

      console.log("uploadID:", uploadID);

      await setDoc(statusRef, {
        createdBy: user.uid,
        createdByEmail: user.email,
        totalCount: 1,
        validCount: 0,
        invalidCount: 0,
        status: "processing",
      });

      // prettier-ignore
      const metadata = {
        contentType: fileType,
        customMetadata: {
          'isPublic': isPublic ? "true" : "false",
          'requiresValidation': "true",
        }
      };

      if (tagID) {
        metadata.customMetadata["tagID"] = tagID;
      }

      try {
        console.log("uploading bytes...");
        await uploadBytes(storageRef, fileByteArray, metadata);
      } catch (error) {
        console.error("Error in uploading bytes:", error);
      }

      const unsubscribe = onSnapshot(statusRef, async (doc) => {
        if (doc.exists) {
          const status = doc.data().status;
          console.log("status:", status);

          if (status === "success") {
            unsubscribe();
            const downloadURL = await getDownloadURL(storageRef);
            resolve({ uploadID: uploadID, downloadURL: downloadURL });
          } else if (status === "error") {
            unsubscribe();
            reject(new Error("File is invalid."));
          }
        }
      });
    } catch (error) {
      console.error("Error in uploadFile:", error);
      reject(error);
    }
  });
};

export default uploadFile;
