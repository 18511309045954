import React from "react";
import { Typography, Paper } from "@mui/material";

// A ---------------------------------------------------------------------- M

const DataCard = ({ title, children }) => {
  return (
    <Paper
      sx={{
        p: 2,
        borderRadius: "8px",
        boxShadow: "0px 4px 12px rgba(0,0,0,0.2)",
        backgroundColor: "#fff",
      }}
    >
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      {children}
    </Paper>
  );
};

export default DataCard;
