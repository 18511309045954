// firebase
import { collection, setDoc, doc, getDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { db } from "config/firebase";

// utils
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

export const addMember = async (newMemberUID, groupID) => {
  try {
    const groupRef = doc(db, "groupsdata", groupID);
    const group = await getDoc(groupRef);
    const usersRef = collection(groupRef, "users");
    const userRef = doc(usersRef, newMemberUID);
    const userSnapshot = await getDoc(userRef);

    if (userSnapshot.exists()) {
      return { status: "user_already_added" };
    }

    await setDoc(userRef, {
      uid: newMemberUID,
      added_on: unixTimestampInSeconds(),
    });

    await updateDoc(groupRef, {
      members: group.data().members + 1,
    });

    return { status: "success", documentId: userRef.id };
  } catch (error) {
    console.error("Error in addMember:", error);
    return { status: "error", message: error.message };
  }
};

export const removeMember = async (memberUID, groupID) => {
  try {
    const groupRef = doc(db, "groupsdata", groupID);
    const group = await getDoc(groupRef);
    const usersRef = collection(groupRef, "users");
    const userRef = doc(usersRef, memberUID);

    await deleteDoc(userRef);
    await updateDoc(groupRef, { members: group.data().members - 1 });
    return { code: 0 };
  } catch (error) {
    console.error("Error in removeMember:", error);
    return { code: -1 };
  }
};
