// SafeTwin
import { calculateSHA256, toHex } from "SafeTwin/crypto/cryptolibsodium";

// storage
import getFile from "storage/getFile";

// A ---------------------------------------------------------------------- M

const hashCalculators = {
  CDNFile: async (record) => {
    const fileByteArray = await getFile(record.filesData.aicAttachment);

    if (!fileByteArray) {
      return null;
    }

    const fileSHA256hex = toHex(calculateSHA256(fileByteArray));
    return fileSHA256hex;
  },
  ai_data_out: async (record) => {
    return toHex(calculateSHA256(record.data.alarm + record.data.prediction + record.data.sensor_id + record.data.timestamp + record.data.windowEnd + record.data.windowStart));
  },
  cfg: async (record) => {
    return toHex(calculateSHA256(record.data.jsonconfig + record.data.target + record.data.timestamp));
  },
  doc: async (record) => {
    const fileByteArray = await getFile(record.data.attachment);

    if (!fileByteArray) {
      return null;
    }

    const fileSHA256hex = toHex(calculateSHA256(fileByteArray));
    return toHex(calculateSHA256(fileSHA256hex + record.data.comment + record.data.timestamp));
  },
  img: async (record) => {
    const fileByteArray = await getFile(record.data.attachment);

    if (!fileByteArray) {
      return null;
    }

    const fileSHA256hex = toHex(calculateSHA256(fileByteArray));
    return toHex(calculateSHA256(fileSHA256hex + record.data.caption + record.data.timestamp));
  },
  info: async (record) => {
    return toHex(calculateSHA256(record.data.text + record.data.timestamp));
  },
  like_sirti: async (record) => {
    return toHex(calculateSHA256(record.data.c?.transactionPayload));
  },
  mps: async (record) => {
    return toHex(calculateSHA256(record.data.value + record.data.uom + record.data.timestamp));
  },
  sensor_data_aggregated: async (record) => {
    return toHex(
      calculateSHA256(
        record.data.avg +
          record.data.end +
          record.data.magnitude +
          record.data.measure_unit +
          record.data.sensor_id +
          record.data.start +
          record.data.stddev +
          record.data.timestamp_end +
          record.data.timestamp_start
      )
    );
  },
};

export const calculateRecordHash = async (record, type) => {
  const calculator = hashCalculators[type];
  return calculator ? await calculator(record) : "";
};
