// firebase
import { collection, getDocs, query, orderBy, limit } from "firebase/firestore";
import { db } from "config/firebase";

// A ---------------------------------------------------------------------- M

const fetchAlarmEvents = async (tagID, maxRecords = 10) => {
  try {
    const alarmEventsRef = collection(db, "tagsdata", tagID, "alarm_events");
    const q = query(alarmEventsRef, orderBy("timestamp", "desc"), limit(maxRecords));
    const querySnapshot = await getDocs(q);

    const alarmEvents = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return alarmEvents;
  } catch (error) {
    console.error("Error fetching alarm events:", error);
    throw error;
  }
};

export default fetchAlarmEvents;
