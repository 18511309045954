// react
import React, { useState, useEffect, useCallback } from "react";

// @mui
import { Grid, CircularProgress } from "@mui/material";

// ui-components
import ClientsTable from "ui-components/CustomerManagement/ClientsTable";
import ClientFormDialog from "ui-components/CustomerManagement/ClientFormDialog";

// firebase
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "config/firebase";

// customer-controller
import getCustomers from "customer-controller/getCustomers";
import postCustomer from "customer-controller/postCustomer";

// A ---------------------------------------------------------------------- M

const ClientList = () => {
  const [clients, setClients] = useState([]);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Load clients
  const loadClients = useCallback(async () => {
    try {
      setIsLoading(true);
      const clientsList = await getCustomers();
      clientsList.sort((client1, client2) => client2.generationTimestamp - client1.generationTimestamp);
      setClients(clientsList);
    } catch (error) {
      console.error("Error loading clients:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Setup initial load and subscription for new client additions
  useEffect(() => {
    loadClients();

    const currentTimestamp = Math.floor(Date.now() / 1000).toString();
    const clientsCollectionRef = collection(db, "clientsdata");
    const newAdditionsQuery = query(clientsCollectionRef, where("generationTimestamp", ">=", currentTimestamp));

    const unsubscribe = onSnapshot(newAdditionsQuery, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          loadClients();
        }
      });
    });

    return () => unsubscribe();
  }, [loadClients]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCreateClient = async (newClientData) => {
    try {
      await postCustomer(newClientData);
      handleClose();
    } catch (error) {
      console.error("Error creating client:", error);
    }
  };

  if (isLoading) {
    return (
      <Grid container justifyContent="center" mt="30%">
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ClientsTable clients={clients} handleOpen={handleOpen} />
      </Grid>
      <ClientFormDialog open={open} handleClose={handleClose} handleCreateClient={handleCreateClient} />
    </Grid>
  );
};

export default ClientList;
