import React from "react";
import { Handle, Position } from "reactflow";
import "reactflow/dist/style.css";
import { Typography, Card, Table, TableBody, TableCell, TableRow, TableHead, Tooltip } from "@mui/material";
import { CheckCircleOutline as CheckCircleOutlineIcon, Cancel as CancelIcon } from "@mui/icons-material";

// A ---------------------------------------------------------------------- M

const TargetNode = ({ data }) => {
  return (
    data && (
      <Card
        variant="outlined"
        sx={{
          width: "800px",
          p: 3,
          borderRadius: "8px",
          backgroundColor: "rgba(0, 47, 108, 0.9)",
          backgroundImage: "linear-gradient(135deg, #002f6c 0%, #00509e 100%)",
          border: "1px solid rgba(255, 255, 255, 0.1)",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.3)",
          color: "#ffffff",
          overflow: "hidden",
        }}
      >
        <Typography
          variant="h6"
          align="center"
          gutterBottom
          fontWeight="bold"
          sx={{
            color: "#ffffff",
            borderBottom: "2px solid #FFA500",
            pb: 1,
            letterSpacing: "1px",
          }}
        >
          Target
        </Typography>

        <Table size="small" sx={{ backgroundColor: "transparent" }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography
                  variant="subtitle2"
                  fontWeight="bold"
                  sx={{
                    textTransform: "uppercase",
                    color: "#b0bec5",
                    letterSpacing: "0.5px",
                  }}
                >
                  Endpoint
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  variant="subtitle2"
                  fontWeight="bold"
                  sx={{
                    textTransform: "uppercase",
                    color: "#b0bec5",
                    letterSpacing: "0.5px",
                  }}
                >
                  Type
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  variant="subtitle2"
                  fontWeight="bold"
                  sx={{
                    textTransform: "uppercase",
                    color: "#b0bec5",
                    letterSpacing: "0.5px",
                  }}
                >
                  Data Types
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  variant="subtitle2"
                  fontWeight="bold"
                  sx={{
                    textTransform: "uppercase",
                    color: "#b0bec5",
                    letterSpacing: "0.5px",
                  }}
                >
                  Status
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              sx={{
                backgroundColor: data.target.enabled ? "#263238" : "#37474f",
                "&:hover": {
                  backgroundColor: "rgba(76, 175, 80, 0.1)",
                  cursor: "pointer",
                },
              }}
            >
              <TableCell>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 500,
                    color: "#eceff1",
                  }}
                >
                  {data.target.endpoint || "N/A"}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 500,
                    color: "#eceff1",
                  }}
                >
                  {data.target.type || "REST"}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 500,
                    color: "#eceff1",
                  }}
                >
                  {data.target.types?.length > 0 ? data.target.types.join(", ") : "N/A"}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Tooltip title={data.target.enabled ? "Enabled" : "Disabled"} arrow>
                  {data.target.enabled ? (
                    <CheckCircleOutlineIcon
                      sx={{
                        color: "#4caf50",
                        verticalAlign: "middle",
                        marginLeft: "5px",
                      }}
                    />
                  ) : (
                    <CancelIcon
                      sx={{
                        color: "#f44336",
                        verticalAlign: "middle",
                        marginLeft: "5px",
                      }}
                    />
                  )}
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        {/* ReactFlow Handle */}
        <Handle
          type="target"
          position={Position.Left}
          id={`handle-${data.target.endpoint}`}
          style={{
            background: "#4caf50",
            border: "2px solid #ffffff",
            width: "12px",
            height: "12px",
          }}
        />
      </Card>
    )
  );
};

export default TargetNode;
