// react
import React, { useState, useEffect } from "react";

// context
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import { useMediaQuery, Box, Grid, SwipeableDrawer, Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Typography, Divider } from "@mui/material";
import { CloseOutlined as CloseOutlinedIcon, FileUpload as FileUploadIcon, FileOpen as FileOpenIcon, Add as AddIcon } from "@mui/icons-material";

// ui-components
import CDNFileForm from "./CDNFileForm";
import CDNFileFormUpload from "./CDNFileFormUpload";
import FileViewer from "ui-components/FileViewer";
import AttributeDetailsDialog from "./AttributeDetailsDialog";
import CDNFileUploadReceipt from "./CDNFileUploadReceipt";
import { Puller } from "ui-components/Puller";
import { LoadingDialog } from "ui-components/LoadingComponent";
import { NewCertConfirm, InvalidFile } from "ui-components/ORFeedbacks";
import { initialUploadProperties } from "./initialUploadProperties";
import { initialDocumentProperties } from "./initialDocumentProperties";

// generator
import genCDNFileRecord from "generator/CDNFileGenerator/genCDNFileRecord";

// SafeTwin
import { calculateSHA256, toHex, genRndString } from "SafeTwin/crypto/cryptolibsodium";

// firebase
import { doc, setDoc, onSnapshot } from "firebase/firestore";
import { db } from "config/firebase";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

// utils
import fileToUint8Array from "utils/fileToUint8Array";

// A ---------------------------------------------------------------------- M

const MAX_FILE_SIZE = 25 * 1024 * 1024;

const getButtonStyle = (action) => ({
  py: 0.5,
  borderColor: "divider",
  color: action === "close" ? "error.main" : "primary.main",
  textTransform: "none",
  boxShadow: "none",
  borderRadius: 10,
  "&:hover": {
    borderColor: action === "close" ? "error.dark" : "primary.dark",
    backgroundColor: "action.hover",
  },
});

const CDNFileGenerator = ({ tag, types, setTypes, open, setOpen, handleOpenCertificationSuccessful, handleOpenCertificationError }) => {
  const { t } = useTranslation();
  const { user } = UserAuth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  // State for file upload & preview
  const [fileType, setFileType] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [xmlContent, setXmlContent] = useState(null);
  const [textContent, setTextContent] = useState(null);
  const [numPages, setNumPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [confirm, setConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isInvalidFile, setIsInvalidFile] = useState(false);
  const [uploadReceipt, setUploadReceipt] = useState(null);
  const [uploadID, setUploadID] = useState(null);
  const [receiptDialogOpen, setReceiptDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [files, setFiles] = useState([]);
  const [uploadProperties, setUploadProperties] = useState(initialUploadProperties);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [primaryDocumentIndex, setPrimaryDocumentIndex] = useState(null);

  // FileViewer properties state
  const [fileViewerProps, setFileViewerProps] = useState({
    fileType: null,
    xmlContent: null,
    textContent: null,
  });

  // Handle browser popstate to reset if dialog is open
  useEffect(() => {
    const handlePopstate = () => {
      if (open) {
        handleReset();
      }
    };
    window.addEventListener("popstate", handlePopstate);
    return () => window.removeEventListener("popstate", handlePopstate);
  }, [open]);

  // Listen for receipt updates
  useEffect(() => {
    if (!uploadID) return;
    const receiptRef = doc(db, "uploadReceipts", uploadID);
    const unsubscribe = onSnapshot(receiptRef, (docSnap) => {
      if (docSnap.exists()) {
        setUploadReceipt(docSnap.data());
        setReceiptDialogOpen(true);
      }
    });
    return () => unsubscribe();
  }, [uploadID]);

  useEffect(() => {
    if (tag?.tipologiaDocumentale) {
      setUploadProperties((prev) => ({
        ...prev,
        tipologiaDocumentale: tag.tipologiaDocumentale,
      }));
    }
  }, [tag]);

  const handleAddNewFile = () => {
    const newFiles = [...files, {}];
    setFiles(newFiles);
    if (newFiles.length === 1) setPrimaryDocumentIndex(0);
  };

  const handleFileChange = async (target, index) => {
    if (target?.files?.length > 0) {
      const file = target.files[0];
      if (file.size > MAX_FILE_SIZE) {
        console.error("File size exceeds 25MB limit.");
        setErrorMessage("File size exceeds the 25MB limit.");
        setIsInvalidFile(true);
        resetFileInput();
        return;
      }
      const validExtensions = ["pdf", "xml", "txt", "csv", "json", "png", "jpg", "jpeg", "webm"];
      const fileExtension = file.name.split(".").pop().toLowerCase();
      const mimeType = file.type;
      if (
        validExtensions.includes(fileExtension) &&
        (mimeType.includes("application/pdf") ||
          mimeType.includes("text/xml") ||
          mimeType.includes("application/xml") ||
          mimeType.includes("text/plain") ||
          mimeType.includes("text/csv") ||
          mimeType.includes("application/json") ||
          mimeType.includes("image/png") ||
          mimeType.includes("image/jpg") ||
          mimeType.includes("image/jpeg") ||
          mimeType.includes("video/webm") ||
          mimeType.includes("video/x-matroska"))
      ) {
        const fileByteArray = await fileToUint8Array(file);
        const fileData = {
          source: fileByteArray,
          blobURL: URL.createObjectURL(file),
          fileName: file.name,
          fileType: file.type,
          documentProperties: {
            ...initialDocumentProperties,
            nomeDocumento: file.name,
            hash: toHex(calculateSHA256(fileByteArray)),
            algoritmo: "SHA-256",
            formatoDocumento: file.type,
          },
        };
        const updatedFiles = [...files];
        updatedFiles[index] = fileData;
        setFiles(updatedFiles);
      } else {
        console.error(`Invalid file format: ${fileExtension}.`);
        setErrorMessage(`Invalid file format: ${fileExtension}.`);
        setIsInvalidFile(true);
        resetFileInput();
      }
    }
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
  };

  const handleGenerate = async () => {
    setIsLoading(true);
    const newUploadID = genRndString(32);
    console.log("uploadID:", newUploadID);
    setUploadID(newUploadID);
    try {
      const statusRef = doc(db, "uploadStatuses", newUploadID);
      await setDoc(statusRef, {
        createdBy: user.uid,
        createdByEmail: user.email,
        totalCount: files.length,
        validCount: 0,
        invalidCount: 0,
        status: "processing",
      });
      const documentiArray = files.map((file) => file.documentProperties);
      const primaryDocument = primaryDocumentIndex !== null ? files[primaryDocumentIndex].documentProperties.idDocumento : null;
      const updatedUploadProperties = {
        ...uploadProperties,
        documenti: documentiArray,
        documentoPrincipale: primaryDocument,
      };
      const uploadedFiles = [];
      for (const file of files) {
        const { source, fileName, fileType } = file;
        try {
          const storagePath = `${newUploadID}/${fileName}`;
          const storageRef = ref(getStorage(), storagePath);
          const metadata = {
            contentType: fileType,
            customMetadata: {
              tagID: tag.id,
              isPublic: "false",
              requiresValidation: "true",
            },
          };
          console.log("Uploading file...");
          await uploadBytes(storageRef, source, metadata);
          console.log("File uploaded successfully!");
          const downloadURL = await getDownloadURL(storageRef);
          uploadedFiles.push({
            attachment: downloadURL,
            fileName,
            fileType,
          });
        } catch (uploadError) {
          console.error(`Error uploading file ${fileName}:`, uploadError);
          throw uploadError;
        }
      }
      const dataBody = {
        uploadID: newUploadID,
        uploadedFiles,
        uploadProperties: updatedUploadProperties,
      };
      const result = await genCDNFileRecord(user.uid, dataBody, tag.id);
      console.log(result);
      if (result.success) {
        handleOpenCertificationSuccessful();
        if (types && !types.includes("CDNFile")) {
          setTypes([...types, "CDNFile"]);
        }
      } else {
        handleOpenCertificationError();
      }
    } catch (error) {
      console.error("Error during multi-upload:", error);
      handleOpenCertificationError();
    } finally {
      setIsLoading(false);
      handleReset();
    }
  };

  const resetFileInput = () => {
    const fileInput = document.getElementById("new-upload-home-button");
    if (fileInput) fileInput.value = null;
  };

  const handleReset = () => {
    setFiles([]);
    setUploadProperties((prev) => ({
      ...initialUploadProperties,
      tipologiaDocumentale: prev.tipologiaDocumentale,
    }));
    setOpen(false);
    setConfirm(false);
    setPrimaryDocumentIndex(null);
  };

  // FileViewer integration
  const handleFileViewerClick = async (fileUrl) => {
    try {
      const response = await fetch(fileUrl);
      if (!response.ok) throw new Error(`Failed to fetch file. HTTP status: ${response.status}`);
      const blob = await response.blob();
      const currentFileType = blob.type;
      setFileType(currentFileType);
      if (currentFileType === "application/pdf") {
        setSelectedFile(fileUrl);
      } else if (currentFileType === "text/xml" || currentFileType === "application/xml") {
        const xmlText = await blob.text();
        setXmlContent(xmlText);
        setSelectedFile(fileUrl);
      } else if (currentFileType === "text/plain" || currentFileType === "text/csv") {
        const text = await blob.text();
        setTextContent(text);
        setSelectedFile(fileUrl);
      } else if (currentFileType === "application/json") {
        const jsonText = await blob.text();
        try {
          setTextContent(JSON.parse(jsonText));
        } catch (error) {
          throw new Error("Invalid JSON format");
        }
        setSelectedFile(fileUrl);
      } else if (currentFileType.startsWith("image/") || currentFileType.startsWith("video/")) {
        setSelectedFile(fileUrl);
      } else {
        throw new Error("Unsupported file type");
      }
    } catch (error) {
      console.error("Error fetching file:", error);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleCloseViewerDialog = () => {
    setSelectedFile(null);
    setXmlContent(null);
    setTextContent(null);
    setCurrentPage(1);
  };

  const handleClickOpen = (content) => {
    setDialogContent(content);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setDialogContent("");
  };

  const handleChangeDocumentProperties = (fileIndex, fieldKey, value) => {
    setFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles[fileIndex] = {
        ...updatedFiles[fileIndex],
        documentProperties: {
          ...updatedFiles[fileIndex].documentProperties,
          [fieldKey]: value,
        },
      };
      return updatedFiles;
    });
  };

  const handleChangeUploadProperties = (fieldKey, value) => {
    setUploadProperties({ ...uploadProperties, [fieldKey]: value });
  };

  const handleSetPrimaryDocument = (index) => {
    setPrimaryDocumentIndex(index);
  };

  const handleResetUploadReceipt = () => {
    setUploadID(null);
    setUploadReceipt(null);
    setReceiptDialogOpen(false);
  };

  const renderFileDialog = () => {
    if (!selectedFile) return null;
    return (
      <FileViewer
        open={Boolean(selectedFile)}
        onClose={handleCloseViewerDialog}
        fileType={fileType}
        fileUrl={selectedFile}
        xmlContent={xmlContent}
        textContent={textContent}
        currentPage={currentPage}
        numPages={numPages}
        onPageChange={handlePageChange}
      />
    );
  };

  if (isLoading) {
    return <LoadingDialog open={isLoading} />;
  }

  return (
    <>
      {isMobile ? (
        <SwipeableDrawer
          anchor="bottom"
          open={open}
          onClose={handleReset}
          onOpen={() => setOpen(true)}
          sx={{
            "& .MuiDrawer-paper": {
              width: "100%",
              height: "80%",
              borderTopLeftRadius: "4%",
              borderTopRightRadius: "4%",
            },
          }}
        >
          <Puller />
          <Box sx={{ p: 3 }}>
            <Typography variant="h6" fontWeight="bold" textAlign="center" mt={2} mb={3} color="text.primary">
              {t("upload")}
            </Typography>
            {files.map((file, index) => (
              <Box
                key={index}
                mb={4}
                p={3}
                sx={{
                  border: "1px solid #ddd",
                  borderRadius: "10px",
                  boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "#fafafa",
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h6" fontWeight="bold" sx={{ mb: 1 }} color="text.primary">
                      {`${t("document")} ${index + 1}`}
                    </Typography>
                    <Divider sx={{ mb: 2 }} />
                  </Grid>
                  <Grid container item spacing={2} alignItems="center">
                    <Grid item xs={12} sm={2}>
                      <Typography>{t("file")} *</Typography>
                    </Grid>
                    <Grid item xs={12} sm={10}>
                      <input
                        id={`file-upload-${index}`}
                        type="file"
                        accept="application/pdf, text/xml, application/xml, text/plain, text/csv, application/json, image/png, image/jpg, image/jpeg, video/webm, video/x-matroska"
                        style={{ display: "none" }}
                        onChange={(e) => handleFileChange(e.target, index)}
                      />
                      <label htmlFor={`file-upload-${index}`}>
                        <Button variant="outlined" component="span" startIcon={<FileUploadIcon />} sx={{ width: isMobile ? "100%" : "30%", ...getButtonStyle("open") }}>
                          {t("select_file")}
                        </Button>
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Typography>{t("file_name")}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={10}>
                      {file.fileName ? (
                        <Button variant="outlined" startIcon={<FileOpenIcon />} onClick={() => handleFileViewerClick(file.blobURL)} sx={getButtonStyle("open")}>
                          {file.fileName}
                        </Button>
                      ) : (
                        <Typography>{t("no_file_selected")}</Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <CDNFileForm
                      source={file.source}
                      documentProperties={file.documentProperties}
                      onChange={(fieldKey, value) => handleChangeDocumentProperties(index, fieldKey, value)}
                      handleClickOpen={handleClickOpen}
                      isPrimary={primaryDocumentIndex === index}
                      onSetPrimary={() => handleSetPrimaryDocument(index)}
                    />
                  </Grid>
                  <Grid container item xs={12} justifyContent="flex-end">
                    <Button variant="outlined" color="error" onClick={() => handleRemoveFile(index)} sx={{ mt: 2, ...getButtonStyle("close") }}>
                      {t("remove")}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            ))}
            <Button variant="outlined" onClick={handleAddNewFile} startIcon={<AddIcon />} sx={getButtonStyle("open")}>
              {t("document")}
            </Button>
            <Divider sx={{ my: 4 }} />
            <CDNFileFormUpload
              uploadProperties={uploadProperties}
              setUploadProperties={setUploadProperties}
              handleChangeUploadProperties={handleChangeUploadProperties}
              handleClickOpen={handleClickOpen}
            />
            <Grid container spacing={1} mt={3}>
              <Grid item xs={12}>
                <Button variant="outlined" disabled={isLoading || files.length === 0} onClick={() => setConfirm(true)} sx={getButtonStyle("open")}>
                  {t("upload")}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </SwipeableDrawer>
      ) : (
        <Dialog
          open={open}
          onClose={handleReset}
          maxWidth="md"
          fullWidth
          sx={{
            "& .MuiPaper-root": {
              borderRadius: 2,
              p: 2,
              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
            },
          }}
        >
          <DialogTitle>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h6" fontWeight="bold" color="text.primary">
                  {t("upload")}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={handleReset} color="error" edge="end">
                  <CloseOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>
            {files.map((file, index) => (
              <Box
                key={index}
                mb={4}
                p={3}
                sx={{
                  border: "1px solid #ddd",
                  borderRadius: "10px",
                  boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "#fafafa",
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h6" fontWeight="bold" sx={{ mb: 1 }} color="text.primary">
                      {`${t("document")} ${index + 1}`}
                    </Typography>
                    <Divider sx={{ mb: 2 }} />
                  </Grid>
                  <Grid container item spacing={2} alignItems="center">
                    <Grid item xs={12} sm={2}>
                      <Typography>{t("file")} *</Typography>
                    </Grid>
                    <Grid item xs={12} sm={10}>
                      <input
                        id={`file-upload-${index}`}
                        type="file"
                        accept="application/pdf, text/xml, application/xml, text/plain, text/csv, application/json, image/png, image/jpg, image/jpeg, video/webm, video/x-matroska"
                        style={{ display: "none" }}
                        onChange={(e) => handleFileChange(e.target, index)}
                      />
                      <label htmlFor={`file-upload-${index}`}>
                        <Button variant="outlined" component="span" startIcon={<FileUploadIcon />} sx={{ width: isMobile ? "100%" : "30%", ...getButtonStyle("open") }}>
                          {t("select_file")}
                        </Button>
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Typography>{t("file_name")}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={10}>
                      {file.fileName ? (
                        <Button variant="outlined" startIcon={<FileOpenIcon />} onClick={() => handleFileViewerClick(file.blobURL)} sx={getButtonStyle("open")}>
                          {file.fileName}
                        </Button>
                      ) : (
                        <Typography>{t("no_file_selected")}</Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <CDNFileForm
                      source={file.source}
                      documentProperties={file.documentProperties}
                      onChange={(fieldKey, value) => handleChangeDocumentProperties(index, fieldKey, value)}
                      handleClickOpen={handleClickOpen}
                      isPrimary={primaryDocumentIndex === index}
                      onSetPrimary={() => handleSetPrimaryDocument(index)}
                    />
                  </Grid>
                  <Grid container item xs={12} justifyContent="flex-end">
                    <Button variant="outlined" color="error" onClick={() => handleRemoveFile(index)} sx={{ mt: 2, ...getButtonStyle("close") }}>
                      {t("remove")}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            ))}
            <Button variant="outlined" onClick={handleAddNewFile} startIcon={<AddIcon />} sx={getButtonStyle("open")}>
              {t("document")}
            </Button>
            <Divider sx={{ my: 4 }} />
            <CDNFileFormUpload
              uploadProperties={uploadProperties}
              setUploadProperties={setUploadProperties}
              handleChangeUploadProperties={handleChangeUploadProperties}
              handleClickOpen={handleClickOpen}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="error" onClick={handleReset} sx={getButtonStyle("close")}>
              {t("cancel")}
            </Button>
            <Button variant="outlined" disabled={isLoading || files.length === 0} onClick={() => setConfirm(true)} sx={getButtonStyle("open")}>
              {t("upload")}
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {confirm && <NewCertConfirm confirm={confirm} setConfirm={setConfirm} handleGenerate={handleGenerate} />}
      {isInvalidFile && <InvalidFile open={isInvalidFile} setOpen={setIsInvalidFile} message={errorMessage} />}
      <AttributeDetailsDialog open={openDialog} handleClose={handleDialogClose} content={dialogContent} />
      {renderFileDialog()}
      {uploadReceipt && <CDNFileUploadReceipt uploadReceipt={uploadReceipt} receiptDialogOpen={receiptDialogOpen} handleResetUploadReceipt={handleResetUploadReceipt} />}
    </>
  );
};

export default CDNFileGenerator;
