// react
import React from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import { Grid, FormControlLabel, Checkbox } from "@mui/material";

// ui-components
import MetadataField from "./MetadataField";
import { metadataSchemaDocument, documentFields } from "./metadataSchema";

// A ---------------------------------------------------------------------- M

const CDNFileForm = ({ source, documentProperties, onChange, handleClickOpen, isPrimary, onSetPrimary }) => {
  const { t } = useTranslation();

  if (!source) return null;

  return (
    <Grid container spacing={2}>
      {documentFields.map((fieldKey) => (
        <MetadataField key={fieldKey} fieldKey={fieldKey} metadata={metadataSchemaDocument} documentProperties={documentProperties} onChange={onChange} handleClickOpen={handleClickOpen} />
      ))}
      <Grid item xs={12}>
        <FormControlLabel control={<Checkbox checked={isPrimary} onChange={onSetPrimary} />} label={t("set_as_primary_document")} />
      </Grid>
    </Grid>
  );
};

export default CDNFileForm;
