import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "context/AuthContext";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { ResetPasswordDialog } from "ui-components/ORFeedbacks";
import Copyright from "ui-components/Copyright";

// A ---------------------------------------------------------------------- M

const ResetPassword = () => {
  const { resetPassword } = UserAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [openReset, setOpenReset] = useState(false);

  const handleReset = async () => {
    await resetPassword(email);
    setOpenReset(true);
  };

  const handleCloseReset = () => {
    setOpenReset(false);
    navigate("/signin");
  };

  return (
    <>
      <Box
        sx={{
          backgroundImage: "url('/images/signin_bg.webp')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Container
          component="main"
          maxWidth="sm"
          sx={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              p: 4,
              boxShadow: 4,
              borderRadius: 4,
              bgcolor: "background.paper",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" fontWeight="bold" mb="5%">
              {t("reset_password")}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label={t("email")}
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  aria-label={t("email")}
                />
              </Grid>
              <Grid item xs={12}>
                <Button fullWidth variant="contained" color="primary" onClick={handleReset} aria-label={t("reset_password")} disabled={!email} sx={{ py: 2, borderRadius: 3 }}>
                  {t("reset_password")}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Container>
        <Box sx={{ mt: 2, pb: 2 }}>
          <Copyright />
        </Box>
      </Box>

      {openReset && <ResetPasswordDialog open={openReset} handleClose={handleCloseReset} />}
    </>
  );
};

export default ResetPassword;
