// firebase
import { doc, getDoc, collection, getDocs } from "firebase/firestore";
import { db } from "config/firebase";

// A ---------------------------------------------------------------------- M

const getCustomer = async (clientID) => {
  try {
    const clientDocRef = doc(db, "clientsdata", clientID);
    const clientDocSnapshot = await getDoc(clientDocRef);

    if (!clientDocSnapshot.exists()) {
      console.error(`No client found with ID ${clientID}`);
      return null;
    }

    const agreementsCollection = collection(db, "clientsdata", clientID, "agreements");
    const agreementsSnapshot = await getDocs(agreementsCollection);
    const agreements = agreementsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return {
      id: clientDocSnapshot.id,
      ...clientDocSnapshot.data(),
      agreements,
    };
  } catch (error) {
    console.error(`Error fetching client with ID ${clientID}:`, error);
    return null;
  }
};

export default getCustomer;
