// firebase
import { collection, setDoc, doc } from "firebase/firestore";
import { db } from "config/firebase";

// user-controller
import addSignature from "user-controller/addSignature";

// A ---------------------------------------------------------------------- M

const sendDataStreamCDNFile = async (userID, dataBody, timestamp, databoxID) => {
  try {
    // Destructure values from dataBody
    const { uploadID, uploadedFiles, uploadProperties } = dataBody;

    // Prepare record data
    const recordData = {
      creator_uuid: userID,
      data: uploadedFiles,
      tdr: databoxID,
      txid: uploadID,
      type: "CDNFile",
      version: 1,
      timestamp,
      uploadID,
      metadata: uploadProperties,
      AIC_ID: null,
      ...uploadProperties,
    };

    // Save record data to "recordsdata" collection
    const recordRef = doc(db, "recordsdata", uploadID);
    await setDoc(recordRef, recordData);
    console.log("Record data saved:", recordData);

    // Prepare to write signature data under the databox (tagsdata)
    const tagRef = doc(db, "tagsdata", databoxID);
    const recordsRef = collection(tagRef, "signatures");

    // Extract fields for the search index
    const { documenti, soggetti, extraMetadata } = uploadProperties;

    const documentNames = documenti.map((doc) => doc.nomeDocumento);
    const soggettiFullName = soggetti.filter((s) => s.tipoSoggetto === "PF").map((s) => `${(s.nome || "").trim()} ${(s.cognome || "").trim()}`.trim());
    const soggettiCodiceFiscale = soggetti.filter((s) => s.tipoSoggetto === "PF").map((s) => s.codiceFiscale || "");
    const soggettiOrganizzazione = soggetti.filter((s) => s.tipoSoggetto === "PG").map((s) => s.denominazioneOrganizzazione || "");

    // Process extra metadata into custom parameters
    const customParams = [];
    if (extraMetadata && typeof extraMetadata === "object") {
      Object.entries(extraMetadata).forEach(([key, value]) => {
        if (typeof value === "string" && value.trim()) {
          customParams.push(`${key.trim()}:${value.trim()}`);
        }
      });
    }

    const signatureData = {
      creator_uuid: userID,
      txid: uploadID,
      type: "CDNFile",
      public: false,
      timestamp,
      uploadID,
      metadata: uploadProperties,
      searchIndex: {
        documentNames,
        soggettiFullName,
        soggettiCodiceFiscale,
        soggettiOrganizzazione,
        customParams,
      },
      db_id: 1,
      AIC_ID: null,
      ...uploadProperties,
    };

    console.log("Signature data to save:", signatureData);

    // Save signature data to "tagsdata/<databoxID>/signatures"
    await setDoc(doc(recordsRef, uploadID), signatureData);

    // Call additional signature function (if needed)
    await addSignature(userID, uploadID, timestamp, "CDNFile", databoxID);

    // Update upload status
    const uploadStatusRef = doc(db, "uploadStatuses", uploadID);
    const uploadStatusData = { txid: uploadID };
    console.log("Upload status data:", uploadStatusData);
    await setDoc(uploadStatusRef, uploadStatusData, { merge: true });

    const result = { txid: uploadID, success: true };
    console.log(result);
    return result;
  } catch (error) {
    console.error("Error in sendDataStreamCDNFile:", error);
    return { success: false, error: error.message };
  }
};

export default sendDataStreamCDNFile;
