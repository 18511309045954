import React, { useState } from "react";
import { Handle, Position } from "reactflow";
import "reactflow/dist/style.css";
import { Typography, Card } from "@mui/material";
import { getEdgeColor, getHandleStyle, getGradient } from "./utils/styleGetters";

// A ---------------------------------------------------------------------- M

const KeyNode = ({ data }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    data && (
      <Card
        variant="outlined"
        sx={{
          width: "250px",
          p: 2,
          borderRadius: "10px",
          background: getGradient(data.status, isHovered),
          border: isHovered ? "3px solid rgba(255, 255, 255, 0.9)" : "1px solid rgba(255, 255, 255, 0.1)",
          boxShadow: isHovered ? "0 8px 20px rgba(0, 0, 0, 0.6)" : "0 4px 12px rgba(0, 0, 0, 0.3)",
          color: "#ffffff",
          textAlign: "center",
          position: "relative",
          cursor: "pointer",
          transform: isHovered ? "scale(1.1)" : "scale(1)",
          transition: "all 0.3s ease-in-out",
          filter: isHovered ? "drop-shadow(0px 0px 12px rgba(0, 173, 255, 0.9))" : "none",
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Typography variant="body1" fontWeight="bold" sx={{ color: "#ffffff", borderBottom: `2px solid ${getEdgeColor(data.status)}`, pb: 2 }}>
          {data.name}
        </Typography>

        <Typography variant="body2" sx={{ mt: 2 }}>
          Datapoints Count: {data.key_number_of_certifications}
        </Typography>

        <Handle
          type="source"
          position={Position.Right}
          id={`key-source-${data.keyId}`}
          style={{
            width: "10px",
            height: "10px",
            ...getHandleStyle(data.status),
          }}
        />
      </Card>
    )
  );
};

export default KeyNode;
