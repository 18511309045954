// firebase
import { doc, collection, getDocs } from "firebase/firestore";
import { db } from "config/firebase";

// A ---------------------------------------------------------------------- M

const fetchSeenTags = async (uuid) => {
  try {
    const userRef = doc(db, "userdata", uuid);
    const seenTagsRef = collection(userRef, "seentags");
    const snapshot = await getDocs(seenTagsRef);

    const seenTags = snapshot.docs.map((doc) => {
      const { nickname = "", notes = "", firstseen = "" } = doc.data();
      return {
        id: doc.id,
        nickname,
        notes,
        firstseen,
      };
    });

    return seenTags;
  } catch (error) {
    console.error(`Error in fetchSeenTags for user ${uuid}:`, error);
    return [];
  }
};

export default fetchSeenTags;
