// react
import React, { useState, useMemo } from "react";

// prop-types
import PropTypes from "prop-types";

// react-i18next
import { useTranslation } from "react-i18next";

// context
import { UserAuth } from "context/AuthContext";

// @mui
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableFooter,
  TablePagination,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  Tooltip,
} from "@mui/material";
import { KeyboardArrowLeftOutlined as KeyboardArrowLeftOutlinedIcon, KeyboardArrowRightOutlined as KeyboardArrowRightOutlinedIcon, Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";

// ui-components
import { MemberRemoveConfirm } from "ui-components/ORFeedbacks";

// group-controller
import { removeMember } from "group-controller/manageMember";

// A ---------------------------------------------------------------------- M

const buttonStyle = {
  py: 0.5,
  borderColor: "divider",
  color: "text.primary",
  textTransform: "none",
  boxShadow: "none",
  borderRadius: 10,
  "&:hover": {
    borderColor: "primary.main",
    backgroundColor: "action.hover",
  },
};

const MembersTableToolbar = ({ creator_uuid, setOpen }) => {
  const { user } = UserAuth();
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
      <Grid item xs={12} sm={6}>
        <Typography variant="h6" fontWeight="bold">
          {t("members")}
        </Typography>
      </Grid>
      {user.uid === creator_uuid && (
        <Grid item container xs={12} sm={6} spacing={1} justifyContent="flex-end" alignItems="center">
          <Grid item xs={12} sm={9} />
          <Grid item xs={12} sm={4}>
            <Button variant="outlined" fullWidth startIcon={<AddIcon />} sx={buttonStyle} onClick={() => setOpen(true)}>
              {t("add_member")}
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const MembersTableHead = () => {
  const { t } = useTranslation();

  const headCells = useMemo(
    () => [
      { id: "email", label: t("email") },
      { id: "role", label: t("role") },
      { id: "actions", label: t("actions") },
    ],
    [t]
  );

  return (
    <TableHead sx={{ backgroundColor: "grey.100" }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.id !== "actions" ? "left" : "right"}>
            <Typography variant="body2" fontWeight="bold">
              {headCell.label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const theme = useTheme();
  const isRtl = theme.direction === "rtl";

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={(e) => onPageChange(e, page - 1)} disabled={page === 0} aria-label="previous page">
        {isRtl ? <KeyboardArrowRightOutlinedIcon /> : <KeyboardArrowLeftOutlinedIcon />}
      </IconButton>
      <IconButton onClick={(e) => onPageChange(e, page + 1)} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        {isRtl ? <KeyboardArrowLeftOutlinedIcon /> : <KeyboardArrowRightOutlinedIcon />}
      </IconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const MembersTable = ({ group, members, setOpen, handleSuccessfulRemoved, handleErrorRemoved }) => {
  const { user } = UserAuth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedMember, setSelectedMember] = useState();
  const [selectedGroup, setSelectedGroup] = useState();
  const [showRemove, setShowRemove] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleShowRemove = (memberUID, groupID) => {
    setShowRemove(true);
    setSelectedMember(memberUID);
    setSelectedGroup(groupID);
  };

  const handleRemoveMember = async () => {
    try {
      await removeMember(selectedMember, selectedGroup);
      setShowRemove(false);
      handleSuccessfulRemoved();
    } catch (error) {
      handleErrorRemoved();
    }
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - members.length) : 0;

  return (
    <>
      <Box>
        <MembersTableToolbar creator_uuid={group.creator_uuid} setOpen={setOpen} />
        <TableContainer>
          <Table>
            <MembersTableHead />
            <TableBody>
              {(rowsPerPage > 0 ? members.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : members).map((member) => {
                return (
                  <TableRow key={member.uid}>
                    <TableCell align="left">{member.email || "N/A"}</TableCell>
                    <TableCell align="left" sx={{ color: "gray" }}>
                      {member.uid === group.creator_uuid ? t("admin") : t("member")}
                    </TableCell>
                    <TableCell align="right" size="small">
                      {user.uid === group.creator_uuid && member.uid !== group.creator_uuid ? (
                        <Tooltip title={t("remove")} placement="top">
                          <IconButton sx={{ height: "100%", color: "red" }} onClick={() => handleShowRemove(member.uid, group.id)} size="small">
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      ) : null}
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={3} />
                </TableRow>
              )}
            </TableBody>
            {!isMobile && (
              <TableFooter sx={{ backgroundColor: "grey.100" }}>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 20]}
                    colSpan={3}
                    count={members.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={t("rows_per_page")}
                    labelDisplayedRows={({ from, to, count }) => t("displayed_rows", { from, to, count: count !== -1 ? count : `more than ${to}` })}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            )}
          </Table>
          {isMobile && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <TablePaginationActions count={members.length} page={page} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} />
            </Box>
          )}
        </TableContainer>
      </Box>
      <MemberRemoveConfirm showRemove={showRemove} setShowRemove={setShowRemove} handleRemoveMember={handleRemoveMember} />
    </>
  );
};

MembersTable.propTypes = {
  group: PropTypes.object.isRequired,
  members: PropTypes.array.isRequired,
  setOpen: PropTypes.func.isRequired,
  handleSuccessfulRemoved: PropTypes.func.isRequired,
  handleErrorRemoved: PropTypes.func.isRequired,
};

export default MembersTable;
