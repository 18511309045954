// react
import React, { useEffect, useState } from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// context
import { UserAuth } from "context/AuthContext";

// @mui
import { useMediaQuery, Box, SwipeableDrawer, Dialog, DialogTitle, DialogContent, DialogActions, Grid, Button, IconButton, Typography, TextField } from "@mui/material";
import { CloseOutlined as CloseOutlinedIcon } from "@mui/icons-material";

// ui-components
import { Puller } from "ui-components/Puller";
import { LoadingDialog } from "ui-components/LoadingComponent";

// datakey-controller
import createDataKey from "datakey-controller/createDataKey";

// A ---------------------------------------------------------------------- M

const getButtonStyle = (action) => ({
  py: 0.5,
  borderColor: "divider",
  color: action === "close" ? "error.main" : "primary.main",
  textTransform: "none",
  boxShadow: "none",
  borderRadius: 10,
  "&:hover": {
    borderColor: action === "close" ? "error.dark" : "primary.dark",
    backgroundColor: "action.hover",
  },
});

const NewDataKey = ({ dataKey, open, setOpen, handleClose, setOpenDataKeySnackbarSuccessful, setOpenDataKeySnackbarError }) => {
  const { user } = UserAuth();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Reset form on dialog close or browser popstate
  useEffect(() => {
    const handlePopstate = () => {
      if (open) {
        handleReset();
      }
    };
    window.addEventListener("popstate", handlePopstate);
    return () => window.removeEventListener("popstate", handlePopstate);
  }, [open]);

  const handleCreate = async () => {
    setIsLoading(true);
    try {
      const createdDoc = await createDataKey(dataKey, name, user.uid);
      if (createdDoc) {
        setOpenDataKeySnackbarSuccessful(true);
      } else {
        setOpenDataKeySnackbarError(true);
      }
    } catch (error) {
      setOpenDataKeySnackbarError(true);
    } finally {
      setIsLoading(false);
      handleReset();
    }
  };

  const handleReset = () => {
    setName("");
    handleClose();
  };

  const DataKeyForm = (
    <Grid container>
      <Grid item xs={12}>
        <TextField
          margin="dense"
          id="value"
          label={t("value")}
          type="text"
          fullWidth
          variant="outlined"
          value={dataKey}
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField margin="dense" id="name" label={t("name")} type="text" fullWidth variant="outlined" value={name} onChange={(e) => setName(e.target.value)} />
      </Grid>
    </Grid>
  );

  if (isLoading) return <LoadingDialog open={isLoading} />;

  return isMobile ? (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={handleReset}
      onOpen={() => setOpen(true)}
      sx={{
        "& .MuiDrawer-paper": {
          width: "100%",
          height: "45%",
          borderTopLeftRadius: "4%",
          borderTopRightRadius: "4%",
        },
      }}
      data-testid="datakey-create-modal"
    >
      <Puller />
      <Box sx={{ p: 3 }}>
        <Grid container justifyContent="center" mb={3}>
          <Typography variant="h6" fontWeight="bold">
            {t("new_datakey")}
          </Typography>
        </Grid>
        {DataKeyForm}
        <Grid container mt={3}>
          <Grid item xs={12}>
            <Button fullWidth variant="outlined" onClick={handleCreate} disabled={!dataKey || !name} sx={getButtonStyle("close")}>
              {t("create")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </SwipeableDrawer>
  ) : (
    <Dialog
      open={open}
      onClose={handleReset}
      maxWidth="sm"
      fullWidth
      sx={{
        "& .MuiPaper-root": {
          borderRadius: 2,
          p: 2,
          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
        },
      }}
      data-testid="datakey-create-modal"
    >
      <DialogTitle>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h6" fontWeight="bold">
              {t("new_datakey")}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleReset} edge="end" color="error">
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>{DataKeyForm}</DialogContent>
      <DialogActions>
        <Button variant="outlined" sx={getButtonStyle("close")} onClick={handleReset}>
          {t("cancel")}
        </Button>
        <Button variant="outlined" onClick={handleCreate} disabled={!dataKey || !name} sx={getButtonStyle("open")}>
          {t("create")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewDataKey;
