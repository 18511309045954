import React from "react";
import { useTranslation } from "react-i18next";
import ReactFlow, { Handle, Position } from "reactflow";
import "reactflow/dist/style.css";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography, Box, Card, useTheme } from "@mui/material";
import formatDate from "utils/formatDate";
import { getStatusChip, getEdgeLabel, getEdgeColor, getLabelStyle, getHandleStyle } from "./utils/styleGetters";

// A ---------------------------------------------------------------------- M

const MiniFlowVisualization = ({ edgeModalData }) => {
  const theme = useTheme();

  const miniNodes = [
    { id: "key", type: "keyMini", data: edgeModalData, position: { x: -20, y: 40 } },
    { id: "databox", type: "databoxMini", data: edgeModalData, position: { x: 380, y: 40 } },
  ];

  const miniEdges = [
    {
      id: "e1-2",
      source: "key",
      target: "databox",
      sourceHandle: "a",
      targetHandle: "b",
      animated: ["online", "recovered"].includes(edgeModalData.status),
      type: "step",
      style: { stroke: getEdgeColor(edgeModalData.status), strokeWidth: 3 },
      label: getEdgeLabel(edgeModalData.status),
      labelStyle: getLabelStyle(edgeModalData.status),
    },
  ];

  const commonCardStyle = {
    p: 2,
    borderRadius: "10px",
    backgroundColor: theme.palette.primary.dark,
    border: `1px solid ${theme.palette.primary.light}`,
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.3)",
    color: "#fff",
    textAlign: "center",
    position: "relative",
    transition: "transform 0.2s ease-in-out",
    "&:hover": {
      transform: "scale(1.05)",
    },
  };

  const KeyMiniNode = ({ data }) =>
    data && (
      <Card variant="outlined" sx={commonCardStyle}>
        <Typography variant="body2" fontWeight="bold" sx={{ color: "#fff", p: 1 }}>
          {data.name}
        </Typography>
        <Handle type="source" position={Position.Right} id="a" style={getHandleStyle(data.status)} />
      </Card>
    );

  const DataboxMiniNode = ({ data }) =>
    data && (
      <Card variant="outlined" sx={commonCardStyle}>
        <Typography variant="body2" fontWeight="bold" sx={{ color: "#fff", p: 1 }}>
          {data.databoxName}
        </Typography>
        <Handle type="target" position={Position.Left} id="b" style={getHandleStyle(data.status)} />
      </Card>
    );

  const miniNodeTypes = { keyMini: KeyMiniNode, databoxMini: DataboxMiniNode };

  return (
    <Box sx={{ width: "100%", height: 160, my: 2 }}>
      <ReactFlow nodes={miniNodes} edges={miniEdges} nodeTypes={miniNodeTypes} fitView nodesDraggable={false} nodesConnectable={false} zoomOnScroll={false} panOnScroll={false} />
    </Box>
  );
};

const EdgeDetailsModal = ({ edgeModalData, handleCloseEdgeModal }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Dialog
      open={Boolean(edgeModalData)}
      onClose={handleCloseEdgeModal}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "15px",
          padding: 1,
          boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.5)",
          backgroundColor: theme.palette.background.paper,
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          fontSize: "1.6rem",
          color: theme.palette.text.primary,
        }}
      >
        {t("flow_details")}
      </DialogTitle>
      <DialogContent dividers>
        {edgeModalData && <MiniFlowVisualization edgeModalData={edgeModalData} />}
        <DialogContentText sx={{ textAlign: "center", my: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 1 }}>
            <Typography variant="body1" fontWeight="bold" mr={1}>
              {t("status")}:
            </Typography>
            {getStatusChip(edgeModalData?.status)}
          </Box>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>{t("last_datapoint")}:</strong> {edgeModalData?.lastEmittedPacket ? formatDate(edgeModalData.lastEmittedPacket) : "N/A"}
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>{t("last_update")}:</strong> {edgeModalData?.lastUpdated ? formatDate(edgeModalData.lastUpdated) : "N/A"}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="error" onClick={handleCloseEdgeModal}>
          {t("close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EdgeDetailsModal;
