// react
import React, { useState, useEffect } from "react";

// prop-types
import PropTypes from "prop-types";

// react-i18next
import { useTranslation } from "react-i18next";

// context
import { UserAuth } from "context/AuthContext";

// @mui
import {
  useMediaQuery,
  Box,
  SwipeableDrawer,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  IconButton,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import { CloseOutlined as CloseOutlinedIcon, ExpandMore as ExpandMoreIcon } from "@mui/icons-material";

// ui-components
import { Puller } from "ui-components/Puller";
import { metadataSchemaUpload, metadataSections } from "./metadataSchema";
import { LoadingDialog } from "ui-components/LoadingComponent";

// storage
import downloadFile from "storage/downloadFile";

// event-logger
import logEvent from "event-logger/logEvent";

// utils
import convertTimestamp from "utils/convertTimestamp";
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

const getButtonStyle = (action) => ({
  py: 0.5,
  borderColor: "divider",
  color: action === "close" ? "error.main" : "primary.main",
  textTransform: "none",
  boxShadow: "none",
  borderRadius: 10,
  "&:hover": {
    borderColor: action === "close" ? "error.dark" : "primary.dark",
    backgroundColor: "action.hover",
  },
});

const CDNFileDetailsDialog = ({ open, onClose, record, handleFileClick }) => {
  const { user } = UserAuth();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [expandedDocuments, setExpandedDocuments] = useState(false);
  const [expandedSoggetti, setExpandedSoggetti] = useState(false);
  const [expandedExtraMetadata, setExpandedExtraMetadata] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handlePopstate = () => {
      if (open) onClose();
    };

    window.addEventListener("popstate", handlePopstate);
    return () => window.removeEventListener("popstate", handlePopstate);
  }, [open, onClose]);

  useEffect(() => {
    if (record) {
      setLoading(false);
    }
  }, [record]);

  const toggleDocumentsExpansion = () => setExpandedDocuments((prev) => !prev);
  const toggleSoggettiExpansion = () => setExpandedSoggetti((prev) => !prev);
  const toggleExtraMetadataExpansion = () => setExpandedExtraMetadata((prev) => !prev);

  const handleDocumentOpen = async (doc) => {
    const fileInfo = record.data?.find((file) => file.fileName === doc.nomeDocumento);
    if (fileInfo?.attachment && fileInfo?.fileName) {
      handleFileClick(fileInfo.attachment, fileInfo.fileName);
    } else {
      console.error("Cannot open the selected document.");
      await logEvent(record.tdr, "event_archive_open_file_error", unixTimestampInSeconds(), `${fileInfo?.fileName} open error.`, null, user.uid, null, "error");
    }
  };

  const handleDownloadFile = async (doc) => {
    const fileInfo = record.data?.find((file) => file.fileName === doc.nomeDocumento);
    const timestamp = unixTimestampInSeconds();
    if (fileInfo?.attachment && fileInfo?.fileName) {
      try {
        await downloadFile(fileInfo.attachment, fileInfo.fileName);
        setSnackbarMessage(t("success_downloading_file"));
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        await logEvent(record.tdr, "event_archive_download_file_success", timestamp, `${fileInfo.fileName} downloaded successfully.`, null, user.uid, null, "success");
      } catch (error) {
        console.error("Cannot download the selected document.");
        setSnackbarMessage(t("error_downloading_file"));
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        await logEvent(record.tdr, "event_archive_download_file_error", timestamp, `${fileInfo.fileName} download failed.`, null, user.uid, null, "error");
      }
    } else {
      console.error("Cannot download the selected document.");
      setSnackbarMessage(t("error_downloading_file"));
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      await logEvent(record.tdr, "event_archive_download_file_error", timestamp, "File download failed.", null, user.uid, null, "error");
    }
  };

  const DetailsForm = (
    <Grid container spacing={2}>
      {record.documenti && record.documenti.length > 0 && (
        <Grid item xs={12}>
          <Accordion
            expanded={expandedDocuments}
            onChange={toggleDocumentsExpansion}
            sx={{
              borderLeft: "4px solid #FFA726",
              boxShadow: "none",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="documents-content" id="documents-header">
              <Typography variant="body1" fontWeight="bold" color="text.primary">
                {t("uploaded_documents")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {record.documenti.map((documento, idx) => (
                  <Grid container item spacing={1} key={idx}>
                    <Grid item xs={12}>
                      <Typography variant="body2" fontWeight="bold">
                        {`${t("document")} ${idx + 1}: ${documento.nomeDocumento}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
                        <strong>{t("ID")}:</strong> {documento.idDocumento}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
                        <strong>{t("Formato")}:</strong> {documento.formatoDocumento}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
                        <strong>{t("Hash")}:</strong> {documento.hash}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
                        <strong>{t("Algoritmo")}:</strong> {documento.algoritmo}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
                        <strong>{t("Numero Allegati")}:</strong> {documento.numeroAllegati}
                      </Typography>
                    </Grid>
                    {documento.descrizioneAllegato && (
                      <Grid item xs={12}>
                        <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
                          <strong>{t("Descrizione Allegato")}:</strong> {documento.descrizioneAllegato}
                        </Typography>
                      </Grid>
                    )}
                    {documento.idDocumentoPrimario && (
                      <Grid item xs={12}>
                        <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
                          <strong>{t("ID Documento Primario")}:</strong> {documento.idDocumentoPrimario}
                        </Typography>
                      </Grid>
                    )}
                    <Grid item xs={12} sx={{ mt: 1 }}>
                      <Button variant="outlined" size="small" sx={getButtonStyle("open")} onClick={() => handleDocumentOpen(documento)}>
                        {t("open")}
                      </Button>
                      <Button variant="outlined" size="small" sx={{ ml: 1, ...getButtonStyle("open") }} onClick={() => handleDownloadFile(documento)}>
                        {t("download")}
                      </Button>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      )}

      <Grid item xs={12}>
        <Divider sx={{ my: 2 }} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography variant="body2" fontWeight="bold" color="text.primary">
          {t("upload_id")}
        </Typography>
        <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
          {record.id || "N/A"}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography variant="body2" fontWeight="bold" color="text.primary">
          {t("upload_datetime")}
        </Typography>
        <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
          {record.timestamp ? convertTimestamp(record.timestamp) : "N/A"}
        </Typography>
      </Grid>

      {metadataSections.map((section, sectionIndex) => (
        <React.Fragment key={sectionIndex}>
          {section.title === "Soggetti" && record.soggetti && Object.keys(record.soggetti).length > 0 ? (
            <Grid item xs={12}>
              <Accordion expanded={expandedSoggetti} onChange={toggleSoggettiExpansion} sx={{ boxShadow: "none" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="soggetti-content" id="soggetti-header">
                  <Typography variant="body2" fontWeight="bold" color="text.primary">
                    {t("Soggetti")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    {record.soggetti.map((soggetto, idx) => (
                      <Grid container item spacing={1} key={idx}>
                        <Grid item xs={12}>
                          <Typography variant="body2" fontWeight="bold" color="text.primary">
                            {`${t("Soggetto")} ${idx + 1}`}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        {Object.keys(soggetto).map((key, keyIndex) =>
                          soggetto[key] ? (
                            <Grid item xs={12} sm={6} key={keyIndex}>
                              <Typography variant="body2" fontWeight="bold">
                                {metadataSchemaUpload[key]?.label || key}
                              </Typography>
                              <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
                                {soggetto[key]}
                              </Typography>
                            </Grid>
                          ) : null
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          ) : (
            section.fields.map((fieldKey, idx) =>
              record[fieldKey] ? (
                <Grid item xs={12} sm={6} key={idx}>
                  <Typography variant="body2" fontWeight="bold" color="text.primary">
                    {metadataSchemaUpload[fieldKey]?.label || fieldKey}
                  </Typography>
                  <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
                    {fieldKey === "dataInvioInConservazione" ? convertTimestamp(record[fieldKey]) : record[fieldKey]}
                  </Typography>
                </Grid>
              ) : null
            )
          )}
        </React.Fragment>
      ))}

      {record.extraMetadata && Object.keys(record.extraMetadata).length > 0 && (
        <Grid item xs={12}>
          <Accordion expanded={expandedExtraMetadata} onChange={toggleExtraMetadataExpansion} sx={{ boxShadow: "none" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="extra-metadata-content" id="extra-metadata-header">
              <Typography variant="body2" fontWeight="bold" color="text.primary">
                {t("extra_metadata")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {Object.entries(record.extraMetadata).map(([key, value], idx) => (
                  <Grid item xs={12} sm={6} key={idx}>
                    <Typography variant="body2" fontWeight="bold">
                      {key}
                    </Typography>
                    <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
                      {value}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      )}
    </Grid>
  );

  if (loading) {
    return <LoadingDialog open={loading} />;
  }

  return (
    <>
      {isMobile ? (
        <SwipeableDrawer
          anchor="bottom"
          open={open}
          onClose={onClose}
          onOpen={() => {}}
          sx={{
            "& .MuiDrawer-paper": {
              width: "100%",
              height: "90%",
              borderTopLeftRadius: "4%",
              borderTopRightRadius: "4%",
            },
          }}
        >
          <Puller />
          <Box sx={{ p: 3 }}>
            <Grid container justifyContent="center" sx={{ mb: 2 }}>
              <Typography variant="h6" fontWeight="bold" color="text.primary">
                {t("details")}
              </Typography>
            </Grid>
            {DetailsForm}
          </Box>
        </SwipeableDrawer>
      ) : (
        <Dialog
          open={open}
          onClose={onClose}
          maxWidth="md"
          fullWidth
          sx={{
            "& .MuiPaper-root": {
              borderRadius: 2,
              p: 2,
              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
            },
          }}
          data-testid="upload-details-modal"
        >
          <DialogTitle>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h6" fontWeight="bold" color="text.primary">
                  {t("details")}
                </Typography>
              </Grid>
              <Grid item>
                <Tooltip title={t("close")} placement="top">
                  <IconButton onClick={onClose} color="error" edge="end" size="small">
                    <CloseOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>{DetailsForm}</DialogContent>
          <DialogActions>
            <Button variant="outlined" sx={getButtonStyle("close")} onClick={onClose}>
              {t("close")}
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} variant="filled" sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

CDNFileDetailsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  record: PropTypes.object.isRequired,
  handleFileClick: PropTypes.func.isRequired,
};

export default CDNFileDetailsDialog;
