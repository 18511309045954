import React, { useState, useEffect, useCallback, Suspense } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Grid, CircularProgress } from "@mui/material";
import DataCard from "./DataCard";
import TableContent from "./TableContent";
import FlowEventsTable from "./FlowEventsTable";
import SkeletonTable from "./SkeletonTable";
import getFlowEvents from "databox-controller/getFlowEvents";

// A ---------------------------------------------------------------------- M

const SourcesDashboard = ({ tag }) => {
  const { t } = useTranslation();

  const [sourcesData, setSourcesData] = useState([]);
  const [flowEventsData, setFlowEventsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const { sources, events } = await getFlowEvents(tag);
      setSourcesData(sources || []);
      setFlowEventsData(events || []);
    } catch (error) {
      console.error("Error fetching sources events:", error);
      setError("Failed to load data. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }, [tag]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error" textAlign="center">
        {error}
      </Typography>
    );
  }

  return (
    <Grid item container spacing={2}>
      <Grid item xs={12} md={6}>
        <DataCard title={t("sources_status")}>
          <Suspense fallback={<SkeletonTable rows={4} columns={4} />}>
            <TableContent sourcesData={sourcesData} />
          </Suspense>
        </DataCard>
      </Grid>

      <Grid item xs={12} md={6}>
        <DataCard title={t("flow_events")}>
          <Suspense fallback={<SkeletonTable rows={4} columns={3} />}>
            <FlowEventsTable flowEventsData={flowEventsData} />
          </Suspense>
        </DataCard>
      </Grid>
    </Grid>
  );
};

export default SourcesDashboard;
