// firebase
import { collection, setDoc, doc, getDoc, deleteDoc } from "firebase/firestore";
import { db } from "config/firebase";

// event-logger
import logEvent from "event-logger/logEvent";

// utils
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

export const addViewGroup = async (tagID, groupID, userID) => {
  const timestamp = unixTimestampInSeconds();

  try {
    const tagRef = doc(db, "tagsdata", tagID);
    const viewgroupsRef = collection(tagRef, "viewgroups");
    const newViewGroupRef = doc(viewgroupsRef, groupID);
    const newViewGroupSnapshot = await getDoc(newViewGroupRef);

    if (newViewGroupSnapshot.exists()) {
      return { code: -1 };
    }

    await setDoc(newViewGroupRef, {
      group_id: groupID,
      added_on: timestamp,
    });

    await logEvent(tagID, "event_add_viewgroup_success", timestamp, `${groupID} added to ${tagID} successfully.`, null, userID, null, "success");
    return { code: 0 };
  } catch (error) {
    console.error(`Error in addViewGroup for tagID ${tagID} and groupID ${groupID}:`, error);
    await logEvent(tagID, "event_add_viewgroup_error", timestamp, `${groupID} added to ${tagID} failed.`, null, userID, null, "error");
    return { code: -2 };
  }
};

export const addViewGroupReadOnly = async (tagID, groupID, userID) => {
  const timestamp = unixTimestampInSeconds();

  try {
    const tagRef = doc(db, "tagsdata", tagID);
    const viewgroupsRef = collection(tagRef, "viewgroups_readonly");
    const newViewGroupRef = doc(viewgroupsRef, groupID);
    const newViewGroupSnapshot = await getDoc(newViewGroupRef);

    if (newViewGroupSnapshot.exists()) {
      return { code: -1 };
    }

    await setDoc(newViewGroupRef, {
      group_id: groupID,
      added_on: timestamp,
    });

    await logEvent(tagID, "event_add_viewgroup_success", timestamp, `${groupID} added to ${tagID} read-only successfully.`, null, userID, null, "success");
    return { code: 0 };
  } catch (error) {
    console.error(`Error in addViewGroupReadOnly for tagID ${tagID} and groupID ${groupID}:`, error);
    await logEvent(tagID, "event_add_viewgroup_error", timestamp, `${groupID} added to ${tagID} read-only failed.`, null, userID, null, "error");
    return { code: -2 };
  }
};

export const removeViewGroup = async (tagID, groupID, userID) => {
  const timestamp = unixTimestampInSeconds();

  try {
    const viewgroupRef = doc(db, "tagsdata", tagID, "viewgroups", groupID);
    await deleteDoc(viewgroupRef);
    await logEvent(tagID, "event_remove_viewgroup_success", timestamp, `${groupID} removed from ${tagID} successfully.`, null, userID, null, "success");
    return { code: 0 };
  } catch (error) {
    console.error(`Error in removeViewGroup for tagID ${tagID} and groupID ${groupID}:`, error.message);
    await logEvent(tagID, "event_remove_viewgroup_error", timestamp, `${groupID} removed from ${tagID} failed.`, null, userID, null, "error");
    return { code: 1 };
  }
};

export const removeViewGroupReadOnly = async (tagID, groupID, userID) => {
  const timestamp = unixTimestampInSeconds();

  try {
    const viewgroupRef = doc(db, "tagsdata", tagID, "viewgroups_readonly", groupID);
    await logEvent(tagID, "event_remove_viewgroup_success", timestamp, `${groupID} removed from ${tagID} successfully.`, null, userID, null, "success");
    await deleteDoc(viewgroupRef);
    return { code: 0 };
  } catch (error) {
    console.error(`Error in removeViewGroupReadOnly for tagID ${tagID} and groupID ${groupID}:`, error.message);
    await logEvent(tagID, "event_remove_viewgroup_error", timestamp, `${groupID} removed from ${tagID} failed.`, null, userID, null, "error");
    return { code: 1 };
  }
};
