// firebase
import { doc, collection, getDocs } from "firebase/firestore";
import { db } from "config/firebase";

// user-controller
import getUserContacts from "user-controller/getUserContacts";

// databox-controller
import getCertificationsByCreator from "databox-controller/getCertificationsByCreator";

// A ---------------------------------------------------------------------- M

const getAuthorizedUsersData = async (databoxID) => {
  try {
    const databoxRef = doc(db, "tagpermissions", databoxID);
    const databoxReadonlyRef = doc(db, "tagpermissions_readonly", databoxID);
    const authorizedUsersRef = collection(databoxRef, "authorized_users");
    const readonlyUsersRef = collection(databoxReadonlyRef, "authorized_users");

    const [snapshot, readonlySnapshot] = await Promise.all([getDocs(authorizedUsersRef), getDocs(readonlyUsersRef)]);

    let totalNumberOfCertifications = 0;
    const usersArray = [];

    const processUser = async (authUser, source) => {
      const existingUser = usersArray.find((user) => user.user_id === authUser.id);

      if (existingUser) {
        if (!existingUser.source.includes(source)) {
          existingUser.source.push(source);
        }
      } else {
        const userContacts = await getUserContacts(authUser.id);

        const userCertifications = await getCertificationsByCreator(databoxID, authUser.id);

        totalNumberOfCertifications += userCertifications.number_of_certifications || 0;

        usersArray.push({
          user_id: authUser.id,
          user_email: userContacts.email || "N/A",
          user_public_key: userContacts.public_key || "N/A",
          user_company: userContacts.company || "N/A",
          user_certifications: userCertifications.certifications || [],
          user_number_of_certifications: userCertifications.number_of_certifications || 0,
          source: [source],
        });
      }
    };

    for (const doc of snapshot.docs) {
      await processUser(doc, "read/write");
    }

    for (const doc of readonlySnapshot.docs) {
      await processUser(doc, "read-only");
    }

    return {
      authorizedUsersArray: usersArray,
      totalNumberOfCertifications,
    };
  } catch (error) {
    console.error("Error in getAuthorizedUsersData:", error);
    return {
      authorizedUsersArray: [],
      totalNumberOfCertifications: 0,
    };
  }
};

export default getAuthorizedUsersData;
