// firebase
import { collectionGroup, query, where, getDocs, getDoc } from "firebase/firestore";
import { db } from "config/firebase";

// databox-controller
import getTagInfo from "./getTagInfo";

// A ---------------------------------------------------------------------- M

const getTagsByViewGroup = async (groupID) => {
  try {
    const groupsQuery = query(collectionGroup(db, "viewgroups"), where("group_id", "==", groupID));
    const snapshot = await getDocs(groupsQuery);

    const tagPromises = snapshot.docs.map(async (group) => {
      const parentDocRef = group.ref.parent.parent;

      if (!parentDocRef) {
        return null;
      }

      const tag = await getDoc(parentDocRef);
      const tagID = tag.id;

      const tagInfo = await getTagInfo(tagID);
      return {
        id: tagID,
        name: tagInfo?.name || "",
      };
    });

    const tags = await Promise.all(tagPromises);

    return tags.filter((tag) => tag !== null);
  } catch (error) {
    console.error(`Error in getTagsByViewGroup for groupID ${groupID}:`, error);
    return [];
  }
};

export default getTagsByViewGroup;
