import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Tooltip from "@mui/material/Tooltip";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { generateMnemonic, mnemonicToEntropy } from "SafeTwin/crypto/bip39/bip39";
import { toHex, fromHex, toBase64, generateSeedEd25519KeyPair } from "SafeTwin/crypto/cryptolibsodium";

// A ---------------------------------------------------------------------- M

const KeyPairGenerator = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [privateKey, setPrivateKey] = useState("");
  const [publicKey, setPublicKey] = useState("");

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  const handleGenerate = () => {
    // Generate mnemonic
    const mnemonic = generateMnemonic();

    // Derive entropy from mnemonic
    const seed = mnemonicToEntropy(mnemonic);

    // Generate keypair
    const keypair = generateSeedEd25519KeyPair(fromHex(seed));

    // Private key
    const privateKeyByteArray = keypair.sk;
    const privateKeyBase64 = toBase64(privateKeyByteArray);

    // Public key
    const publicKeyByteArray = keypair.pk;
    const publicKeyHex = toHex(publicKeyByteArray);

    setPrivateKey(privateKeyBase64);
    setPublicKey(publicKeyHex);
  };

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          mt: isMobile ? 10 : 20,
          p: isMobile ? 2 : 4,
          boxShadow: 4,
          borderRadius: 2,
          bgcolor: "background.paper",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant={isMobile ? "h6" : "h5"} fontWeight="bold" mb="5%" align="center">
              {t("generate_keypair")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              {t("gen_key_1")}
              <br />
              1. <b>{t("private_key")}</b>: {t("gen_key_2")}
              <br />
              2. <b>{t("public_key")}</b>: {t("gen_key_3")}
            </Typography>
          </Grid>
          <Grid item container spacing={2} xs={12}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="Private Key"
                label={t("private_key")}
                name="Private Key"
                value={privateKey}
                InputProps={{
                  endAdornment: (
                    <Tooltip title={t("copy")} placement="top">
                      <IconButton color="primary" onClick={() => handleCopyToClipboard(privateKey)}>
                        <ContentCopyIcon />
                      </IconButton>
                    </Tooltip>
                  ),
                }}
                aria-label={t("private_key")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="Public Key"
                label={t("public_key")}
                name="Public Key"
                value={publicKey}
                InputProps={{
                  endAdornment: (
                    <Tooltip title={t("copy")} placement="top">
                      <IconButton color="primary" onClick={() => handleCopyToClipboard(publicKey)}>
                        <ContentCopyIcon />
                      </IconButton>
                    </Tooltip>
                  ),
                }}
                aria-label={t("public_key")}
              />
            </Grid>
            <Grid container item xs={12} justifyContent="flex-end">
              <Button variant="contained" onClick={handleGenerate} aria-label={t("generate")}>
                {t("generate")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default KeyPairGenerator;
