// react
import React, { useState, useEffect } from "react";

// react-router-dom
import { useNavigate, useLocation, Link } from "react-router-dom";

// context
import { UserAuth } from "context/AuthContext";

// react-pwa-install
import { useReactPWAInstall } from "react-pwa-install";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import { Container, Box, Grid, TextField, Button, Typography, Link as MuiLink } from "@mui/material";

// ui-components
import { LoginSnackbar, InstallBanner } from "ui-components/ORFeedbacks";
import Copyright from "ui-components/Copyright";

// firebase
import { requestPermission } from "config/firebase";

// SafeTwin
import { seedFlow } from "SafeTwin/3S/seedFlow";

// user-controller
import addFCMToken from "user-controller/addFCMToken";

// A ---------------------------------------------------------------------- M

const Signin = () => {
  const { user, setLanguage, setSeedFlow, signIn } = UserAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { pwaInstall, supported, isInstalled } = useReactPWAInstall();

  const [showInstallBanner, setShowInstallBanner] = useState(supported() && !isInstalled());
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const tag = location.state?.tag;

  useEffect(() => {
    setLanguage("it");
  }, []);

  useEffect(() => {
    if (user) navigate("/");
  }, []);

  const handleClick = () => {
    pwaInstall({
      title: t("install_pwa_title"),
      description: t("install_pwa_description"),
    })
      .then(() => console.log(t("installation_success")))
      .catch(() => console.log(t("installation_error")));
  };

  const handleSignIn = async () => {
    try {
      const userCredential = await signIn(email, password);
      const token = await requestPermission();
      if (token) {
        await addFCMToken(userCredential.user.uid, token);
      }
      const isSeeded = await seedFlow(userCredential.user, password);
      setSeedFlow(isSeeded);
      setOpenSuccess(true);
      console.log("Signin successful.");
    } catch (error) {
      console.error("Signin error:", error.message);
      setPassword("");
      setOpenError(true);
    }
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
    navigate(tag ? `/${tag}` : "/");
  };

  const handleCloseError = () => {
    setOpenError(false);
  };

  return (
    <>
      <Box
        sx={{
          backgroundImage: "url('/images/signin_bg.webp')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Container
          component="main"
          maxWidth="sm"
          sx={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              p: 3,
              borderRadius: 2,
              bgcolor: "rgba(255, 255, 255, 0.8)",
              backdropFilter: "blur(5px)",
              boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
              width: "100%",
            }}
          >
            <Typography variant="h5" fontWeight="bold" mb={3} align="center">
              {t("sign_in")}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField variant="outlined" fullWidth required id="email" label={t("email")} name="email" value={email} onChange={(e) => setEmail(e.target.value)} aria-label={t("email")} />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  required
                  id="password"
                  label={t("password")}
                  name="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  aria-label={t("password")}
                />
                <MuiLink component={Link} to="/reset-password" style={{ textDecoration: "none", marginTop: "0.5rem", display: "block", textAlign: "right" }}>
                  <Typography variant="body2" color="primary">
                    {t("reset_password")}
                  </Typography>
                </MuiLink>
              </Grid>
              <Grid item xs={12}>
                <Button fullWidth variant="contained" color="primary" onClick={handleSignIn} aria-label={t("sign_in")} sx={{ py: 1.5, borderRadius: 2 }}>
                  {t("sign_in")}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Container>
        <Box sx={{ mt: 2, pb: 2 }}>
          <Copyright />
        </Box>
      </Box>
      {showInstallBanner && <InstallBanner showInstallBanner={showInstallBanner} handleCloseInstallBanner={() => setShowInstallBanner(false)} handleClick={handleClick} />}
      {openSuccess && <LoginSnackbar open={openSuccess} handleClose={handleCloseSuccess} severity="success" message="login_successful" autoHideDuration={1500} />}
      {openError && <LoginSnackbar open={openError} handleClose={handleCloseError} severity="error" message="email_password_wrong" autoHideDuration={3000} />}
    </>
  );
};

export default Signin;
