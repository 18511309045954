// react
import React, { useState } from "react";

// prop-types
import PropTypes from "prop-types";

// context
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import { Box, Grid, Table, TableCell, TableHead, TableRow, TableContainer, TableFooter, TablePagination, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { KeyboardArrowLeftOutlined as KeyboardArrowLeftOutlinedIcon, KeyboardArrowRightOutlined as KeyboardArrowRightOutlinedIcon } from "@mui/icons-material";

// ui-components
import CertificationsTableStandard from "./CertificationsTableStandard";
import CertificationsTableConservSostL1 from "./CertificationsTableConservSostL1";

// A ---------------------------------------------------------------------- M

const CertificationsTableToolbar = () => {
  const { conservSostL1 } = UserAuth();
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
      <Grid item xs={12} sm={6}>
        <Typography variant="h6" fontWeight="bold">
          {conservSostL1 ? t("latest_documents") : t("latest_certifications")}
        </Typography>
      </Grid>
    </Grid>
  );
};

const CDNFileTableHead = () => {
  const { t } = useTranslation();

  return (
    <TableHead sx={{ backgroundColor: "grey.100" }}>
      <TableRow>
        <TableCell>
          <Typography variant="body2" fontWeight="bold">
            {t("file_name")}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" fontWeight="bold">
            {t("upload_datetime")}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" fontWeight="bold">
            {t("archive")}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" fontWeight="bold">
            {t("document_type")}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Typography variant="body2" fontWeight="bold">
            {t("actions")}
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

const CertificationsTableHead = () => {
  const { t } = useTranslation();

  return (
    <TableHead sx={{ backgroundColor: "grey.100" }}>
      <TableRow>
        <TableCell>
          <Typography variant="body2" fontWeight="bold">
            {t("value")}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" fontWeight="bold">
            {t("generation_datetime")}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" fontWeight="bold">
            {t("databox")}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" fontWeight="bold">
            {t("type")}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" fontWeight="bold">
            {t("visibility")}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Typography variant="body2" fontWeight="bold">
            {t("actions")}
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const theme = useTheme();
  const isRtl = theme.direction === "rtl";

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={(e) => onPageChange(e, page - 1)} disabled={page === 0} aria-label="previous page">
        {isRtl ? <KeyboardArrowRightOutlinedIcon /> : <KeyboardArrowLeftOutlinedIcon />}
      </IconButton>
      <IconButton onClick={(e) => onPageChange(e, page + 1)} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        {isRtl ? <KeyboardArrowLeftOutlinedIcon /> : <KeyboardArrowRightOutlinedIcon />}
      </IconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const CertificationsTable = ({ signatures, handleView, checkVerification, checkPreservationProof }) => {
  const { conservSostL1 } = UserAuth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - signatures.length) : 0;

  return (
    <Box>
      <CertificationsTableToolbar />
      <TableContainer>
        <Table>
          {conservSostL1 ? <CDNFileTableHead /> : <CertificationsTableHead />}
          {conservSostL1 ? (
            <CertificationsTableConservSostL1
              signatures={signatures}
              handleView={handleView}
              checkPreservationProof={checkPreservationProof}
              emptyRows={emptyRows}
              rowsPerPage={rowsPerPage}
              page={page}
            />
          ) : (
            <CertificationsTableStandard signatures={signatures} handleView={handleView} checkVerification={checkVerification} emptyRows={emptyRows} rowsPerPage={rowsPerPage} page={page} />
          )}
          {!isMobile && (
            <TableFooter sx={{ backgroundColor: "grey.100" }}>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20]}
                  colSpan={6}
                  count={signatures.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={t("rows_per_page")}
                  labelDisplayedRows={({ from, to, count }) => t("displayed_rows", { from, to, count: count !== -1 ? count : `more than ${to}` })}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};

CertificationsTable.propTypes = {
  signatures: PropTypes.array.isRequired,
  handleView: PropTypes.func.isRequired,
  checkVerification: PropTypes.func.isRequired,
  checkPreservationProof: PropTypes.func.isRequired,
};

export default CertificationsTable;
