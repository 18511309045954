// firebase
import { collection, query, where, getDocs, doc } from "firebase/firestore";
import { db } from "config/firebase";

import fetchSignature from "./fetchSignature";

// A ---------------------------------------------------------------------- M

const fetchRecordsByType = async (tagID, type, isTagGroupMember, startDate, endDate) => {
  try {
    const tagRef = doc(db, "tagsdata", tagID);
    const signaturesRef = collection(tagRef, "signatures");

    let q;

    if (startDate || endDate) {
      const startDateUnixSecondsString = (startDate.getTime() / 1000).toString();
      const endDateUnixSecondsString = (endDate.getTime() / 1000).toString();

      if (isTagGroupMember) {
        q = query(signaturesRef, where("type", "==", type), where("timestamp", ">=", startDateUnixSecondsString), where("timestamp", "<=", endDateUnixSecondsString));
      } else {
        q = query(signaturesRef, where("type", "==", type), where("timestamp", ">=", startDateUnixSecondsString), where("timestamp", "<=", endDateUnixSecondsString), where("public", "==", true));
      }
    } else {
      if (isTagGroupMember) {
        q = query(signaturesRef, where("type", "==", type));
      } else {
        q = query(signaturesRef, where("type", "==", type), where("public", "==", true));
      }
    }

    const snapshot = await getDocs(q);

    const recordPromises = snapshot.docs.map(async (sig) => {
      const signatureData = sig.data();
      const txid = signatureData.txid;
      const signature = await fetchSignature(txid);
      return signature;
    });

    const records = await Promise.all(recordPromises);
    return records.filter((record) => record !== undefined);
  } catch (error) {
    console.error("Error in fetchRecordsByType:", error);
    return null;
  }
};

export default fetchRecordsByType;
