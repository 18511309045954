// react
import React, { useState, useEffect } from "react";

// contexts
import { UserAuth } from "context/AuthContext";

// react-pdf
import { Document, Page, pdfjs } from "react-pdf";

// @mui
import { Box, IconButton, useMediaQuery, Dialog, Typography } from "@mui/material";
import { KeyboardArrowLeftOutlined as KeyboardArrowLeftOutlinedIcon, KeyboardArrowRightOutlined as KeyboardArrowRightOutlinedIcon, CloseOutlined as CloseOutlinedIcon } from "@mui/icons-material";

// components
import AggregatedTable from "./AggregatedTable";
import DataDialog from "ui-components/CertificationManagement/DataDialog";
import DatapointIntegrityInspector from "ui-components/CertificationManagement/DatapointIntegrityInspector";
import { LoadingDialog } from "ui-components/LoadingComponent";

// verification
import verifySignature from "verification/verifySignature";

// A ---------------------------------------------------------------------- M

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const AggregatedViewer = ({ tag, isTagGroupMember }) => {
  const { user } = UserAuth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [record, setRecord] = useState();
  const [verification, setVerification] = useState();
  const [openVerify, setOpenVerify] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [verificationLoading, setVerificationLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const handlePopState = () => {
      if (openVerify) {
        setOpenVerify(false);
      } else if (openView) {
        setOpenView(false);
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [openVerify, openView]);

  const handleOpenView = () => {
    setOpenView(true);
    window.history.pushState(null, "");
  };

  const handleOpenVerify = () => {
    setOpenVerify(true);
    window.history.pushState(null, "");
  };

  const checkVerification = async (record) => {
    try {
      setVerificationLoading(true);

      let verification;

      if (user) {
        verification = await verifySignature(user.uid, record, record.type);
      } else {
        verification = await verifySignature(false, record, record.type);
      }

      setVerification(verification);

      handleOpenVerify();
    } catch (error) {
      console.error("Error in checkVerification:", error.message);
    } finally {
      setVerificationLoading(false);
    }
  };

  const handleView = (record) => {
    handleOpenView();
    setRecord(record);
  };

  const handleFileClick = async (fileUrl) => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const fileType = blob.type;

      if (fileType === "application/pdf") {
        setSelectedFile(fileUrl);
      } else if (fileType.startsWith("image/")) {
        setSelectedImage(fileUrl);
      }
    } catch (error) {
      console.error("Error fetching and parsing file:", error.message);
    }
  };

  const handleCloseDocDialog = () => {
    setSelectedFile(null);
    setCurrentPage(1);
  };

  const handleDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleCloseImageDialog = () => {
    setSelectedImage(null);
  };

  return (
    <>
      {verificationLoading && <LoadingDialog open={verificationLoading} />}

      {tag && <AggregatedTable tag={tag} isTagGroupMember={isTagGroupMember} checkVerification={checkVerification} handleView={handleView} handleFileClick={handleFileClick} />}

      {record && <DataDialog data={{ ...record, databoxName: tag.name || "N/A" }} open={openView} onClose={() => setOpenView(false)} handleFileClick={handleFileClick} />}

      {verification && <DatapointIntegrityInspector verification={verification} open={openVerify} setOpen={setOpenVerify} />}

      {selectedFile && (
        <Dialog open={Boolean(selectedFile)} onClose={handleCloseDocDialog}>
          <IconButton color="error" sx={{ justifyContent: "flex-end" }} onClick={handleCloseDocDialog}>
            <CloseOutlinedIcon />
          </IconButton>
          <Document file={selectedFile} onLoadSuccess={handleDocumentLoadSuccess}>
            <Page pageNumber={currentPage} width={isMobile ? 300 : 600} renderAnnotationLayer={false} renderTextLayer={false} />
          </Document>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
              <KeyboardArrowLeftOutlinedIcon />
            </IconButton>
            <Typography sx={{ mx: 2 }}>
              {currentPage} / {numPages}
            </Typography>
            <IconButton onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === numPages}>
              <KeyboardArrowRightOutlinedIcon />
            </IconButton>
          </Box>
        </Dialog>
      )}

      {selectedImage && (
        <Dialog open={Boolean(selectedImage)} onClose={handleCloseImageDialog} fullWidth maxWidth="md">
          <IconButton color="error" sx={{ position: "absolute", top: "0%", right: "0%", zIndex: 1 }} onClick={handleCloseImageDialog}>
            <CloseOutlinedIcon fontSize="small" />
          </IconButton>
          <img src={selectedImage} alt="img" />
        </Dialog>
      )}
    </>
  );
};

export default AggregatedViewer;
