// react
import React from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";

// A ---------------------------------------------------------------------- M

const buttonStyle = {
  py: 0.5,
  borderColor: "divider",
  color: "error.main",
  textTransform: "none",
  boxShadow: "none",
  borderRadius: 10,
  "&:hover": {
    borderColor: "error.dark",
    backgroundColor: "action.hover",
  },
};

const AttributeDetailsDialog = ({ open, handleClose, content }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      sx={{
        "& .MuiPaper-root": {
          borderRadius: 2,
          p: 2,
          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <DialogContent>
        <DialogContentText variant="body2">{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" size="small" onClick={handleClose} sx={buttonStyle}>
          {t("close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AttributeDetailsDialog;
