import React, { useState } from "react";
import { UserAuth } from "context/AuthContext";
import { Handle, Position } from "reactflow";
import "reactflow/dist/style.css";
import { Typography, Table, TableBody, TableCell, TableRow, TableHead, Card, Box } from "@mui/material";

// A ---------------------------------------------------------------------- M

const UsersNode = ({ data }) => {
  const { conservSostL1 } = UserAuth();
  const [isHovered, setIsHovered] = useState(false);

  const getPermissions = (source) => {
    if (source.includes("read/write")) {
      return "read/write";
    } else if (source.includes("read-only")) {
      return "read";
    } else {
      return "N/A";
    }
  };

  return (
    <Card
      variant="outlined"
      sx={{
        width: "800px",
        p: 2,
        borderRadius: "8px",
        background: isHovered ? "linear-gradient(135deg, #8d8d8d 0%, #a0a0a0 100%)" : "linear-gradient(135deg, #757575 0%, #9e9e9e 100%)",
        border: "1px solid rgba(255, 255, 255, 0.1)",
        boxShadow: isHovered ? "0 8px 20px rgba(0, 0, 0, 0.6)" : "0 4px 12px rgba(0, 0, 0, 0.3)",
        color: "#ffffff",
        textAlign: "center",
        position: "relative",
        transform: isHovered ? "scale(1.1)" : "scale(1)",
        transition: "all 0.3s ease-in-out",
        filter: isHovered ? "drop-shadow(0px 0px 12px rgba(0, 173, 255, 0.9))" : "none",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Typography variant="h6" align="center" fontWeight="bold" sx={{ color: "#ffffff", borderBottom: "2px solid white", pb: 2 }}>
        Users
      </Typography>

      <Typography variant="body2" sx={{ mt: 2 }}>
        {!conservSostL1 ? "Records" : "Documents"} Count: {data.totalNumberOfCertifications}
      </Typography>

      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle2" fontWeight="bold" sx={{ color: "white", textTransform: "uppercase", letterSpacing: "0.5px" }}>
                User
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="subtitle2" fontWeight="bold" sx={{ color: "white", textTransform: "uppercase", letterSpacing: "0.5px" }}>
                Permissions
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="subtitle2" fontWeight="bold" sx={{ color: "white", textTransform: "uppercase", letterSpacing: "0.5px" }}>
                Count
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.authorizedUsersArray.map((user, index) => (
            <TableRow
              key={index}
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(76, 175, 80, 0.1)",
                  cursor: "pointer",
                },
                backgroundColor: user.user_number_of_certifications > 0 ? "#263238" : "#37474f",
              }}
            >
              <TableCell>
                <Typography variant="body2" sx={{ fontWeight: 500, color: "#eceff1" }}>
                  {user.user_email}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2" sx={{ fontWeight: 500, color: "#eceff1" }}>
                  {getPermissions(user.source)}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2" sx={{ fontWeight: 500, color: "#eceff1" }}>
                  {user.user_number_of_certifications}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Handle
        type="source"
        position={Position.Right}
        id="users-source"
        style={{
          width: "10px",
          height: "10px",
          top: "70%",
          background: "linear-gradient(90deg, #757575, #9e9e9e)",
          border: "2px solid #ffffff",
          boxShadow: "0 0 4px #757575",
        }}
      />
    </Card>
  );
};

export default UsersNode;
