// firebase
import { collection, setDoc, doc } from "firebase/firestore";
import { db } from "config/firebase";

// user-controller
import addSignature from "user-controller/addSignature";

// A ---------------------------------------------------------------------- M

const sendDataStreamImage = async (isPublic, userID, dataBody, dataCertification, timestamp, databoxID) => {
  try {
    const txid = dataCertification.txid;
    const recordRef = doc(db, "recordsdata", txid);

    const recordData = {
      txid: txid,
      type: "img",
      ...(databoxID && { tdr: databoxID }),
      creator_uuid: userID,
      data: {
        fileName: dataBody.fileName,
        fileType: dataBody.fileType,
        attachment: dataBody.downloadURL,
        caption: dataBody.caption,
        timestamp: timestamp,
      },
      fileName: dataBody.fileName,
      fileType: dataBody.fileType,
      description: dataBody.caption,
      timestamp: timestamp,
      public: isPublic,
      version: 1,
    };

    console.log(recordData);

    await setDoc(recordRef, recordData);

    if (databoxID) {
      const tagRef = doc(db, "tagsdata", databoxID);
      const recordsRef = collection(tagRef, "signatures");

      const signatureData = {
        txid: txid,
        type: "img",
        creator_uuid: userID,
        fileName: dataBody.fileName,
        fileType: dataBody.fileType,
        timestamp: timestamp,
        description: dataBody.caption,
        public: isPublic,
        db_id: 1,
      };

      console.log(signatureData);

      await setDoc(doc(recordsRef, txid), signatureData);
    }

    databoxID ? await addSignature(userID, txid, timestamp, "img", databoxID) : await addSignature(userID, txid, timestamp, "databoxID");

    const result = {
      txid: txid,
      success: true,
    };

    console.log(result);
    return result;
  } catch (error) {
    console.error("Error in sendDataStreamImage:", error.message);

    const result = {
      success: false,
      error: error.message,
    };

    return result;
  }
};

export default sendDataStreamImage;
