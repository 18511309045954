import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import formatDate from "utils/formatDate";
import { getStatusChip } from "./utils/styleGetters";

// A ---------------------------------------------------------------------- M

const TableContent = ({ sourcesData }) => {
  const { t } = useTranslation();

  const headers = useMemo(() => [t("name"), t("status"), t("last_datapoint"), t("last_update")], [t]);

  return (
    <Table>
      <TableHead>
        <TableRow>
          {headers.map((header) => (
            <TableCell key={header} sx={{ fontWeight: "bold" }}>
              {header}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {sourcesData.length > 0 ? (
          sourcesData.map((source) => (
            <TableRow key={source.keyId} hover>
              <TableCell>{source.name}</TableCell>
              <TableCell>{getStatusChip(source.status)}</TableCell>
              <TableCell>{source.lastEmittedPacket ? formatDate(source.lastEmittedPacket) : "N/A"}</TableCell>
              <TableCell>{source.lastUpdated ? formatDate(source.lastUpdated) : "N/A"}</TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={4} align="center">
              {t("no_source_found")}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default TableContent;
