const downloadFile = (downloadURL, fileName) => {
  return new Promise((resolve, reject) => {
    try {
      const xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      
      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          const blob = xhr.response;
          const a = document.createElement("a");
          a.style.display = "none";
          const blobUrl = window.URL.createObjectURL(blob);
          a.href = blobUrl;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(blobUrl);
          resolve();
        } else {
          reject(new Error("Failed to download file. HTTP status: " + xhr.status));
        }
      };

      xhr.onerror = () => reject(new Error("Network error while downloading file."));
      xhr.open("GET", downloadURL);
      xhr.send();
    } catch (error) {
      reject(error);
    }
  });
};

export default downloadFile;
