// firebase
import { doc, collection, query, where, getDocs, getCountFromServer } from "firebase/firestore";
import { db } from "config/firebase";

// A ---------------------------------------------------------------------- M

const getCertificationsByCreator = async (databoxID, uid) => {
  try {
    const databoxRef = doc(db, "tagsdata", databoxID);
    const signaturesRef = collection(databoxRef, "signatures");
    const q = query(signaturesRef, where("creator_uuid", "==", uid));
    const querySnapshot = await getDocs(q);

    const certifications = querySnapshot.docs.map((certDoc) => ({
      id: certDoc.id,
      ...certDoc.data(),
    }));

    const countSnap = await getCountFromServer(q);
    const numberOfCertifications = countSnap.data().count;

    return {
      certifications,
      number_of_certifications: numberOfCertifications,
    };
  } catch (error) {
    console.error(`Error in getCertificationsByCreator for databoxID ${databoxID} and uid ${uid}:`, error);
    return {
      certifications: [],
      number_of_certifications: 0,
    };
  }
};

export default getCertificationsByCreator;
