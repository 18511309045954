// react
import React from "react";

// react-dom/client
import ReactDOM from "react-dom/client";

// react-router-dom
import { BrowserRouter as Router } from "react-router-dom";

// react-pwa-install
import ReactPWAInstallProvider from "react-pwa-install";

import "./i18n";

// react-i18next
import { I18nextProvider } from "react-i18next";

// i18n
import i18n from "./i18n";

// context
import { AuthContextProvider } from "context/AuthContext";
import App from "App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

// reportWebVitals
import reportWebVitals from "reportWebVitals";

// css
import "./index.css";

// A ---------------------------------------------------------------------- M

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ReactPWAInstallProvider>
    <Router>
      <I18nextProvider i18n={i18n}>
        <AuthContextProvider>
          <App />
        </AuthContextProvider>
      </I18nextProvider>
    </Router>
  </ReactPWAInstallProvider>
);

if (process.env.NODE_ENV === "production") {
  serviceWorkerRegistration.register();
}

reportWebVitals();
