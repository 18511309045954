// firebase
import { doc, collection, getDocs, query, orderBy, limit } from "firebase/firestore";
import { db } from "config/firebase";

// record-controller
import fetchSignature from "../record-controller/fetchSignature";

// databox-controller
import getTagInfo from "databox-controller/getTagInfo";

// A ---------------------------------------------------------------------- M

const fetchLastSignatures = async (userID) => {
  try {
    const userRef = doc(db, "userdata", userID);
    const mysigsRef = collection(userRef, "mysigs");

    const latestSigsQuery = query(mysigsRef, orderBy("timestamp", "desc"), limit(20));
    const snapshot = await getDocs(latestSigsQuery);

    const signaturePromises = snapshot.docs.map(async (sig) => {
      try {
        const signature = await fetchSignature(sig.id);

        // Check if the signature type is "doc", "img", "info", "CDNFile"
        if (["doc", "img", "info", "CDNFile"].includes(signature?.type)) {
          if (!signature.tdr) {
            return signature;
          }

          const tagInfo = await getTagInfo(signature.tdr);

          return {
            ...signature,
            name: tagInfo?.name || "",
          };
        }
        return null;
      } catch (error) {
        console.error(`Error fetching signature or tag info for sig ID ${sig.id}:`, error);
        return null;
      }
    });

    const signatureResults = await Promise.all(signaturePromises);
    const signatures = signatureResults.filter(Boolean);
    return signatures;
  } catch (error) {
    console.error(`Error in fetchLastSignatures for userID ${userID}:`, error);
    return [];
  }
};

export default fetchLastSignatures;
