// react
import React, { createContext, useContext, useEffect, useState } from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// firebase
import { auth } from "config/firebase";
import { signInWithEmailAndPassword, signOut, onAuthStateChanged, reauthenticateWithCredential, EmailAuthProvider, sendPasswordResetEmail } from "firebase/auth";

// components
import { LoadingComponent } from "ui-components/LoadingComponent";

// user-controller
import getUserContacts from "user-controller/getUserContacts";
import getUserData from "user-controller/getUserData";

// customer-controller
import getCustomer from "customer-controller/getCustomer";

// A ---------------------------------------------------------------------- M

const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const { i18n } = useTranslation();

  const [user, setUser] = useState();
  const [language, setLanguage] = useState("it");
  const [userCompany, setUserCompany] = useState();
  const [clientData, setClientData] = useState();
  const [pubKey, setPubKey] = useState();
  const [APIKey, setAPIKey] = useState();
  const [canCreateDatabox, setCanCreateDatabox] = useState(false);
  const [canCreateGroup, setCanCreateGroup] = useState(false);
  const [canSign, setCanSign] = useState(false);
  const [isReseller, setIsReseller] = useState(false);
  const [isInternal, setIsInternal] = useState(false);
  const [canAddUser, setCanAddUser] = useState(false);
  const [conservSostL1, setConservSostL1] = useState(false);
  const [seedflow, setSeedFlow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const signIn = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const checkPassword = async (password) => {
    try {
      const credential = EmailAuthProvider.credential(user.email, password);
      await reauthenticateWithCredential(user, credential);
      return true;
    } catch (error) {
      console.error("Error in checkPassword:", error);
      return false;
    }
  };

  const logout = () => {
    try {
      window.localStorage.removeItem("seed");
      window.localStorage.removeItem("nonce");
      setPubKey();
      setAPIKey();
      setClientData();
      setCanCreateDatabox(false);
      setCanCreateGroup(false);
      setCanSign(false);
      setIsReseller(false);
      setIsInternal(false);
      setCanAddUser(false);
      setSeedFlow(false);
      setIsLoading(false);
      return signOut(auth);
    } catch (error) {
      console.error("Error in logout:", error);
    }
  };

  const resetPassword = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      console.error("Error in resetPassword:", error);
    }
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
  };

  useEffect(() => {
    changeLanguage(language);

    if (window.localStorage.getItem("seed")) {
      setSeedFlow(true);
    }

    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);

      if (currentUser?.uid) {
        try {
          // User Contacts
          const userContacts = await getUserContacts(currentUser.uid);

          if (userContacts) {
            setUserCompany(userContacts.company);
            setPubKey(userContacts.public_key);

            // ClientData from ClientID
            if (userContacts.clientID) {
              const clientData = await getCustomer(userContacts.clientID);
              setClientData(clientData);
            }
          }

          // User Data
          const userData = await getUserData(currentUser.uid);

          if (userData) {
            setCanCreateDatabox(userData.canCreateDatabox);
            setCanCreateGroup(userData.canCreateGroup);
            setCanSign(userData.canSign);
            setIsReseller(userData.isReseller);
            setIsInternal(userData.isInternal);
            setCanAddUser(userData.canAddUser);
            setConservSostL1(userData.conservSostL1);
            setAPIKey(userData.APIKey);
          }
        } catch (error) {
          console.error("Error in fetching user information:", error);
        }
      }

      setIsLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <UserContext.Provider
      value={{
        user,
        language,
        setLanguage: changeLanguage,
        pubKey,
        setPubKey,
        userCompany,
        clientData,
        canCreateDatabox,
        canCreateGroup,
        canSign,
        isReseller,
        isInternal,
        canAddUser,
        conservSostL1,
        setConservSostL1,
        APIKey,
        seedflow,
        setSeedFlow,
        signIn,
        logout,
        checkPassword,
        resetPassword,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};
