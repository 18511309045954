// react
import React, { useEffect, useState } from "react";

// react-router-dom
import { useParams } from "react-router-dom";

// context
import { UserAuth } from "context/AuthContext";

// @mui
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";

// ui-components
import TagToolbar from "ui-components/DataboxManagement/TagToolbar";
import RequestsTable from "ui-components/CertificationManagement/RequestsTable";
import DocGenerator from "ui-components/DataTypeManagement/Doc/DocGenerator";
import ImageGenerator from "ui-components/DataTypeManagement/Image/ImageGenerator";
import InfoGenerator from "ui-components/DataTypeManagement/Info/InfoGenerator";
import { CertificationSuccessful, CertificationError } from "ui-components/ORFeedbacks";

// firebase
import { doc, collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "config/firebase";

// databox-controller
import { addAlarmUser, removeAlarmUser, hasUserAlarmOn } from "databox-controller/manageAlarm";
import isUserFollowingDatabox from "databox-controller/isUserFollowingDatabox";
import { addFollower, removeFollower } from "databox-controller/manageFollower";
import getTag from "databox-controller/getTag";
import getTagOwner from "databox-controller/getTagOwner";
import getTagTypes from "databox-controller/getTagTypes";

// user-controller
import getSeenTag from "user-controller/getSeenTag";
import getUserContacts from "user-controller/getUserContacts";

// record-controller
import fetchRequests from "record-controller/fetchRequests";

// A ---------------------------------------------------------------------- M

const TagRequests = () => {
  const { user, conservSostL1 } = UserAuth();
  const { tag } = useParams();

  const [seentag, setSeenTag] = useState({});
  const [types, setTypes] = useState([]);
  const [requests, setRequests] = useState([]);
  const [request, setRequest] = useState();
  const [openCertificationSuccessful, setOpenCertificationSuccessful] = useState(false);
  const [openCertificationError, setOpenCertificationError] = useState(false);
  const [openDocGen, setOpenDocGen] = useState(false);
  const [openImageGen, setOpenImageGen] = useState(false);
  const [openInfoGen, setOpenInfoGen] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const [alarmOn, setAlarmOn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchSeenTag = async () => {
      const seentag = await getSeenTag(user.uid, tag);
      const tagData = await getTag(tag);
      const tagName = tagData.name;
      const tagOwner = await getTagOwner(tag);
      const tagOwnerData = await getUserContacts(tagOwner);
      const tagOwnerEmail = tagOwnerData.email;

      const updatedSeentag = {
        ...seentag,
        name: tagName,
        tagOwner: tagOwner,
        tagOwnerEmail: tagOwnerEmail,
      };

      if (conservSostL1 && tagData.tipologiaDocumentale) {
        updatedSeentag.tipologiaDocumentale = tagData.tipologiaDocumentale;
      }

      setSeenTag(updatedSeentag);
    };

    const getTypes = async () => {
      const types = await getTagTypes(tag);
      const allTypes = [...new Set([...types, ...types])];
      setTypes(allTypes);
    };

    const fetchTagRequests = async () => {
      const tagOwner = await getTagOwner(tag);
      if (user.uid === tagOwner) {
        const requests = await fetchRequests(tag);
        requests.sort((a, b) => b.timestamp - a.timestamp);
        setRequests(requests);
      }
    };

    const fetchData = async () => {
      await fetchSeenTag();
      await getTypes();
      await fetchTagRequests();
      setIsLoading(false);
    };

    fetchData();

    const currentTimestampInSeconds = Math.floor(Date.now() / 1000);
    const tagsdataDocRef = doc(db, "tagsdata", tag);
    const signatureRequestsCollectionRef = collection(tagsdataDocRef, "signature_requests");
    const newAddedQuerySignatureRequests = query(signatureRequestsCollectionRef, where("timestamp", ">=", currentTimestampInSeconds.toString()));

    const newUnsubscribeAddedSignatureRequests = onSnapshot(newAddedQuerySignatureRequests, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          fetchTagRequests();
        }
      });
    });

    const newUnsubscribeUpdatedSignatureRequests = onSnapshot(signatureRequestsCollectionRef, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "modified") {
          fetchTagRequests();
        }
      });
    });

    return () => {
      newUnsubscribeAddedSignatureRequests();
      newUnsubscribeUpdatedSignatureRequests();
    };
  }, [tag, user]);

  useEffect(() => {
    const checkIsFollowing = async () => {
      const following = await isUserFollowingDatabox(user.uid, tag);
      setIsFollowing(following);
    };

    const fetchUserAlarmState = async () => {
      const alarmOn = await hasUserAlarmOn(user.uid, tag);
      setAlarmOn(alarmOn);
    };

    checkIsFollowing();
    fetchUserAlarmState();
  }, [user, tag]);

  const handleFollowToggle = async () => {
    if (isFollowing) {
      await removeFollower(user.uid, tag);
    } else {
      await addFollower(user.uid, tag);
    }
    setIsFollowing(!isFollowing);
  };

  const handleAlarmToggle = async () => {
    if (alarmOn) {
      await removeAlarmUser(user.uid, tag);
    } else {
      await addAlarmUser(user.uid, tag);
    }
    setAlarmOn((prev) => !prev);
  };

  const handleCertify = (request) => {
    setRequest(request);

    if (request.type === "info") {
      openInfoGenDrawer();
    } else if (request.type === "img") {
      openImageGenDrawer();
    } else if (request.type === "doc") {
      openDocGenDrawer();
    }
  };

  const openDocGenDrawer = () => {
    setOpenDocGen(true);
    window.history.pushState(null, "");
  };

  const openImageGenDrawer = () => {
    setOpenImageGen(true);
    window.history.pushState(null, "");
  };

  const openInfoGenDrawer = () => {
    setOpenInfoGen(true);
    window.history.pushState(null, "");
  };

  const handleOpenCertificationSuccessful = () => {
    setOpenCertificationSuccessful(true);
    window.history.pushState(null, "");
  };

  const handleOpenCertificationError = () => {
    setOpenCertificationError(true);
    window.history.pushState(null, "");
  };

  return isLoading ? (
    <Grid item container justifyContent="center" mt="30%">
      <CircularProgress />
    </Grid>
  ) : (
    <>
      <Grid item container xs={12} spacing={5}>
        <Grid item container spacing={2}>
          {seentag && (
            <>
              <Grid item xs={12}>
                <TagToolbar tag={seentag} isFollowing={isFollowing} handleFollowToggle={handleFollowToggle} alarmOn={alarmOn} handleAlarmToggle={handleAlarmToggle} />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          )}
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12}>
            <RequestsTable requests={requests} handleCertify={handleCertify} />
          </Grid>
        </Grid>
      </Grid>

      {request && (
        <>
          {request.type === "doc" && (
            <DocGenerator
              tag={tag}
              types={types}
              setTypes={setTypes}
              open={openDocGen}
              setOpen={setOpenDocGen}
              handleOpenCertificationSuccessful={handleOpenCertificationSuccessful}
              handleOpenCertificationError={handleOpenCertificationError}
              request={request}
              setRequest={setRequest}
            />
          )}

          {request.type === "img" && (
            <ImageGenerator
              tag={tag}
              types={types}
              setTypes={setTypes}
              open={openImageGen}
              setOpen={setOpenImageGen}
              handleOpenCertificationSuccessful={handleOpenCertificationSuccessful}
              handleOpenCertificationError={handleOpenCertificationError}
              request={request}
              setRequest={setRequest}
            />
          )}

          {request.type === "info" && (
            <InfoGenerator
              tag={tag}
              types={types}
              setTypes={setTypes}
              open={openInfoGen}
              setOpen={setOpenInfoGen}
              handleOpenCertificationSuccessful={handleOpenCertificationSuccessful}
              handleOpenCertificationError={handleOpenCertificationError}
              request={request}
              setRequest={setRequest}
            />
          )}
        </>
      )}

      {openCertificationSuccessful && <CertificationSuccessful open={openCertificationSuccessful} setOpen={setOpenCertificationSuccessful} />}
      {openCertificationError && <CertificationError open={openCertificationError} setOpen={setOpenCertificationError} />}
    </>
  );
};

export default TagRequests;
