// firebase
import { doc, getDoc, collection, getDocs } from "firebase/firestore";
import { db } from "config/firebase";

// A ---------------------------------------------------------------------- M

const getTag = async (tagID) => {
  try {
    const tagRef = doc(db, "tagsdata", tagID);
    const tagSnapshot = await getDoc(tagRef);

    if (!tagSnapshot.exists()) {
      console.log("Databox does not exist.");
      return {};
    }

    const tagData = tagSnapshot.data();

    const targetsRef = collection(tagRef, "targets");
    const targetsSnapshot = await getDocs(targetsRef);

    const targets = targetsSnapshot.docs.map((targetDoc) => {
      const { name, prototype, prototype_meta } = targetDoc.data();

      return {
        ...(name && { name }),
        ...(prototype && { prototype }),
        ...(prototype_meta && { prototype_meta }),
      };
    });

    return {
      id: tagSnapshot.id,
      ...tagData,
      targets,
    };
  } catch (error) {
    console.error(`Error in getTag for tagID ${tagID}:`, error);
    return {};
  }
};

export default getTag;
