// firebase
import { collection, getDocs } from "firebase/firestore";
import { db } from "config/firebase";

// group-controller
import getGroup from "group-controller/getGroup";

// user-controller
import getUserContacts from "user-controller/getUserContacts";

// A ---------------------------------------------------------------------- M

const getViewGroupsReadOnly = async (tagID) => {
  try {
    const viewgroupsRef = collection(db, "tagsdata", tagID, "viewgroups_readonly");
    const viewgroupsSnapshot = await getDocs(viewgroupsRef);

    const viewgroupsPromises = viewgroupsSnapshot.docs.map(async (viewgroupDoc) => {
      const group = await getGroup(viewgroupDoc.id);
      const creatorContacts = group?.creator_uuid ? await getUserContacts(group.creator_uuid) : {};
      const creatorEmail = creatorContacts.email || "";

      return {
        ...viewgroupDoc.data(),
        ...group,
        creator_email: creatorEmail,
        permissions: "r",
      };
    });

    const groups = await Promise.all(viewgroupsPromises);
    return groups;
  } catch (error) {
    console.error(`Error in getViewGroupsReadOnly for tagID ${tagID}:`, error);
    return [];
  }
};

export default getViewGroupsReadOnly;
