// firebase
import { collection, query, where, orderBy, limit, startAfter, getDocs } from "firebase/firestore";
import { db } from "config/firebase";

import fetchSignature from "./fetchSignature";

// A ---------------------------------------------------------------------- M

const fetchPaginatedRecordsWithTypes = async (tagID, types, isTagGroupMember, lastDoc, rowsPerPage, _orderBy, _order) => {
  console.log(tagID, types, isTagGroupMember, lastDoc, rowsPerPage, _orderBy, _order);

  try {
    const recordsRef = collection(db, "tagsdata", tagID, "signatures");
    let baseQuery = query(recordsRef, where("type", "in", types), orderBy(_orderBy, _order));

    if (!isTagGroupMember) {
      baseQuery = query(baseQuery, where("public", "==", true));
    }

    if (lastDoc) {
      baseQuery = query(baseQuery, startAfter(lastDoc));
    }

    const limitedQuery = query(baseQuery, limit(rowsPerPage));
    const snapshot = await getDocs(limitedQuery);

    const recordPromises = snapshot.docs.map(async (sig) => {
      const signatureData = sig.data();
      const txid = signatureData.txid;
      const signature = await fetchSignature(txid);
      return signature;
    });

    const records = await Promise.all(recordPromises);
    console.log("Fetched paginated records:", records);

    return {
      records: records.filter((record) => record !== undefined),
      lastDoc: snapshot.docs[snapshot.docs.length - 1],
    };
  } catch (error) {
    console.error("Error fetching paginated records:", error);
    return { records: [], lastDoc: null };
  }
};

export default fetchPaginatedRecordsWithTypes;
