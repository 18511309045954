// firebase
import { collection, query, where, orderBy, limit, startAfter, getDocs } from "firebase/firestore";
import { db } from "config/firebase";

import fetchSignature from "./fetchSignature";

// A ---------------------------------------------------------------------- M

const fetchPaginatedRecords = async (tagId, type, isTagGroupMember, lastDoc, rowsPerPage, _orderBy, _order, criteria) => {
  console.log(tagId, type, isTagGroupMember, lastDoc, rowsPerPage, _orderBy, _order, criteria);

  try {
    const recordsRef = collection(db, "tagsdata", tagId, "signatures");
    let baseQuery = query(recordsRef, where("type", "==", type), orderBy(_orderBy, _order));

    if (!isTagGroupMember) {
      baseQuery = query(baseQuery, where("public", "==", true));
    }

    // Mappa di priorità (valore più basso = più selettivo)
    const priority = {
      soggettiCodiceFiscale: 1,
      soggettiFullName: 2,
      documentNames: 3,
      soggettiOrganizzazione: 4,
      customParams: 5,
    };

    // Array per i filtri "array-contains" da applicare
    let arrayFilters = [];

    // Applichiamo direttamente i filtri sulle date
    if (criteria) {
      Object.entries(criteria).forEach(([key, value]) => {
        if (value) {
          if (typeof value === "string") {
            value = value.trim();
          }
          if (key === "startDate") {
            baseQuery = query(baseQuery, where("timestamp", ">=", value));
          } else if (key === "endDate") {
            baseQuery = query(baseQuery, where("timestamp", "<=", value));
          } else if (key === "documentNames") {
            arrayFilters.push({ field: "searchIndex.documentNames", value });
          } else if (key === "soggettiFullName") {
            arrayFilters.push({ field: "searchIndex.soggettiFullName", value });
          } else if (key === "soggettiCodiceFiscale") {
            arrayFilters.push({ field: "searchIndex.soggettiCodiceFiscale", value });
          } else if (key === "soggettiOrganizzazione") {
            arrayFilters.push({ field: "searchIndex.soggettiOrganizzazione", value });
          } else {
            // Per i custom params, costruiamo "key:value"
            arrayFilters.push({ field: "searchIndex.customParams", value: `${key}:${value}` });
          }
        }
      });
    }

    // Se abbiamo filtri array-contains, scegliamo quello più selettivo
    let primaryFilter = null;

    if (arrayFilters.length > 0) {
      primaryFilter = arrayFilters.reduce((best, current) => {
        const bestField = best.field.split(".")[1];
        const currentField = current.field.split(".")[1];
        return priority[currentField] < priority[bestField] ? current : best;
      });
      // Rimuoviamo il filtro scelto dall'array
      arrayFilters = arrayFilters.filter((filter) => filter !== primaryFilter);
      // Applichiamo il filtro primario nella query Firestore
      baseQuery = query(baseQuery, where(primaryFilter.field, "array-contains", primaryFilter.value));
    }

    if (lastDoc) {
      baseQuery = query(baseQuery, startAfter(lastDoc));
    }

    const limitedQuery = query(baseQuery, limit(rowsPerPage));
    const snapshot = await getDocs(limitedQuery);

    // Estrae i documenti raw da Firestore (da tagsdata/signatures)
    let rawDocs = snapshot.docs.map((doc) => doc.data());
    console.log("Raw documents:", rawDocs);

    // Filtraggio manuale: per ogni documento raw ottenuto, verifichiamo che contenga anche tutti gli altri termini (logica AND)
    if (arrayFilters.length > 0) {
      rawDocs = rawDocs.filter((doc) => {
        return arrayFilters.every((filter) => {
          // Estrae il nome del campo, ad es. "documentNames" da "searchIndex.documentNames"
          const fieldName = filter.field.split(".")[1];
          const fieldValue = doc.searchIndex[fieldName];
          return Array.isArray(fieldValue) ? fieldValue.includes(filter.value) : false;
        });
      });
    }

    // Ora, per ogni documento filtrato, chiamiamo fetchSignature
    const recordPromises = rawDocs.map(async (doc) => {
      console.log(doc);
      const signature = await fetchSignature(doc.txid);
      return signature;
    });

    let records = await Promise.all(recordPromises);
    records = records.filter((record) => record !== undefined);
    console.log("Final filtered records:", records);

    return {
      records,
      lastDoc: snapshot.docs[snapshot.docs.length - 1],
    };
  } catch (error) {
    console.error("Error fetching paginated records:", error);
    return { records: [], lastDoc: null };
  }
};

export default fetchPaginatedRecords;
