// react
import React, { useState, useEffect, useCallback } from "react";

// context
import { UserAuth } from "context/AuthContext";

// @mui
import { useMediaQuery, Grid, Fab, CircularProgress } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";

// ui-components
import DataBoxCreate from "ui-components/DataboxManagement/DataBoxCreate";
import DataBoxesTable from "ui-components/DataboxManagement/DataBoxesTable";
import { DataboxCreationSuccessfulSnackbar } from "ui-components/ORFeedbacks";

// firebase
import { doc, collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "config/firebase";

// user-controller
import fetchSeenTags from "user-controller/fetchSeenTags";

// databox-controller
import getTagInfo from "databox-controller/getTagInfo";

// A ---------------------------------------------------------------------- M

const MyDataboxes = () => {
  const { user, canCreateDatabox, conservSostL1 } = UserAuth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [seentags, setSeenTags] = useState([]);
  const [openGen, setOpenGen] = useState(false);
  const [openDataboxCreationSuccessful, setOpenDataboxCreationSuccessful] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Fetch seen tags and enrich them with tag info
  const getSeenTagsWithNames = useCallback(async (userID) => {
    try {
      setIsLoading(true);
      const tags = await fetchSeenTags(userID);
      const tagsWithNames = await Promise.all(
        tags.map(async (tag) => {
          const tagInfo = await getTagInfo(tag.id);
          return { ...tag, ...tagInfo, name: tagInfo?.name || "N/A" };
        })
      );
      tagsWithNames.sort((a, b) => b.firstseen - a.firstseen);
      setSeenTags(tagsWithNames);
    } catch (error) {
      console.error("Error in getSeenTagsWithNames:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (user?.uid) {
      getSeenTagsWithNames(user.uid);

      // Listen for new tag additions in real time
      const currentTimestamp = Math.floor(Date.now() / 1000).toString();
      const userdataRef = doc(db, "userdata", user.uid);
      const seentagsColRef = collection(userdataRef, "seentags");
      const newTagsQuery = query(seentagsColRef, where("firstseen", ">=", currentTimestamp));

      const unsubscribe = onSnapshot(newTagsQuery, async (snapshot) => {
        const newTags = await Promise.all(
          snapshot.docChanges().map(async (change) => {
            if (change.type === "added") {
              const id = change.doc.id;
              const data = change.doc.data();
              const tagInfo = await getTagInfo(id);
              console.log(data);
              console.log(tagInfo);
              return { ...data, id, name: tagInfo?.name || "N/A", tipologiaDocumentale: tagInfo?.tipologiaDocumentale || "N/A" };
            }
            return null;
          })
        );
        const filtered = newTags.filter((tag) => tag !== null);
        if (filtered.length > 0) {
          setSeenTags((prev) => [...filtered, ...prev].sort((a, b) => b.firstseen - a.firstseen));
        }
      });

      return () => unsubscribe();
    }
  }, [user, getSeenTagsWithNames]);

  const openTagGen = () => {
    setOpenGen(true);
    window.history.pushState(null, "");
  };

  const handleOpenDataboxCreationSuccessful = () => {
    setOpenDataboxCreationSuccessful(true);
    window.history.pushState(null, "");
  };

  if (isLoading) {
    return (
      <Grid item container justifyContent="center" mt="30%">
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <>
      {seentags && <DataBoxesTable tags={seentags} handleOpen={openTagGen} />}
      {(canCreateDatabox || conservSostL1) && (
        <>
          <DataBoxCreate open={openGen} setOpen={setOpenGen} handleSuccessful={handleOpenDataboxCreationSuccessful} />
          <DataboxCreationSuccessfulSnackbar open={openDataboxCreationSuccessful} setOpen={setOpenDataboxCreationSuccessful} />
        </>
      )}
      {isMobile && (canCreateDatabox || conservSostL1) && (
        <Fab color="primary" aria-label="add-databox" sx={{ position: "fixed", bottom: 120, right: 16 }} onClick={openTagGen}>
          <AddIcon />
        </Fab>
      )}
    </>
  );
};

export default MyDataboxes;
