import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import JSZip from "jszip";
import { saveAs } from "file-saver";

// A ---------------------------------------------------------------------- M

const downloadAsZip = async (uploadID) => {
  const storage = getStorage();
  const storageRef = ref(storage, uploadID);

  try {
    // List all files in the folder
    const fileList = await listAll(storageRef);

    const zip = new JSZip();
    const folder = zip.folder(uploadID);

    // Loop through each file and add it to the zip
    const filePromises = fileList.items.map(async (fileRef) => {
      const fileUrl = await getDownloadURL(fileRef);

      // Fetch the file content
      const response = await fetch(fileUrl);
      const fileData = await response.blob();

      // Add the file to the zip folder
      folder.file(fileRef.name, fileData);
    });

    // Wait for all files to be added to the zip
    await Promise.all(filePromises);

    // Generate the ZIP file and trigger download
    const zipContent = await zip.generateAsync({ type: "blob" });
    saveAs(zipContent, `${uploadID}.zip`);
  } catch (error) {
    console.error("Error creating ZIP file:", error);
  }
};

export default downloadAsZip;
