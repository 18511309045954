// react
import React, { useEffect, useState } from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// context
import { UserAuth } from "context/AuthContext";

// @mui
import {
  Box,
  SwipeableDrawer,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import { CloseOutlined as CloseOutlinedIcon, Add as AddIcon } from "@mui/icons-material";

// ui-components
import { Puller } from "ui-components/Puller";

// databox-controller
import { addViewGroup, addViewGroupReadOnly } from "databox-controller/manageViewGroup";
import getViewGroups from "databox-controller/getViewGroups";
import getViewGroupsReadOnly from "databox-controller/getViewGroupsReadOnly";

// group-controller
import getUserGroups from "group-controller/getUserGroups";

// A ---------------------------------------------------------------------- M

const getButtonStyle = (action) => ({
  py: 0.5,
  borderColor: "divider",
  color: action === "close" ? "error.main" : "primary.main",
  textTransform: "none",
  boxShadow: "none",
  borderRadius: 10,
  "&:hover": {
    borderColor: action === "close" ? "error.dark" : "primary.dark",
    backgroundColor: "action.hover",
  },
});

const AddViewGroup = ({ tag, open, setOpen, handleSuccessfulAdded, handleErrorAdded }) => {
  const { user, conservSostL1 } = UserAuth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [permissions, setPermissions] = useState("r");
  const [groupAlreadyAdded, setGroupAlreadyAdded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Close dialog on popstate
  useEffect(() => {
    const handlePopstate = () => {
      if (open) handleReset();
    };
    window.addEventListener("popstate", handlePopstate);
    return () => window.removeEventListener("popstate", handlePopstate);
  }, [open]);

  // Fetch groups when dialog opens
  useEffect(() => {
    if (open) {
      const fetchGroups = async () => {
        setIsLoading(true);
        try {
          const userGroups = await getUserGroups(user.uid);
          const viewGroups = await getViewGroups(tag.id);
          const viewGroupReadOnly = await getViewGroupsReadOnly(tag.id);
          const viewGroupIds = viewGroups.map((group) => group.id);
          const viewGroupReadOnlyIds = viewGroupReadOnly.map((group) => group.id);
          const filteredGroups = userGroups.filter((group) => !viewGroupIds.includes(group.id) && !viewGroupReadOnlyIds.includes(group.id));
          filteredGroups.sort((a, b) => a.name.localeCompare(b.name));
          setGroups(filteredGroups);
        } catch (error) {
          console.error("Error fetching groups:", error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchGroups();
    }
  }, [tag, open, user.uid]);

  // Reset any "already added" error when selectedGroup changes
  useEffect(() => {
    setGroupAlreadyAdded(false);
  }, [selectedGroup]);

  const handleAddViewGroup = async () => {
    setIsLoading(true);
    try {
      let result;
      if (conservSostL1 && permissions === "r") {
        result = await addViewGroupReadOnly(tag.id, selectedGroup, user.uid);
      } else {
        result = await addViewGroup(tag.id, selectedGroup, user.uid);
      }

      if (result.code === 0) {
        handleSuccessfulAdded();
      } else if (result.code === -1) {
        setGroupAlreadyAdded(true);
      } else {
        handleErrorAdded();
      }
    } catch (error) {
      handleErrorAdded();
    } finally {
      handleReset();
      setIsLoading(false);
    }
  };

  const handleChangeGroup = (e) => setSelectedGroup(e.target.value);
  const handleChangePermissions = (e) => setPermissions(e.target.value);

  const handleReset = () => {
    setSelectedGroup("");
    setPermissions("r");
    setOpen(false);
  };

  const ViewgroupForm = groups && (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControl fullWidth required>
          <InputLabel>{t("name")}</InputLabel>
          <Select label={t("name")} value={selectedGroup} onChange={handleChangeGroup}>
            {groups.map((group) => (
              <MenuItem key={group.id} value={group.id}>
                {group.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {conservSostL1 && (
        <Grid item xs={12}>
          <FormControl fullWidth required>
            <InputLabel>{t("permissions")}</InputLabel>
            <Select label={t("permissions")} value={permissions} onChange={handleChangePermissions}>
              <MenuItem value="r">read</MenuItem>
              <MenuItem value="r/w">read/write</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      )}
      <Grid item xs={12}>
        {groupAlreadyAdded && (
          <Typography variant="body2" color="error">
            {t("group_already_added")}
          </Typography>
        )}
      </Grid>
    </Grid>
  );

  if (isLoading) {
    return (
      <Grid item container justifyContent="center" mt="30%">
        <CircularProgress />
      </Grid>
    );
  }
  
  return isMobile ? (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={handleReset}
      onOpen={() => setOpen(true)}
      sx={{
        "& .MuiDrawer-paper": {
          width: "100%",
          height: "45%",
          borderTopLeftRadius: "4%",
          borderTopRightRadius: "4%",
        },
      }}
    >
      <Puller />
      <Box sx={{ p: 3 }}>
        <Grid container alignItems="center" justifyContent="center" sx={{ mb: 3 }}>
          <Typography variant="h6" fontWeight="bold">
            {t("add_group")}
          </Typography>
        </Grid>
        {groups ? (
          <>
            {ViewgroupForm}
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Button fullWidth variant="outlined" startIcon={<AddIcon />} onClick={handleAddViewGroup} disabled={!selectedGroup} sx={{ ...getButtonStyle("open"), maxWidth: "200px" }}>
                {t("add")}
              </Button>
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <Typography align="center" color="gray" variant="h6">
              {t("no_group_available")}
            </Typography>
          </Grid>
        )}
      </Box>
    </SwipeableDrawer>
  ) : (
    <Dialog
      open={open}
      onClose={handleReset}
      maxWidth="sm"
      fullWidth
      sx={{
        "& .MuiPaper-root": {
          borderRadius: 2,
          p: 2,
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
        },
      }}
    >
      <DialogTitle>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h6" fontWeight="bold">
              {t("add_group")}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleReset} color="error" edge="end">
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        {groups ? (
          ViewgroupForm
        ) : (
          <Grid item xs={12}>
            <Typography align="center" color="gray" variant="h6">
              {t("no_group_available")}
            </Typography>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleReset} sx={getButtonStyle("close")}>
          {t("cancel")}
        </Button>
        <Button variant="outlined" onClick={handleAddViewGroup} disabled={!selectedGroup} sx={getButtonStyle("open")}>
          {t("add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddViewGroup;
