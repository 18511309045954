// react
import React, { useState, useEffect, useMemo } from "react";

// react-router-dom
import { useNavigate } from "react-router-dom";

// prop-types
import PropTypes from "prop-types";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import {
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableFooter,
  TablePagination,
  IconButton,
  useMediaQuery,
  useTheme,
  Tooltip,
} from "@mui/material";
import { KeyboardArrowLeftOutlined as KeyboardArrowLeftOutlinedIcon, KeyboardArrowRightOutlined as KeyboardArrowRightOutlinedIcon, Search as SearchIcon } from "@mui/icons-material";

// utils
import convertTimestamp from "utils/convertTimestamp";

// A ---------------------------------------------------------------------- M

const ClientsTableToolbar = ({ handleOpen, searchQuery, handleSearchChange, isSearchOpen, setIsSearchOpen }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleSearchIconClick = () => {
    setIsSearchOpen((prev) => !prev);
  };

  return (
    <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
      <Grid item xs={12} sm={6}>
        <Typography variant="h6" fontWeight="bold">
          {t("customer_records")}
        </Typography>
      </Grid>
      <Grid item container xs={12} sm={6} spacing={1} justifyContent="flex-end" alignItems="center">
        {isSearchOpen ? (
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              placeholder={t("search_by_name")}
              value={searchQuery}
              onChange={handleSearchChange}
              variant="outlined"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Tooltip title={t("search")} placement="top">
                      <IconButton onClick={handleSearchIconClick} sx={{ p: 0.5 }}>
                        <SearchIcon fontSize={isMobile ? "small" : "medium"} />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              inputProps={{
                sx: { fontSize: isMobile ? "0.8rem" : "1rem" },
              }}
            />
          </Grid>
        ) : (
          <Grid item>
            <Tooltip title={t("search")} placement="top">
              <IconButton onClick={handleSearchIconClick} sx={{ p: 0.5 }}>
                <SearchIcon fontSize="medium" />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const ClientsTableHead = () => {
  const { t } = useTranslation();

  const headCells = useMemo(
    () => [
      { id: "name", label: t("name") },
      { id: "timestamp", label: t("creation_datetime") },
      { id: "email", label: t("email") },
    ],
    [t]
  );

  return (
    <TableHead sx={{ backgroundColor: "grey.100" }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.id !== "actions" ? "left" : "right"}>
            <Typography variant="body2" fontWeight="bold">
              {headCell.label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const theme = useTheme();
  const isRtl = theme.direction === "rtl";

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={(e) => onPageChange(e, page - 1)} disabled={page === 0} aria-label="previous page">
        {isRtl ? <KeyboardArrowRightOutlinedIcon /> : <KeyboardArrowLeftOutlinedIcon />}
      </IconButton>
      <IconButton onClick={(e) => onPageChange(e, page + 1)} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        {isRtl ? <KeyboardArrowLeftOutlinedIcon /> : <KeyboardArrowRightOutlinedIcon />}
      </IconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const ClientsTable = ({ clients, handleOpen }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredClients, setFilteredClients] = useState(clients);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  useEffect(() => {
    setFilteredClients(clients);
  }, [clients]);

  useEffect(() => {
    setFilteredClients(clients.filter((client) => client.companyName.toLowerCase().includes(searchQuery.toLowerCase())));
    setPage(0);
  }, [searchQuery]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredClients.length) : 0;

  return (
    <Box>
      <ClientsTableToolbar handleOpen={handleOpen} searchQuery={searchQuery} handleSearchChange={handleSearchChange} isSearchOpen={isSearchOpen} setIsSearchOpen={setIsSearchOpen} />
      <TableContainer>
        <Table>
          {!isMobile && <ClientsTableHead />}
          <TableBody>
            {(isMobile ? filteredClients : filteredClients.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)).map((client) => {
              return (
                <TableRow
                  key={client.id}
                  sx={{ cursor: "pointer", "&:hover": { backgroundColor: "#bbdefb" } }}
                  onClick={() =>
                    navigate(`/customers/${client.id}`, {
                      state: { client },
                    })
                  }
                >
                  <TableCell>{client.companyName || "N/A"}</TableCell>
                  <TableCell>{client.generationTimestamp ? convertTimestamp(client.generationTimestamp) : "N/A"}</TableCell>
                  <TableCell sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>{client.email || "N/A"}</TableCell>
                </TableRow>
              );
            })}
            {!isMobile && emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={3} />
              </TableRow>
            )}
          </TableBody>
          {!isMobile && (
            <TableFooter sx={{ backgroundColor: "grey.100" }}>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20]}
                  colSpan={3}
                  count={filteredClients.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={t("rows_per_page")}
                  labelDisplayedRows={({ from, to, count }) => t("displayed_rows", { from, to, count: count !== -1 ? count : `more than ${to}` })}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};

ClientsTable.propTypes = {
  clients: PropTypes.array.isRequired,
  handleOpen: PropTypes.func.isRequired,
};

export default ClientsTable;
