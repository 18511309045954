import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { UserAuth } from "context/AuthContext";
import { useTranslation } from "react-i18next";
import { Grid, CircularProgress, Divider, Tabs, Tab } from "@mui/material";
import ReactFlow, { Controls } from "reactflow";
import "reactflow/dist/style.css";
import TagToolbar from "ui-components/DataboxManagement/TagToolbar";
import UsersNode from "ui-components/FlowMonitorManagement/UsersNode";
import KeyNode from "ui-components/FlowMonitorManagement/KeyNode";
import Databox from "ui-components/FlowMonitorManagement/Databox";
import TargetNode from "ui-components/FlowMonitorManagement/TargetNode";
import EdgeDetailsModal from "ui-components/FlowMonitorManagement/EdgeDetailsModal";
import SourcesDashboard from "ui-components/FlowMonitorManagement/SourcesDashboard";
import SourcesConfiguration from "ui-components/FlowMonitorManagement/SourcesConfiguration";
import { getEdgeLabel, getEdgeColor, getLabelStyle } from "ui-components/FlowMonitorManagement/utils/styleGetters";

// databox-controller
import { addAlarmUser, removeAlarmUser, hasUserAlarmOn } from "databox-controller/manageAlarm";
import isUserFollowingDatabox from "databox-controller/isUserFollowingDatabox";
import { addFollower, removeFollower } from "databox-controller/manageFollower";
import getTag from "databox-controller/getTag";
import getTagOwner from "databox-controller/getTagOwner";
import getAuthorizedUsersData from "databox-controller/getAuthorizedUsersData";
import getAuthorizedKeysData from "databox-controller/getAuthorizedKeysData";
import getTargetsData from "databox-controller/getTargetsData";

// user-controller
import getSeenTag from "user-controller/getSeenTag";
import getUserContacts from "user-controller/getUserContacts";

// A ---------------------------------------------------------------------- M

const nodeTypes = {
  databox: Databox,
  users: UsersNode,
  key: KeyNode,
  target: TargetNode,
};

const FlowMonitor = () => {
  const { user, conservSostL1 } = UserAuth();
  const { tag } = useParams();
  const { t } = useTranslation();

  const [seentag, setSeenTag] = useState();
  const [databoxName, setDataboxName] = useState();
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [edgeModalData, setEdgeModalData] = useState(null);
  const [isFollowing, setIsFollowing] = useState(false);
  const [alarmOn, setAlarmOn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    const fetchSeenTag = async () => {
      const seentag = await getSeenTag(user.uid, tag);
      const tagData = await getTag(tag);
      const tagName = tagData.name;
      const alarmEmails = tagData.alarm_emails || [];
      const alarmEnabled = tagData.alarm_enabled || false;
      const tagOwner = await getTagOwner(tag);
      const tagOwnerData = await getUserContacts(tagOwner);
      const tagOwnerEmail = tagOwnerData.email;

      const updatedSeentag = {
        ...seentag,
        name: tagName,
        alarmEmails,
        alarmEnabled,
        tagOwner: tagOwner,
        tagOwnerEmail: tagOwnerEmail,
      };

      if (conservSostL1 && tagData.tipologiaDocumentale) {
        updatedSeentag.tipologiaDocumentale = tagData.tipologiaDocumentale;
      }

      setSeenTag(updatedSeentag);
    };

    const fetchData = async () => {
      setIsLoading(true);

      // Get databox
      const databox = await getTag(tag);
      const databoxName = databox.name ? databox.name : databox.id;
      setDataboxName(databoxName);

      // Get users
      const authorizedUsersData = await getAuthorizedUsersData(tag);
      const { authorizedUsersArray, totalNumberOfCertifications } = authorizedUsersData;

      // Get keys
      const authorizedKeysData = await getAuthorizedKeysData(tag);
      const { authorizedKeysArray } = authorizedKeysData;

      // Get targets
      const targetsData = await getTargetsData(tag);
      const { targetsArray } = targetsData;

      // Positioning variables
      const databoxX = 500;
      const databoxY = 750;
      const keyX = -200;
      const keySpacing = 150;
      const targetSpacing = 150;

      // Create nodes
      const nodesList = [
        {
          id: "databox",
          type: "databox",
          data: { databoxName, databoxID: tag },
          position: { x: databoxX, y: databoxY },
        },
        {
          id: "users",
          type: "users",
          data: { authorizedUsersArray, totalNumberOfCertifications },
          position: { x: -500, y: 300 },
        },
        ...authorizedKeysArray.map((key, index) => ({
          id: `key-${key.keyId}`,
          type: "key",
          data: key,
          position: { x: keyX, y: 850 + index * keySpacing },
        })),
        ...targetsArray.map((target, index) => ({
          id: `target-${index + 1}`,
          type: "Target",
          data: { target },
          position: { x: 1250, y: 750 + index * targetSpacing },
        })),
      ];

      // Create edges
      const edgesList = [
        {
          id: "users-databox",
          source: "users",
          target: "databox",
          sourceHandle: "users-source",
          targetHandle: "databox-users-target",
          animated: totalNumberOfCertifications > 0,
          type: "step",
          style: {
            stroke: totalNumberOfCertifications > 0 ? "#00c853" : "#f44336",
            strokeWidth: 2,
          },
        },
        ...authorizedKeysArray.map((key, index) => ({
          id: `key-databox-${key.keyId}`,
          source: `key-${key.keyId}`,
          target: "databox",
          sourceHandle: `key-source-${key.keyId}`,
          targetHandle: "databox-key-target",
          animated: key.status === "online" || key.status === "recovered",
          type: "step",
          style: {
            stroke: getEdgeColor(key.status),
            strokeWidth: 2,
          },
          label: getEdgeLabel(key.status),
          labelStyle: getLabelStyle(key.status),
          data: { ...key, databoxName: databoxName },
        })),
        ...targetsArray.map((target, index) => ({
          id: `databox-target-${index + 1}`,
          source: "databox",
          target: `target-${index + 1}`,
          sourceHandle: "databox-target-source",
          targetHandle: `handle-${target.endpoint}`,
          animated: target.enabled,
          type: "step",
          style: {
            stroke: target.enabled ? "#00c853" : "#f44336",
            strokeWidth: 2,
          },
        })),
      ];

      setNodes(nodesList);
      setEdges(edgesList);
      setIsLoading(false);
    };

    const fetch = async () => {
      await fetchSeenTag();
      await fetchData();
      setIsLoading(false);
    };

    fetch();
  }, [tag, user.uid, conservSostL1]);

  useEffect(() => {
    const checkIsFollowing = async () => {
      const following = await isUserFollowingDatabox(user.uid, tag);
      setIsFollowing(following);
    };

    const fetchUserAlarmState = async () => {
      const alarmOn = await hasUserAlarmOn(user.uid, tag);
      setAlarmOn(alarmOn);
    };

    checkIsFollowing();
    fetchUserAlarmState();
  }, [user, tag]);

  const handleFollowToggle = async () => {
    if (isFollowing) await removeFollower(user.uid, tag);
    else await addFollower(user.uid, tag);
    setIsFollowing(!isFollowing);
  };

  const handleAlarmToggle = async () => {
    if (alarmOn) await removeAlarmUser(user.uid, tag);
    else await addAlarmUser(user.uid, tag);
    setAlarmOn((prev) => !prev);
  };

  const handleEdgeMouseEnter = (event, edge) => {
    setEdges((prevEdges) => prevEdges.map((e) => (e.id === edge.id ? { ...e, style: { ...e.style, strokeWidth: 6, filter: "brightness(1.2)" } } : e)));
  };

  const handleEdgeMouseLeave = (event, edge) => {
    setEdges((prevEdges) => prevEdges.map((e) => (e.id === edge.id ? { ...e, style: { ...e.style, strokeWidth: 2, filter: "none" } } : e)));
  };

  const handleCloseEdgeModal = () => {
    setEdgeModalData(null);
  };

  return isLoading ? (
    <Grid item container justifyContent="center" mt="30%">
      <CircularProgress />
    </Grid>
  ) : (
    <Grid container spacing={5}>
      {seentag && (
        <>
          <Grid item xs={12}>
            <TagToolbar tag={seentag} isFollowing={isFollowing} handleFollowToggle={handleFollowToggle} alarmOn={alarmOn} handleAlarmToggle={handleAlarmToggle} />
          </Grid>

          <Grid item container justifyContent="flex-end">
            <Tabs value={tabIndex} onChange={(event, newValue) => setTabIndex(newValue)} centered>
              <Tab label={t("diagram")} />
              <Tab label={t("dashboard")} />
              <Tab label={t("settings")} />
            </Tabs>
          </Grid>

          {tabIndex === 0 && (
            <Grid item container xs={12} spacing={5}>
              {seentag && (
                <>
                  <Grid item container xs={12} sx={{ height: "70vh" }}>
                    <ReactFlow
                      nodes={nodes}
                      edges={edges}
                      nodeTypes={nodeTypes}
                      fitView
                      onEdgeClick={(event, edge) => setEdgeModalData(edge.data)}
                      onEdgeMouseEnter={handleEdgeMouseEnter}
                      onEdgeMouseLeave={handleEdgeMouseLeave}
                    >
                      <Controls />
                    </ReactFlow>
                  </Grid>
                  <EdgeDetailsModal edgeModalData={edgeModalData} handleCloseEdgeModal={handleCloseEdgeModal} />
                </>
              )}
            </Grid>
          )}

          {tabIndex === 1 && (
            <Grid item xs={12}>
              <SourcesDashboard tag={tag} />
            </Grid>
          )}

          {tabIndex === 2 && (
            <Grid item xs={12}>
              <SourcesConfiguration tag={tag} />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default FlowMonitor;
