// react
import React, { useState, useEffect } from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// context
import { UserAuth } from "context/AuthContext";

// @mui
import {
  Box,
  SwipeableDrawer,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
  TableRow,
  TableCell,
  TableContainer,
  Tooltip,
  TableHead,
  TableBody,
  Table,
  CircularProgress,
} from "@mui/material";
import { CloseOutlined as CloseOutlinedIcon, Add as AddIcon, VisibilityOff as VisibilityOffIcon, Visibility as VisibilityIcon, Check as CheckIcon } from "@mui/icons-material";

// ui-components
import { Puller } from "ui-components/Puller";
import { DatakeyAddedSuccessfulSnackbar } from "ui-components/ORFeedbacks";

// datakey-controller
import getDataKeysByOwner from "datakey-controller/getDataKeysByOwner";

// databox-controller
import { addAuthorizedKey } from "databox-controller/manageAuthorizedKey";

// A ---------------------------------------------------------------------- M

const getButtonStyle = (action) => ({
  py: 0.5,
  borderColor: "divider",
  color: action === "close" ? "error.main" : "primary.main",
  textTransform: "none",
  boxShadow: "none",
  borderRadius: 10,
  "&:hover": {
    borderColor: action === "close" ? "error.dark" : "primary.dark",
    backgroundColor: "action.hover",
  },
});

const AddDataKey = ({ tag, dataKeys, open, setOpen }) => {
  const { user } = UserAuth();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [keys, setKeys] = useState([]);
  const [addedKeys, setAddedKeys] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // Reset state on dialog close/popstate
  useEffect(() => {
    const handlePopstate = () => {
      if (open) handleReset();
    };
    window.addEventListener("popstate", handlePopstate);
    return () => window.removeEventListener("popstate", handlePopstate);
  }, [open]);

  // Fetch keys when the dialog opens
  useEffect(() => {
    const fetchKeys = async () => {
      setIsLoading(true);
      try {
        const fetchedKeys = await getDataKeysByOwner(user.uid);
        setKeys(fetchedKeys);
      } catch (error) {
        console.error("Error fetching data keys:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (open) {
      fetchKeys();
    }
  }, [open, user.uid, dataKeys]);

  // Helper: check if key already added (either from local state or passed dataKeys)
  const isKeyAdded = (keyId) => addedKeys.includes(keyId) || dataKeys.some((key) => key.id === keyId);

  const handleAddKey = async (keyId) => {
    try {
      await addAuthorizedKey(tag.id, keyId, user.uid);
      setAddedKeys((prev) => [...prev, keyId]);
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error adding key:", error);
    }
  };

  const handleReset = () => {
    setAddedKeys([]);
    setOpen(false);
  };

  // A minimal table row component with toggle for key visibility
  const TableRowWithToggle = ({ name, datakey, added, handleAdd }) => {
    const [showDataKey, setShowDataKey] = useState(false);
    const toggleDataKeyVisibility = () => setShowDataKey((prev) => !prev);
    return (
      <TableRow>
        <TableCell>{name}</TableCell>
        <TableCell
          align="left"
          sx={{
            minWidth: showDataKey ? "200px" : "60px",
            maxWidth: showDataKey ? "200px" : "60px",
          }}
        >
          {showDataKey ? datakey : "••••••"}
          <IconButton onClick={toggleDataKeyVisibility} size="small" color="primary">
            {showDataKey ? <VisibilityOffIcon fontSize="small" /> : <VisibilityIcon fontSize="small" />}
          </IconButton>
        </TableCell>
        <TableCell align="right" size="small">
          {!added ? (
            <IconButton color="primary" onClick={handleAdd}>
              <AddIcon fontSize="small" />
            </IconButton>
          ) : (
            <IconButton disabled>
              <CheckIcon fontSize="small" />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
    );
  };

  const renderTable = () => (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="left">{t("name")}</TableCell>
            <TableCell align="left">{t("value")}</TableCell>
            <TableCell align="right">{t("actions")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {keys.map((key) => {
            const added = isKeyAdded(key.id);
            return <TableRowWithToggle key={key.id} name={key.name} datakey={key.datakey} added={added} handleAdd={() => !added && handleAddKey(key.id)} />;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const content = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {renderTable()}
      </Grid>
    </Grid>
  );

  if (isLoading) {
    return (
      <Grid item container justifyContent="center" mt="30%">
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <>
      {isMobile ? (
        <SwipeableDrawer
          anchor="bottom"
          open={open}
          onClose={handleReset}
          onOpen={() => setOpen(true)}
          sx={{
            "& .MuiDrawer-paper": {
              width: "100%",
              height: "90%",
              borderTopLeftRadius: "4%",
              borderTopRightRadius: "4%",
            },
          }}
        >
          <Puller />
          <Box sx={{ p: 3 }}>{content}</Box>
        </SwipeableDrawer>
      ) : (
        <Dialog
          open={open}
          onClose={handleReset}
          maxWidth="md"
          fullWidth
          sx={{
            "& .MuiPaper-root": {
              borderRadius: 2,
              p: 2,
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
            },
          }}
        >
          <DialogTitle>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h6" fontWeight="bold">
                  {t("add_datakey")}
                </Typography>
              </Grid>
              <Grid item>
                <Tooltip title={t("close")} placement="top">
                  <IconButton onClick={handleReset} color="error">
                    <CloseOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>{content}</DialogContent>
          <DialogActions>
            <Button variant="outlined" sx={getButtonStyle("close")} onClick={handleReset}>
              {t("close")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <DatakeyAddedSuccessfulSnackbar open={snackbarOpen} setOpen={setSnackbarOpen} />
    </>
  );
};

export default AddDataKey;
