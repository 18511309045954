// react
import React, { useState, useEffect } from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import { useMediaQuery, Box, SwipeableDrawer, Dialog, DialogTitle, DialogContent, DialogActions, Grid, Button, IconButton, Typography, TextField, Tooltip } from "@mui/material";
import { CloseOutlined as CloseOutlinedIcon, Add as AddIcon } from "@mui/icons-material";

// ui-components
import { Puller } from "ui-components/Puller";
import { LoadingComponent } from "ui-components/LoadingComponent";

// group-controller
import { addMember } from "group-controller/manageMember";

// user-controller
import getUUIDFromEmail from "user-controller/getUUIDFromEmail";

// A ---------------------------------------------------------------------- M

const getButtonStyle = (action) => ({
  py: 0.5,
  borderColor: "divider",
  color: action === "close" ? "error.main" : "primary.main",
  textTransform: "none",
  boxShadow: "none",
  borderRadius: 10,
  "&:hover": {
    borderColor: action === "close" ? "error.dark" : "primary.dark",
    backgroundColor: "action.hover",
  },
});

const AddMember = ({ group, open, setOpen, handleSuccessfulAdded, handleErrorAdded }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [newMemberEmail, setNewMemberEmail] = useState("");
  const [userNotFound, setUserNotFound] = useState(false);
  const [userAlreadyAdded, setUserAlreadyAdded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Reset error states when email changes
  useEffect(() => {
    setUserNotFound(false);
    setUserAlreadyAdded(false);
  }, [newMemberEmail]);

  // Handle browser popstate to close the dialog
  useEffect(() => {
    const handlePopstate = () => {
      if (open) {
        handleReset();
      }
    };
    window.addEventListener("popstate", handlePopstate);
    return () => window.removeEventListener("popstate", handlePopstate);
  }, [open]);

  const handleAddMember = async () => {
    setIsLoading(true);
    try {
      const newMemberUID = await getUUIDFromEmail(newMemberEmail);
      if (!newMemberUID) {
        setUserNotFound(true);
        setIsLoading(false);
        return;
      }
      const result = await addMember(newMemberUID, group.id);
      switch (result.status) {
        case "success":
          handleReset();
          handleSuccessfulAdded();
          break;
        case "user_already_added":
          setUserAlreadyAdded(true);
          break;
        case "error":
        default:
          handleErrorAdded();
      }
    } catch (error) {
      console.error("Error adding member:", error.message);
      handleErrorAdded();
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    setNewMemberEmail("");
    setUserNotFound(false);
    setUserAlreadyAdded(false);
    setOpen(false);
  };

  const AddMemberForm = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField fullWidth margin="dense" label={t("email")} required variant="outlined" value={newMemberEmail} onChange={(e) => setNewMemberEmail(e.target.value)} />
      </Grid>
      {userNotFound && (
        <Grid item xs={12}>
          <Typography variant="body2" color="error" gutterBottom>
            {t("member_error_1")}
          </Typography>
        </Grid>
      )}
      {userAlreadyAdded && (
        <Grid item xs={12}>
          <Typography variant="body2" color="error" gutterBottom>
            {t("member_error_2")}
          </Typography>
        </Grid>
      )}
    </Grid>
  );

  return isMobile ? (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={handleReset}
      onOpen={() => setOpen(true)}
      sx={{
        "& .MuiDrawer-paper": {
          width: "100%",
          height: "45%",
          borderTopLeftRadius: "4%",
          borderTopRightRadius: "4%",
        },
      }}
    >
      <Puller />
      <Box sx={{ p: 3 }}>
        <Grid container alignItems="center" justifyContent="center" mb={3}>
          <Typography variant="h6" fontWeight="bold">
            {t("add_member")}
          </Typography>
        </Grid>
        {isLoading ? (
          <LoadingComponent />
        ) : (
          <>
            {AddMemberForm}
            <Grid container mt={3}>
              <Grid item xs={12}>
                <Button fullWidth variant="outlined" onClick={handleAddMember} disabled={!newMemberEmail} sx={getButtonStyle("open")}>
                  {t("add")}
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </SwipeableDrawer>
  ) : (
    <Dialog
      open={open}
      onClose={handleReset}
      maxWidth="sm"
      fullWidth
      sx={{
        "& .MuiPaper-root": {
          borderRadius: 2,
          p: 2,
          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <DialogTitle>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h6" fontWeight="bold">
              {t("add_member")}
            </Typography>
          </Grid>
          <Grid item>
            <Tooltip title={t("close")} placement="top">
              <IconButton onClick={handleReset} edge="end" sx={{ color: "error.main" }}>
                <CloseOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </DialogTitle>
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <>
          <DialogContent dividers>{AddMemberForm}</DialogContent>
          <DialogActions>
            <Button variant="outlined" sx={getButtonStyle("close")} onClick={handleReset}>
              {t("cancel")}
            </Button>
            <Button variant="outlined" onClick={handleAddMember} disabled={!newMemberEmail} sx={getButtonStyle("open")}>
              {t("add")}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default AddMember;
