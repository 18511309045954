// firebase
import { collection, query, getDocs, doc } from "firebase/firestore";
import { db } from "config/firebase";

import fetchRequest from "./fetchRequest";

// A ---------------------------------------------------------------------- M

const fetchRequests = async (tagID) => {
  try {
    const tagRef = doc(db, "tagsdata", tagID);
    const signaturesRef = collection(tagRef, "signature_requests");

    const q = query(signaturesRef);
    const snapshot = await getDocs(q);

    const requestPromises = snapshot.docs.map(async (req) => {
      const id = req.id;
      const request = await fetchRequest(id);
      return {
        ...request,
        completed: req.data().completed || false,
      };
    });

    const requests = await Promise.all(requestPromises);
    const requestsFiltered = requests.filter((obj) => obj !== undefined);
    return requestsFiltered;
  } catch (error) {
    console.error("Error in fetchRequests:", error);
    return null;
  }
};

export default fetchRequests;
