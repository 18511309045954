// react
import React, { useState, useEffect, useCallback } from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// context
import { UserAuth } from "context/AuthContext";

// mui
import {
  Box,
  Button,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Tooltip,
  Snackbar,
  Alert,
  Switch,
  CircularProgress,
} from "@mui/material";
import { Add as AddIcon, DeleteForever as DeleteForeverIcon, CloseOutlined as CloseOutlinedIcon } from "@mui/icons-material";

// databox-controller
import { createAlarmTrigger, deleteAlarmTrigger } from "databox-controller/manageAlarm";
import fetchAlarmTriggers from "databox-controller/fetchAlarmTriggers";
import updateAlarmTrigger from "databox-controller/updateAlarmTrigger";
import fetchAlarmEvents from "databox-controller/fetchAlarmEvents";

// record-controller
import fetchSensorAndMagnitudeData from "record-controller/fetchSensorAndMagnitudeData";

// firebase
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { db } from "config/firebase";

// utils
import convertTimestamp from "utils/convertTimestamp";

// A ---------------------------------------------------------------------- M

const AlarmViewer = ({ tag, isTagGroupMember }) => {
  const { user } = UserAuth();
  const { t } = useTranslation();

  const [triggers, setTriggers] = useState([]);
  const [alarmEvents, setAlarmEvents] = useState([]);
  const [isLoadingEvents, setIsLoadingEvents] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [newTrigger, setNewTrigger] = useState({ source: "", magnitude: "", operator: "", value: "", unit: "", enabled: true });
  const [sensors, setSensors] = useState([]);
  const [magnitudes, setMagnitudes] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");

  const fetchData = useCallback(async () => {
    try {
      const alarmTriggers = await fetchAlarmTriggers(tag.id);
      setTriggers(alarmTriggers);

      const { sensors, magnitudes } = await fetchSensorAndMagnitudeData(tag.id, isTagGroupMember);
      setSensors(sensors);
      setMagnitudes(magnitudes);

      const events = await fetchAlarmEvents(tag.id, 25);
      setAlarmEvents(events);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoadingEvents(false);
    }
  }, [tag, isTagGroupMember]);

  useEffect(() => {
    fetchData();
  }, [tag.id, isTagGroupMember]);

  useEffect(() => {
    const currentTimestampInSeconds = Math.floor(Date.now() / 1000);
    const triggersRef = collection(db, "tagsdata", tag.id, "triggers");
    const q = query(triggersRef, where("createdAt", ">", currentTimestampInSeconds.toString()));

    const unsubscribeCreation = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          const newTrigger = { id: change.doc.id, ...change.doc.data() };
          setTriggers((prev) => [newTrigger, ...prev]);
        }
      });
    });

    const unsubscribeDeletion = onSnapshot(triggersRef, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "removed") {
          const removedTriggerId = change.doc.id;
          setTriggers((prev) => prev.filter((trigger) => trigger.id !== removedTriggerId));
        }
      });
    });

    const alarmEventsRef = collection(db, "tagsdata", tag.id, "alarm_events");
    const qAlarm = query(alarmEventsRef, where("timestamp", ">", currentTimestampInSeconds.toString()));

    const unsubscribeAlarm = onSnapshot(qAlarm, (snapshot) => {
      snapshot.docChanges().forEach(async (change) => {
        if (change.type === "added") {
          const events = await fetchAlarmEvents(tag.id, 25);
          setAlarmEvents(events);
        }
      });
    });

    return () => {
      unsubscribeCreation();
      unsubscribeDeletion();
      unsubscribeAlarm();
    };
  }, [tag, isTagGroupMember]);

  const handleAddTrigger = async () => {
    try {
      const trigger = {
        source: newTrigger.source,
        sourceName: sensors.find((sensor) => sensor.id === newTrigger.source)?.name || "",
        magnitude: newTrigger.magnitude,
        operator: newTrigger.operator,
        value: newTrigger.value,
        unit: newTrigger.unit,
        enabled: newTrigger.enabled,
      };

      await createAlarmTrigger(tag.id, user.uid, trigger);
      handleReset();
      showSnackbar(t("trigger_added_success"), "success");
    } catch (error) {
      console.error("Failed to add trigger:", error);
      showSnackbar(t("trigger_added_error"), "error");
    }
  };

  const handleDeleteTrigger = async (id) => {
    try {
      await deleteAlarmTrigger(tag.id, id);
      showSnackbar(t("trigger_deleted_success"), "success");
    } catch (error) {
      console.error("Failed to delete trigger:", error);
      showSnackbar(t("trigger_deleted_error"), "error");
    }
  };

  const handleToggleEnabled = async (id, enabled) => {
    try {
      await updateAlarmTrigger(tag.id, id, { enabled: !enabled });
      setTriggers((prev) => prev.map((trigger) => (trigger.id === id ? { ...trigger, enabled: !enabled } : trigger)));
      showSnackbar(t(enabled ? "trigger_disabled" : "trigger_enabled"), "success");
    } catch (error) {
      console.error("Failed to update trigger:", error);
      showSnackbar(t("trigger_update_error"), "error");
    }
  };

  const handleReset = () => {
    setOpenDialog(false);
    setNewTrigger({ source: "", magnitude: "", operator: "", value: "", unit: "", enabled: true });
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} p={1}>
        <Typography variant="h6" fontWeight="bold">
          {t("triggers")}
        </Typography>
        <Button variant="outlined" startIcon={<AddIcon />} onClick={() => setOpenDialog(true)}>
          {t("new_trigger")}
        </Button>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography fontWeight="bold">{t("source_name")}</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight="bold">{t("magnitude")}</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight="bold">{t("condition")}</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight="bold">{t("enabled")}</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight="bold">{t("creation_date")}</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography fontWeight="bold">{t("actions")}</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {triggers.map((trigger) => (
              <TableRow key={trigger.id}>
                <TableCell>{trigger.sourceName || trigger.source}</TableCell>
                <TableCell>{trigger.magnitude}</TableCell>
                <TableCell>
                  {trigger.operator} {trigger.value}
                </TableCell>
                <TableCell>
                  <Switch checked={trigger.enabled} onChange={() => handleToggleEnabled(trigger.id, trigger.enabled)} color={trigger.enabled ? "success" : "primary"} />
                </TableCell>
                <TableCell>{trigger.createdAt ? convertTimestamp(trigger.createdAt) : "N/A"}</TableCell>
                <TableCell align="right">
                  <Tooltip title={t("remove")} placement="top">
                    <IconButton color="error" onClick={() => handleDeleteTrigger(trigger.id)}>
                      <DeleteForeverIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box display="flex" justifyContent="space-between" alignItems="center" mt={5} mb={2} p={1}>
        <Typography variant="h6" fontWeight="bold" color="error">
          🚨 {t("events")}
        </Typography>
      </Box>

      {isLoadingEvents ? (
        <Box display="flex" justifyContent="center" alignItems="center" height={200}>
          <CircularProgress />
        </Box>
      ) : alarmEvents.length > 0 ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography fontWeight="bold">{t("source_name")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight="bold">{t("magnitude")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight="bold">{t("recorded_value")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight="bold">{t("condition_met")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight="bold">{t("generation_datetime")}</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {alarmEvents.map((event) => (
                <TableRow key={event.id}>
                  <TableCell>
                    <Typography variant="body2" color="textPrimary">
                      {event.triggerSourceName || event.triggerSource}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="textPrimary">
                      {event.recordMagnitude}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="textPrimary">
                      {event.recordValue} {event.recordUom ?? ""}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Box>
                      <Typography variant="body2" color="textPrimary">
                        {event.triggerOperator} {event.triggerValue}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="textPrimary">
                      {event.timestamp ? convertTimestamp(event.timestamp) : "N/A"}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center" height={200} borderRadius={3} border="1px dashed" borderColor="textSecondary">
          <Typography variant="body2" color="textSecondary">
            {t("no_alert_events")}
          </Typography>
        </Box>
      )}

      <Dialog
        open={openDialog}
        onClose={handleReset}
        maxWidth="sm"
        fullWidth
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "15px",
            padding: 0.5,
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        <DialogTitle>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="h5" fontWeight="bold">
                {t("new_trigger")}
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip title={t("close")} placement="top">
                <IconButton onClick={handleReset} color="error" edge="end">
                  <CloseOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="source_name">{t("source_name")}</InputLabel>
                <Select
                  labelId={t("source_name")}
                  id="source_name"
                  value={newTrigger.source}
                  onChange={(e) => setNewTrigger({ ...newTrigger, source: e.target.value })}
                  input={<OutlinedInput label={t("source_name")} />}
                >
                  {sensors.map((sensor) => (
                    <MenuItem key={sensor.id} value={sensor.id}>
                      {sensor.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="magnitude">{t("magnitude")}</InputLabel>
                <Select
                  labelId={t("magnitude")}
                  id="magnitude"
                  value={newTrigger.magnitude}
                  onChange={(e) => setNewTrigger({ ...newTrigger, magnitude: e.target.value })}
                  input={<OutlinedInput label={t("magnitude")} />}
                >
                  {magnitudes.map((magnitude) => (
                    <MenuItem key={magnitude} value={magnitude}>
                      {magnitude}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel id="operator-label">{t("operator")}</InputLabel>
                <Select
                  labelId="operator-label"
                  id="operator"
                  value={newTrigger.operator}
                  onChange={(e) => setNewTrigger({ ...newTrigger, operator: e.target.value })}
                  input={<OutlinedInput label={t("operator")} />}
                >
                  <MenuItem value=">">{">"}</MenuItem>
                  <MenuItem value="<">{"<"}</MenuItem>
                  <MenuItem value=">=">{">="}</MenuItem>
                  <MenuItem value="<=">{"<="}</MenuItem>
                  <MenuItem value="==">{"=="}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField fullWidth label={t("value")} value={newTrigger.value} onChange={(e) => setNewTrigger({ ...newTrigger, value: e.target.value })} placeholder={"30"} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>{t("cancel")}</Button>
          <Button variant="contained" onClick={handleAddTrigger}>
            {t("add")}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AlarmViewer;
