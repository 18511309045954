const fetchBlockchainRecord = async (txid, pubkey, pubkeyURL) => {
  try {
    const requestBody = {
      txid,
      ...(pubkey && { pubkey }),
      ...(pubkeyURL && { pubkeyURL }),
    };

    const response = await fetch("https://serving.armilis.com/api/v0.3a/transactionverify", {
      method: "POST",
      headers: { "Content-Type": "application/json; charset=UTF-8" },
      body: JSON.stringify(requestBody),
    });

    // Verifica che la risposta sia OK (code 201)
    if (!response.ok) {
      const errorText = await response.text();
      console.error("fetchBlockchainRecord: non-OK response", response.status, errorText);
      throw new Error(`Response status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error in fetchBlockchainRecord:", error);
    return null;
  }
};

export default fetchBlockchainRecord;
