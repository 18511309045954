import React, { useState } from "react";
import { UserAuth } from "context/AuthContext";
import { Handle, Position } from "reactflow";
import "reactflow/dist/style.css";
import { Typography, Card } from "@mui/material";
import DataboxLogModal from "./DataboxLogModal";

// A ---------------------------------------------------------------------- M

const Databox = ({ data }) => {
  const { conservSostL1 } = UserAuth();

  const [isLogOpen, setIsLogOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleNodeClick = () => {
    if (conservSostL1) setIsLogOpen(true);
  };

  const handleCloseLog = () => {
    setIsLogOpen(false);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const isActive = isHovered || (conservSostL1 && isLogOpen);

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          width: "600px",
          p: 4,
          borderRadius: "12px",
          background: isActive ? "linear-gradient(135deg, #00BCD4, #009688)" : "linear-gradient(135deg, #00BCD4, #009688)",
          border: isActive ? "3px solid rgba(255, 255, 255, 0.9)" : "1px solid rgba(255, 255, 255, 0.1)",
          boxShadow: isActive ? "0 8px 20px rgba(0, 0, 0, 0.6)" : "0 4px 12px rgba(0, 0, 0, 0.3)",
          textAlign: "center",
          color: "#ffffff",
          position: "relative",
          cursor: "pointer",
          transform: isActive ? "scale(1.1)" : "scale(1)",
          transition: "all 0.3s ease-in-out",
          filter: isActive ? "drop-shadow(0px 0px 12px rgba(0, 173, 255, 0.9))" : "none",
        }}
        onClick={handleNodeClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Typography variant="h5" gutterBottom fontWeight="bold">
          {data.databoxName}
        </Typography>

        <Handle
          type="target"
          position={Position.Left}
          id="databox-users-target"
          style={{
            top: "30%",
            background: "#4caf50",
            border: "2px solid #ffffff",
            width: "14px",
            height: "14px",
          }}
        />

        <Handle
          type="target"
          position={Position.Left}
          id="databox-key-target"
          style={{
            top: "50%",
            background: "#4caf50",
            border: "2px solid #ffffff",
            width: "14px",
            height: "14px",
          }}
        />

        <Handle
          type="source"
          position={Position.Right}
          id="databox-target-source"
          style={{
            top: "50%",
            background: "#4caf50",
            border: "2px solid #ffffff",
            width: "14px",
            height: "14px",
          }}
        />
      </Card>

      {conservSostL1 && isLogOpen && <DataboxLogModal isOpen={isLogOpen} onClose={handleCloseLog} databoxID={data.databoxID} />}
    </>
  );
};

export default Databox;
