// firebase
import { collection, getDocs } from "firebase/firestore";
import { db } from "config/firebase";

// A ---------------------------------------------------------------------- M

const getCustomers = async () => {
  try {
    const clientsCollection = collection(db, "clientsdata");
    const clientsSnapshot = await getDocs(clientsCollection);

    const customers = await Promise.all(
      clientsSnapshot.docs.map(async (doc) => {
        const clientData = doc.data();
        const agreementsCollection = collection(db, "clientsdata", doc.id, "agreements");
        const agreementsSnapshot = await getDocs(agreementsCollection);
        const agreements = agreementsSnapshot.docs.map((agreementDoc) => ({
          id: agreementDoc.id,
          ...agreementDoc.data(),
        }));

        return {
          id: doc.id,
          ...clientData,
          agreements,
        };
      })
    );

    return customers;
  } catch (error) {
    console.error("Error fetching clients:", error);
    return [];
  }
};

export default getCustomers;
