// react
import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";

// prop-types
import PropTypes from "prop-types";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import {
  Grid,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableFooter,
  TablePagination,
  Typography,
  IconButton,
  useMediaQuery,
  useTheme,
  TableSortLabel,
  Tooltip,
  CircularProgress,
  Button,
  Snackbar,
  Alert,
  Chip,
} from "@mui/material";
import {
  KeyboardArrowLeftOutlined as KeyboardArrowLeftOutlinedIcon,
  KeyboardArrowRightOutlined as KeyboardArrowRightOutlinedIcon,
  Search as SearchIcon,
  Add as AddIcon,
  ManageSearch as ManageSearchIcon,
  Verified as VerifiedIcon,
} from "@mui/icons-material";

// ui-components
import CDNFileDetailsDialog from "./CDNFileDetailsDialog";
import CDNSearchDialog from "./CDNSearchDialog";

// record-controller
import fetchPaginatedRecords from "record-controller/fetchPaginatedRecords";
import fetchSignature from "record-controller/fetchSignature";
import fetchTotalRecordsCount from "record-controller/fetchTotalRecordsCount";

// firebase
import { onSnapshot, query, collection, orderBy as orderByQuery, where } from "firebase/firestore";
import { db } from "config/firebase";

// utils
import convertTimestamp from "utils/convertTimestamp";

// A ---------------------------------------------------------------------- M

const CDNFileTableToolbar = ({ isTagGroupMember, isTagGroupMemberReadWrite, handleOpenSearchDialog, openUploadGenDrawer }) => {
  const { t } = useTranslation();

  const buttonStyle = {
    py: 0.5,
    borderColor: "divider",
    color: "text.primary",
    textTransform: "none",
    boxShadow: "none",
    borderRadius: 10,
    "&:hover": {
      borderColor: "primary.main",
      backgroundColor: "action.hover",
    },
  };

  return (
    <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
      <Grid item xs={12} sm={6}>
        <Typography variant="h6" fontWeight="bold">
          {t("documents")}
        </Typography>
      </Grid>
      <Grid item container xs={12} sm={6} spacing={1} justifyContent="flex-end">
        {isTagGroupMember && (
          <Grid item xs={12} sm={4}>
            <Button variant="outlined" fullWidth startIcon={<SearchIcon fontSize="small" />} sx={buttonStyle} onClick={handleOpenSearchDialog}>
              {t("search")}
            </Button>
          </Grid>
        )}
        {isTagGroupMemberReadWrite && (
          <Grid item xs={12} sm={4}>
            <Button variant="outlined" fullWidth startIcon={<AddIcon fontSize="small" />} sx={buttonStyle} onClick={openUploadGenDrawer}>
              {t("upload")}
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const CDNFileTableHead = ({ order, orderBy, onRequestSort }) => {
  const { t } = useTranslation();

  const headCells = useMemo(
    () => [
      { id: "file_name", label: t("file_name") },
      { id: "timestamp", label: t("upload_datetime") },
      { id: "document_type", label: t("document_type") },
      { id: "actions", label: t("actions") },
    ],
    [t]
  );

  const createSortHandler = (property) => () => {
    if (property === "timestamp") {
      onRequestSort(property);
    }
  };

  return (
    <TableHead sx={{ backgroundColor: "grey.100" }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.id !== "actions" ? "left" : "right"}>
            {headCell.id === "timestamp" ? (
              <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)}>
                <Typography variant="body2" fontWeight="bold">
                  {headCell.label}
                </Typography>
              </TableSortLabel>
            ) : (
              <Typography variant="body2" fontWeight="bold">
                {headCell.label}
              </Typography>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

CDNFileTableHead.propTypes = {
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
};

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const theme = useTheme();
  const isRtl = theme.direction === "rtl";

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={(e) => onPageChange(e, page - 1)} disabled={page === 0} aria-label="previous page">
        {isRtl ? <KeyboardArrowRightOutlinedIcon /> : <KeyboardArrowLeftOutlinedIcon />}
      </IconButton>
      <IconButton onClick={(e) => onPageChange(e, page + 1)} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        {isRtl ? <KeyboardArrowLeftOutlinedIcon /> : <KeyboardArrowRightOutlinedIcon />}
      </IconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const CDNFileTable = ({ tag, isTagGroupMember, isTagGroupMemberReadWrite, isTagGroupMemberReadOnly, openUploadGenDrawer, handleFileClick, handleOpenPreservationProofDialog }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [records, setRecords] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("timestamp");
  const [order, setOrder] = useState("desc");
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const [isSearchDialogOpen, setIsSearchDialogOpen] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState(null);

  const recordCacheRef = useRef({});
  const lastDocCacheRef = useRef({});
  const pageRef = useRef(page);

  useEffect(() => {
    pageRef.current = page;
  }, [page]);

  const resetState = () => {
    setRecords([]);
    recordCacheRef.current = {};
    lastDocCacheRef.current = {};
  };

  const fetchTotalCount = useCallback(async () => {
    try {
      const totalCount = await fetchTotalRecordsCount(tag.id, "CDNFile", isTagGroupMember, searchCriteria);
      setTotalRecords(totalCount);
    } catch (error) {
      console.error("Error fetching total record count:", error);
      setTotalRecords(0);
    }
  }, [tag.id, isTagGroupMember, isTagGroupMemberReadWrite, isTagGroupMemberReadOnly, searchCriteria]);

  const fetchData = useCallback(async () => {
    if (recordCacheRef.current[pageRef.current]) {
      setRecords(recordCacheRef.current[pageRef.current]);
      return;
    }

    setIsLoading(true);

    try {
      const lastDoc = pageRef.current > 0 ? lastDocCacheRef.current[pageRef.current - 1] : null;
      const { records: newRecords, lastDoc: newLastDoc } = await fetchPaginatedRecords(tag.id, "CDNFile", isTagGroupMember, lastDoc, rowsPerPage, orderBy, order, searchCriteria);
      recordCacheRef.current[pageRef.current] = newRecords;
      lastDocCacheRef.current[pageRef.current] = newLastDoc;
      setRecords(newRecords);
    } catch (error) {
      console.error("Error fetching paginated records:", error);
      setRecords([]);
    } finally {
      setIsLoading(false);
    }
  }, [tag.id, isTagGroupMember, isTagGroupMemberReadWrite, isTagGroupMemberReadOnly, page, rowsPerPage, orderBy, order, searchCriteria]);

  useEffect(() => {
    fetchTotalCount();
    fetchData();
  }, [fetchTotalCount, fetchData]);

  useEffect(() => {
    if (!tag.id) return;

    const collectionRef = collection(db, "tagsdata", tag.id, "signatures");
    const q = query(collectionRef, where("type", "==", "CDNFile"), where("timestamp", ">=", Date.now().toString()), orderByQuery("timestamp", "desc"));

    const unsubscribe = onSnapshot(q, async (snapshot) => {
      try {
        const recordPromises = snapshot.docs.map((doc) => fetchSignature(doc.id));
        const detailedRecords = await Promise.all(recordPromises);
        const newRecords = detailedRecords.filter((record) => record !== null);

        if (newRecords.length === 0) return;

        if (pageRef.current === 0) {
          recordCacheRef.current = {};
          lastDocCacheRef.current = {};
          fetchData();
        } else {
          setSnackbarOpen(true);
        }

        setTotalRecords((prevCount) => prevCount + 1);
      } catch (error) {
        console.error("Error processing new records:", error);
      }
    });

    return () => unsubscribe();
  }, [tag.id, isTagGroupMember, isTagGroupMemberReadWrite, isTagGroupMemberReadOnly, rowsPerPage, orderBy, order]);

  const handleChangePage = (event, newPage) => {
    pageRef.current = newPage;
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    pageRef.current = 0;
    setPage(0);
    resetState();
  };

  const handleRequestSort = (property) => {
    if (property === "timestamp") {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
      pageRef.current = 0;
      setPage(0);
      resetState();
    }
  };

  const handleNewDataAvailable = () => {
    recordCacheRef.current = {};
    lastDocCacheRef.current = {};

    if (pageRef.current === 0) {
      fetchData();
    } else {
      pageRef.current = 0;
      setPage(0);
    }

    setSnackbarOpen(false);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbarOpen(false);
  };

  const handleOpenDetails = (record) => {
    setSelectedRecord(record);
    setDetailsDialogOpen(true);
  };

  const handleCloseDetails = () => {
    setDetailsDialogOpen(false);
    setSelectedRecord(null);
  };

  const handleOpenSearchDialog = () => setIsSearchDialogOpen(true);
  const handleCloseSearchDialog = () => setIsSearchDialogOpen(false);

  const handleSearch = (criteria) => {
    setSearchCriteria(criteria);
    pageRef.current = 0;
    setPage(0);
    resetState();
  };

  if (isLoading) {
    return (
      <Box position="absolute" top={0} left={0} right={0} bottom={0} display="flex" justifyContent="center" alignItems="center" bgcolor="rgba(255, 255, 255, 0.8)" zIndex={10}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box>
        <CDNFileTableToolbar
          isTagGroupMember={isTagGroupMember}
          isTagGroupMemberReadWrite={isTagGroupMemberReadWrite}
          handleOpenSearchDialog={handleOpenSearchDialog}
          openUploadGenDrawer={openUploadGenDrawer}
        />
        {searchCriteria && (
          <Box mb={2}>
            <Box display="flex" flexWrap="wrap" gap={1}>
              {Object.entries(searchCriteria).map(([key, value]) =>
                value ? (
                  <Chip
                    key={key}
                    label={`${t(key)}: ${["startDate", "endDate"].includes(key) ? convertTimestamp(value) : value}`}
                    color="primary"
                    variant="outlined"
                    sx={{ fontWeight: 500, fontSize: "0.9rem" }}
                  />
                ) : null
              )}
              <Chip
                label={t("clear_search")}
                onDelete={() => {
                  setSearchCriteria(null);
                  resetState();
                  fetchTotalCount();
                  fetchData();
                }}
                color="error"
                variant="filled"
                sx={{ fontWeight: "bold", fontSize: "0.9rem" }}
              />
            </Box>
          </Box>
        )}
        <TableContainer>
          <Table size={isMobile ? "small" : "medium"}>
            <CDNFileTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
            <TableBody>
              {records.map((record) => {
                const documentName = record.metadata?.documenti?.find((doc) => doc.idDocumento === record.metadata?.documentoPrincipale)?.nomeDocumento || <Typography color="gray">N/A</Typography>;
                return (
                  <TableRow key={record.id}>
                    <TableCell sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>{documentName}</TableCell>
                    <TableCell>{record.timestamp ? convertTimestamp(record.timestamp) : <Typography color="gray">N/A</Typography>}</TableCell>
                    <TableCell sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>{record.metadata?.tipologiaDocumentale || <Typography color="gray">N/A</Typography>}</TableCell>
                    <TableCell align="right" size="small">
                      <Tooltip title={t("details")} placement="top">
                        <IconButton color="primary" onClick={() => handleOpenDetails(record)}>
                          <ManageSearchIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t("preservation_proof")} placement="top">
                        <IconButton color="success" onClick={() => handleOpenPreservationProofDialog(record)}>
                          <VerifiedIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            {!isMobile && (
              <TableFooter sx={{ backgroundColor: "grey.100" }}>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 20]}
                    colSpan={5}
                    count={totalRecords}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={t("rows_per_page")}
                    labelDisplayedRows={({ from, to, count }) => t("displayed_rows", { from, to, count: count !== -1 ? count : `more than ${to}` })}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
          <Alert
            onClose={handleSnackbarClose}
            severity="info"
            action={
              <Button color="inherit" size="small" onClick={handleNewDataAvailable}>
                {t("reload")}
              </Button>
            }
          >
            {t("new_data_available")}
          </Alert>
        </Snackbar>
      </Box>

      {selectedRecord && <CDNFileDetailsDialog open={detailsDialogOpen} onClose={handleCloseDetails} record={selectedRecord} handleFileClick={handleFileClick} />}

      <CDNSearchDialog open={isSearchDialogOpen} onClose={handleCloseSearchDialog} onSearch={handleSearch} />
    </>
  );
};

export default CDNFileTable;
