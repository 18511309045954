// react
import React from "react";

// prop-types
import PropTypes from "prop-types";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import { useMediaQuery, Grid, SwipeableDrawer, Stack, Dialog, DialogTitle, DialogContent, DialogActions, Typography, IconButton, Box, Tooltip, Button, Card, CardContent } from "@mui/material";
import { AccessTime as AccessTimeIcon, AllInclusive as AllInclusiveIcon, CloseOutlined as CloseOutlinedIcon } from "@mui/icons-material";

// ui-components
import { Puller } from "ui-components/Puller";

// utils
import convertTimestamp from "utils/convertTimestamp";

// A ---------------------------------------------------------------------- M

const getButtonStyle = (action) => {
  return {
    py: 0.5,
    borderColor: "divider",
    color: action === "close" ? "error.main" : "primary.main",
    textTransform: "none",
    boxShadow: "none",
    borderRadius: 10,
    "&:hover": {
      borderColor: action === "close" ? "error.dark" : "primary.dark",
      backgroundColor: "action.hover",
    },
  };
};

const SimpleBlockchainVerification = ({ verification, open, onClose }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  // Caso in cui non esiste il txid
  if (!verification || !verification.txid) {
    const noVerificationContent = (
      <Stack spacing={2}>
        <Typography variant="body1">{t("verification_not_available")}</Typography>
      </Stack>
    );

    return isMobile ? (
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={onClose}
        onOpen={() => {}}
        sx={{
          "& .MuiDrawer-paper": {
            width: "100%",
            height: "90%",
            borderTopLeftRadius: "4%",
            borderTopRightRadius: "4%",
          },
        }}
      >
        <Puller />
        <Box sx={{ p: 3 }}>
          <Typography variant="h6" fontWeight="bold" textAlign="center" mb={3} color="text.primary">
            {t("certification_details")}
          </Typography>
          {noVerificationContent}
        </Box>
      </SwipeableDrawer>
    ) : (
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
        sx={{
          "& .MuiPaper-root": {
            borderRadius: 2,
            p: 2,
            boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        <DialogTitle>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="h6" fontWeight="bold" color="text.primary">
                {t("certification_details")}
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip title={t("close")} placement="top">
                <IconButton onClick={onClose} color="error" edge="end" size="small">
                  <CloseOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>{noVerificationContent}</DialogContent>
        <DialogActions>
          <Button variant="outlined" sx={getButtonStyle("close")} onClick={onClose}>
            {t("close")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  // Scheda per il Certification ID
  const certificationCard = (
    <Card sx={{ borderLeft: "4px solid green", boxShadow: "none", p: 1, mb: 2 }}>
      <CardContent>
        <Typography variant="body1" fontWeight="bold" color="text.primary">
          {t("certification_id")}
        </Typography>
        <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
          {verification.txid || "N/A"}
        </Typography>
      </CardContent>
    </Card>
  );

  // Scheda per la Timeliness
  const timelinessColor = verification.tx_timestamp && verification.tx_timestamp !== 0 ? "green" : "gray";
  const timelinessCard = (
    <Card sx={{ borderLeft: `4px solid ${timelinessColor}`, boxShadow: "none", p: 1, mb: 2 }}>
      <CardContent>
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <AccessTimeIcon fontSize="small" color={timelinessColor === "green" ? "success" : "disabled"} />
          </Grid>
          <Grid item xs>
            <Typography variant="body1" fontWeight="bold" color={timelinessColor}>
              {t("timeliness")}
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="body2">
          {verification.tx_timestamp && verification.tx_timestamp !== 0 ? (
            <>
              {t("timeliness_text_1")} <b>{convertTimestamp(verification.tx_timestamp)}</b>
            </>
          ) : verification.tx_timestamp === 0 ? (
            t("loading")
          ) : (
            t("not_available")
          )}
        </Typography>
      </CardContent>
    </Card>
  );

  // Scheda per la Consistency
  const consistencyColor = verification.hsh && verification.check ? "green" : "red";
  const consistencyCard = (
    <Card sx={{ borderLeft: `4px solid ${consistencyColor}`, boxShadow: "none", p: 1, mb: 2 }}>
      <CardContent>
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <AllInclusiveIcon fontSize="small" color={consistencyColor === "green" ? "success" : "error"} />
          </Grid>
          <Grid item xs>
            <Typography variant="body1" fontWeight="bold" color={consistencyColor}>
              {t("consistency")}
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="body2">
          {verification.hsh && verification.check ? (
            <>
              {t("consistency_text_1")} <b>{t("unaltered")}</b> {t("consistency_text_2")}
            </>
          ) : verification.hsh && !verification.check ? (
            <>
              {t("consistency_text_1")} <b>{t("altered")}</b> {t("consistency_text_3")}
            </>
          ) : (
            t("not_available")
          )}
        </Typography>
        <Box mt={2}>
          <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
            <b>Hash calcolato dal file AIC XML:</b> <br /> <span style={{ color: verification.check ? "green" : "red" }}>{verification.record_hash}</span>
          </Typography>
          <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
            <b>Hash registrato in blockchain:</b> <br /> <span style={{ color: verification.check ? "green" : "red" }}>{verification.hsh}</span>
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );

  const content = (
    <Stack spacing={2}>
      {certificationCard}
      {timelinessCard}
      {consistencyCard}
    </Stack>
  );

  return isMobile ? (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={() => {}}
      sx={{
        "& .MuiDrawer-paper": {
          width: "100%",
          height: "90%",
          borderTopLeftRadius: "4%",
          borderTopRightRadius: "4%",
        },
      }}
    >
      <Puller />
      <Box sx={{ p: 3 }}>
        <Typography variant="h6" fontWeight="bold" textAlign="center" mb={3} color="text.primary">
          {t("certification_details")}
        </Typography>
        {content}
      </Box>
    </SwipeableDrawer>
  ) : (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      sx={{
        "& .MuiPaper-root": {
          borderRadius: 2,
          p: 2,
          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <DialogTitle>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h6" fontWeight="bold" color="text.primary">
              {t("certification_details")}
            </Typography>
          </Grid>
          <Grid item>
            <Tooltip title={t("close")} placement="top">
              <IconButton onClick={onClose} color="error" edge="end" size="small">
                <CloseOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>{content}</DialogContent>
      <DialogActions>
        <Button variant="outlined" sx={getButtonStyle("close")} onClick={onClose}>
          {t("close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SimpleBlockchainVerification.propTypes = {
  verification: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SimpleBlockchainVerification;
