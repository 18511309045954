// react
import React, { useState, useEffect, useCallback } from "react";

// context
import { UserAuth } from "context/AuthContext";

// @mui
import { Grid, CircularProgress } from "@mui/material";

// ui-components
import CertificationsTable from "ui-components/CertificationManagement/CertificationsTable";
import DataDialog from "ui-components/CertificationManagement/DataDialog";
import DatapointIntegrityInspector from "ui-components/CertificationManagement/DatapointIntegrityInspector";
import CDNFileDetailsDialog from "ui-components/DataTypeManagement/CDNFile/CDNFileDetailsDialog";
import CDNPreservationProofDialog from "ui-components/DataTypeManagement/CDNFile/CDNPreservationProofDialog";
import FileViewer from "ui-components/FileViewer";
import { LoadingDialog } from "ui-components/LoadingComponent";

// user-controller
import fetchLastSignatures from "user-controller/fetchLastSignatures";

// verification
import verifySignature from "verification/verifySignature";

// A ---------------------------------------------------------------------- M

const MyCertifications = () => {
  const { user, conservSostL1 } = UserAuth();

  const [signatures, setSignatures] = useState([]);
  const [record, setRecord] = useState(null);
  const [verification, setVerification] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [verificationLoading, setVerificationLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileViewerProps, setFileViewerProps] = useState({
    fileType: null,
    xmlContent: null,
    textContent: null,
  });
  const [numPages, setNumPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [dialogs, setDialogs] = useState({
    view: false,
    verify: false,
    preservationProof: false,
    file: false,
  });

  const toggleDialog = (dialog, state = true) => {
    setDialogs((prev) => ({ ...prev, [dialog]: state }));
    if (state) window.history.pushState(null, "");
  };

  const fetchUserData = useCallback(async () => {
    if (!user) return;
    try {
      const sigs = await fetchLastSignatures(user.uid);
      setSignatures(sigs.sort((a, b) => b.timestamp - a.timestamp));
    } catch (error) {
      console.error("Error in fetchUserData:", error.message);
    } finally {
      setIsLoading(false);
    }
  }, [user]);

  useEffect(() => {
    fetchUserData();
  }, [user, fetchUserData]);

  useEffect(() => {
    const handlePopState = () => {
      if (dialogs.view) toggleDialog("view", false);
      else if (dialogs.verify) toggleDialog("verify", false);
      else if (dialogs.preservationProof) toggleDialog("preservationProof", false);
      else if (dialogs.file) toggleDialog("file", false);
    };

    window.addEventListener("popstate", handlePopState);
    return () => window.removeEventListener("popstate", handlePopState);
  }, [dialogs]);

  const handleView = (record) => {
    setRecord(record);
    toggleDialog("view");
  };

  const checkVerification = async (record) => {
    try {
      setVerificationLoading(true);
      const result = await verifySignature(user?.uid || false, record, record.type);
      setVerification(result);
      toggleDialog("verify");
    } catch (error) {
      console.error("Error in checkVerification:", error.message);
    } finally {
      setVerificationLoading(false);
    }
  };

  const checkPreservationProof = (record) => {
    setRecord(record);
    toggleDialog("preservationProof");
  };

  const handleFileClick = async (fileUrl) => {
    try {
      const response = await fetch(fileUrl);
      if (!response.ok) throw new Error(`Failed to fetch file. HTTP status: ${response.status}`);
      const blob = await response.blob();
      const fileType = blob.type;
      let xmlContent = null;
      let textContent = null;

      if (fileType === "application/pdf") {
        // PDF viewer will use fileUrl
      } else if (fileType === "text/xml" || fileType === "application/xml") {
        xmlContent = await blob.text();
      } else if (fileType === "text/plain" || fileType === "text/csv") {
        textContent = await blob.text();
      } else if (fileType === "application/json") {
        const jsonText = await blob.text();
        try {
          textContent = JSON.parse(jsonText);
        } catch (error) {
          throw new Error("Invalid JSON format");
        }
      }
      setFileViewerProps({ fileType, xmlContent, textContent });
      setSelectedFile(fileUrl);
      toggleDialog("file");
    } catch (error) {
      console.error("Error fetching and parsing file:", error.message);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  if (isLoading) {
    return (
      <Grid item container justifyContent="center" mt="30%">
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <>
      <CertificationsTable signatures={signatures} handleView={handleView} checkVerification={checkVerification} checkPreservationProof={checkPreservationProof} handleFileClick={handleFileClick} />
      {verificationLoading && <LoadingDialog open={verificationLoading} />}
      {dialogs.file && (
        <FileViewer
          open={dialogs.file}
          onClose={() => toggleDialog("file", false)}
          fileType={fileViewerProps.fileType}
          fileUrl={selectedFile}
          xmlContent={fileViewerProps.xmlContent}
          textContent={fileViewerProps.textContent}
          currentPage={currentPage}
          numPages={numPages}
          onPageChange={handlePageChange}
        />
      )}
      {record &&
        (conservSostL1 ? (
          <>
            <CDNFileDetailsDialog record={record} open={dialogs.view} onClose={() => toggleDialog("view", false)} handleFileClick={handleFileClick} />
            <CDNPreservationProofDialog
              open={dialogs.preservationProof}
              setOpen={() => toggleDialog("preservationProof", false)}
              onClose={() => toggleDialog("preservationProof", false)}
              record={record}
            />
          </>
        ) : (
          <DataDialog data={{ ...record, databoxName: record.name }} open={dialogs.view} onClose={() => toggleDialog("view", false)} handleFileClick={handleFileClick} />
        ))}
      {verification && <DatapointIntegrityInspector verification={verification} open={dialogs.verify} setOpen={() => toggleDialog("verify", false)} />}
    </>
  );
};

export default MyCertifications;
