// react
import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";

// prop-types
import PropTypes from "prop-types";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableFooter,
  TablePagination,
  IconButton,
  useMediaQuery,
  useTheme,
  TableSortLabel,
  Tooltip,
  Typography,
  CircularProgress,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import {
  KeyboardArrowLeftOutlined as KeyboardArrowLeftOutlinedIcon,
  KeyboardArrowRightOutlined as KeyboardArrowRightOutlinedIcon,
  ManageSearch as ManageSearchIcon,
  Verified as VerifiedIcon,
} from "@mui/icons-material";

// record-controller
import fetchPaginatedRecords from "record-controller/fetchPaginatedRecords";
import fetchSignature from "record-controller/fetchSignature";
import fetchTotalRecordsCount from "record-controller/fetchTotalRecordsCount";

// firebase
import { onSnapshot, query, collection, orderBy as orderByQuery, where } from "firebase/firestore";
import { db } from "config/firebase";

// utils
import isoToUnixTimestamp from "utils/isoToUnixTimestamp";
import convertTimestamp from "utils/convertTimestamp";

// A ---------------------------------------------------------------------- M

const LikeSirtiTableHead = ({ order, orderBy, onRequestSort }) => {
  const { t } = useTranslation();

  const headCells = useMemo(
    () => [
      { id: "timestamp", label: t("generation_datetime") },
      { id: "creator", label: t("creator") },
      { id: "type", label: t("type") },
      { id: "visibility", label: t("visibility") },
      { id: "forwarded", label: t("forwarded") },
      { id: "actions", label: t("actions") },
    ],
    [t]
  );

  const createSortHandler = (property) => () => {
    if (property === "timestamp") {
      onRequestSort(property);
    }
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.id !== "actions" ? "left" : "right"}>
            {headCell.id === "timestamp" ? (
              <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)}>
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

LikeSirtiTableHead.propTypes = {
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
};

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const theme = useTheme();
  const isRtl = theme.direction === "rtl";

  const handleBackButtonClick = (event) => onPageChange(event, page - 1);
  const handleNextButtonClick = (event) => onPageChange(event, page + 1);

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {isRtl ? <KeyboardArrowRightOutlinedIcon /> : <KeyboardArrowLeftOutlinedIcon />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        {isRtl ? <KeyboardArrowLeftOutlinedIcon /> : <KeyboardArrowRightOutlinedIcon />}
      </IconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const LikeSirtiTable = ({ tag, isTagGroupMember, checkVerification, handleView }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [records, setRecords] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("timestamp");
  const [order, setOrder] = useState("desc");
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const recordCacheRef = useRef({});
  const lastDocCacheRef = useRef({});
  const pageRef = useRef(page);

  console.log(recordCacheRef.current);
  console.log(lastDocCacheRef.current);

  useEffect(() => {
    pageRef.current = page;
  }, [page]);

  // Fetch total record count
  const fetchTotalCount = useCallback(async () => {
    try {
      const totalCount = await fetchTotalRecordsCount(tag.id, "like_sirti", isTagGroupMember);
      setTotalRecords(totalCount);
    } catch (error) {
      console.error("Error fetching total record count:", error.message);
      setTotalRecords(0);
    }
  }, [tag.id, isTagGroupMember]);

  // Fetch paginated data
  const fetchData = useCallback(async () => {
    if (recordCacheRef.current[pageRef.current]) {
      setRecords(recordCacheRef.current[pageRef.current]);
      return;
    }

    setIsLoading(true);

    try {
      const lastDoc = pageRef.current > 0 ? lastDocCacheRef.current[pageRef.current - 1] : null;
      const { records: newRecords, lastDoc: newLastDoc } = await fetchPaginatedRecords(tag.id, "like_sirti", isTagGroupMember, lastDoc, rowsPerPage, orderBy, order);

      recordCacheRef.current[pageRef.current] = newRecords;
      lastDocCacheRef.current[pageRef.current] = newLastDoc;

      setRecords(newRecords);
    } catch (error) {
      console.error("Error fetching paginated records:", error.message);
      setRecords([]);
    } finally {
      setIsLoading(false);
    }
  }, [tag.id, isTagGroupMember, page, rowsPerPage, orderBy, order]);

  useEffect(() => {
    fetchTotalCount();
    fetchData();
  }, [fetchTotalCount, fetchData]);

  // Real-time listener
  useEffect(() => {
    if (!tag.id) return;

    const collectionRef = collection(db, "tagsdata", tag.id, "signatures");
    const q = query(collectionRef, where("type", "==", "like_sirti"), where("timestamp", ">=", Date.now().toString()), orderByQuery("timestamp", "desc"));

    const unsubscribe = onSnapshot(q, async (snapshot) => {
      try {
        const recordPromises = snapshot.docs.map((doc) => fetchSignature(doc.id));
        const detailedRecords = await Promise.all(recordPromises);
        const newRecords = detailedRecords.filter((record) => record !== null);

        if (newRecords.length === 0) return;

        if (pageRef.current === 0) {
          recordCacheRef.current = {};
          lastDocCacheRef.current = {};
          fetchData();
        } else {
          setSnackbarOpen(true);
        }

        setTotalRecords((prevCount) => prevCount + 1);
      } catch (error) {
        console.error("Error processing new records:", error.message);
      }
    });

    return () => unsubscribe();
  }, [tag.id, isTagGroupMember, rowsPerPage, orderBy, order]);

  const handleChangePage = (event, newPage) => {
    pageRef.current = newPage;
    setPage(newPage);
  };

  const resetState = () => {
    setRecords([]);
    recordCacheRef.current = {};
    lastDocCacheRef.current = {};
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    pageRef.current = 0;
    setPage(0);
    resetState();
  };

  const handleRequestSort = (property) => {
    if (property === "timestamp") {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
      pageRef.current = 0;
      setPage(0);
      resetState();
    }
  };

  const handleNewDataAvailable = () => {
    recordCacheRef.current = {};
    lastDocCacheRef.current = {};

    if (pageRef.current === 0) {
      fetchData();
    } else {
      pageRef.current = 0;
      setPage(0);
    }

    setSnackbarOpen(false);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbarOpen(false);
  };

  return isLoading ? (
    <Box position="absolute" top={0} left={0} right={0} bottom={0} display="flex" justifyContent="center" alignItems="center" bgcolor="rgba(255, 255, 255, 0.8)" zIndex={10}>
      <CircularProgress />
    </Box>
  ) : (
    <>
      <TableContainer>
        <Table size="small">
          <LikeSirtiTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
          <TableBody>
            {records.map((record) => {
              return (
                <TableRow key={record.id} sx={{ "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" }, cursor: "pointer", "&:hover": { backgroundColor: "#bbdefb" } }}>
                  <TableCell align="left">{record.data.ts ? convertTimestamp(isoToUnixTimestamp(record.data.ts)) : <span style={{ color: "gray" }}>N/A</span>}</TableCell>
                  <TableCell align="left">{record.creator_email || <span style={{ color: "gray" }}>N/A</span>}</TableCell>
                  <TableCell align="left">{t("like_sirti")}</TableCell>
                  <TableCell align="left" sx={{ color: "gray" }}>
                    {record.public ? t("public") : t("private")}
                  </TableCell>
                  <TableCell align="left">
                    {record.isForwarded ? (
                      <Typography variant="body2" color="green">
                        {t("Y")}
                      </Typography>
                    ) : (
                      <Typography variant="body2" color="red">
                        {t("N")}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip title={t("details")} placement="top">
                      <IconButton color="primary" onClick={() => handleView(record)}>
                        <ManageSearchIcon />
                      </IconButton>
                    </Tooltip>
                    {record.id.startsWith("UNCERTIFIED_") ? null : (
                      <Tooltip title={t("verify")} placement="top">
                        <IconButton color="success" onClick={() => checkVerification(record)}>
                          <VerifiedIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
          {!isMobile && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20, { label: "All", value: -1 }]}
                  colSpan={6}
                  count={totalRecords}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={t("rows_per_page")}
                  labelDisplayedRows={({ from, to, count }) => t("displayed_rows", { from, to, count: count !== -1 ? count : `more than ${to}` })}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
        <Alert
          onClose={handleSnackbarClose}
          severity="info"
          action={
            <Button color="inherit" size="small" onClick={handleNewDataAvailable}>
              {t("reload")}
            </Button>
          }
        >
          {t("new_data_available")}
        </Alert>
      </Snackbar>
    </>
  );
};

LikeSirtiTable.propTypes = {
  tag: PropTypes.object.isRequired,
  isTagGroupMember: PropTypes.bool.isRequired,
  checkVerification: PropTypes.func.isRequired,
  handleView: PropTypes.func.isRequired,
};

export default LikeSirtiTable;
