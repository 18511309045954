// react
import React, { useEffect } from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// mui
import { useMediaQuery, Grid, SwipeableDrawer, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, IconButton, Box, Tooltip, Stack } from "@mui/material";
import { AccessTime as AccessTimeIcon, TaskAlt as TaskAltIcon, AllInclusive as AllInclusiveIcon, GpsFixed as GpsFixedIcon, CloseOutlined as CloseOutlinedIcon } from "@mui/icons-material";

// ui-components
import { Puller } from "ui-components/Puller";

// utils
import convertTimestamp from "utils/convertTimestamp";

// A ---------------------------------------------------------------------- M

const getButtonStyle = (action) => {
  return {
    py: 0.5,
    borderColor: "divider",
    color: action === "close" ? "error.main" : "primary.main",
    textTransform: "none",
    boxShadow: "none",
    borderRadius: 10,
    "&:hover": {
      borderColor: action === "close" ? "error.dark" : "primary.dark",
      backgroundColor: "action.hover",
    },
  };
};

const DatapointIntegrityInspector = ({ verification, open, setOpen }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  useEffect(() => {
    const handlePopstate = () => {
      if (open) setOpen(false);
    };

    window.addEventListener("popstate", handlePopstate);
    return () => window.removeEventListener("popstate", handlePopstate);
  }, [open, setOpen]);

  // Se il documento non è stato trovato
  if (verification.tx_not_found) {
    return (
      <Box p={2}>
        <Typography variant="body2" color="gray">
          {t("verification_unable")}
        </Typography>
      </Box>
    );
  }

  // Sezione Certification
  const certificationCard = (
    <Box
      sx={{
        borderLeft: "4px solid green",
        p: 2,
        mb: 2,
        borderRadius: 1,
        backgroundColor: "background.paper",
      }}
    >
      <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
        <strong>{t("certification_id")}:</strong> {verification.txid || "N/A"}
      </Typography>
      <Typography variant="body2" sx={{ wordBreak: "break-all", mt: 1 }}>
        <strong>{t("assigned_databox")}:</strong> {verification.tdr ? verification.tagname || verification.tdr : <i>{t("not_assigned")}</i>}
      </Typography>
    </Box>
  );

  // Sezione Timeliness
  const timelinessColor = verification.tx_timestamp && verification.tx_timestamp !== 0 ? "green" : "gray";
  const timelinessCard = (
    <Box sx={{ borderLeft: `4px solid ${timelinessColor}`, p: 1, mb: 2 }}>
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <AccessTimeIcon fontSize="small" color={timelinessColor === "green" ? "success" : "disabled"} />
        </Grid>
        <Grid item xs>
          <Typography variant="body1" fontWeight="bold" color={timelinessColor}>
            {t("timeliness")}
          </Typography>
        </Grid>
      </Grid>
      <Typography variant="body2">
        {verification.tx_timestamp && verification.tx_timestamp !== 0
          ? `${t("timeliness_text_1")} ${convertTimestamp(verification.tx_timestamp)}`
          : verification.tx_timestamp === 0
          ? t("loading")
          : t("not_available")}
      </Typography>
      <Typography variant="body2" sx={{ mt: 1, wordBreak: "break-all" }}>
        <strong>{t("transaction_id")}:</strong>
      </Typography>
      <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
        {verification.txid}
      </Typography>
    </Box>
  );

  // Sezione Validity
  const validityColor = !verification.pubkey && !verification.pubkeyURL ? "gray" : verification.signature_status === "verified" ? "green" : "red";
  const validityCard = (
    <Box sx={{ borderLeft: `4px solid ${validityColor}`, p: 1, mb: 2 }}>
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <TaskAltIcon fontSize="small" color={validityColor === "green" ? "success" : validityColor === "red" ? "error" : "disabled"} />
        </Grid>
        <Grid item xs>
          <Typography variant="body1" fontWeight="bold" color={validityColor}>
            {t("validity")}
          </Typography>
        </Grid>
      </Grid>
      <Typography variant="body2">
        {!verification.pubkey && !verification.pubkeyURL
          ? t("validity_text_1")
          : verification.signature_status === "verified"
          ? verification.server_id !== "no server identity available"
            ? `${t("validity_text_2")} ${verification.server_id}`
            : verification.pubkey
            ? `${t("validity_text_2")} ARMILIS SA`
            : ""
          : t("signature_mismatch")}
      </Typography>
      <Typography variant="body2" sx={{ mt: 1, wordBreak: "break-all" }}>
        <strong>{t("signature")}:</strong>
      </Typography>
      <Typography variant="body2" sx={{ wordBreak: "break-all" }} color={!verification.pubkey && !verification.pubkeyURL ? "gray" : validityColor}>
        {verification.sig}
      </Typography>
    </Box>
  );

  // Sezione Consistency
  const consistencyColor = verification.txid && verification.hsh && verification.check ? "green" : "red";
  const consistencyCard = (
    <Box sx={{ borderLeft: `4px solid ${consistencyColor}`, p: 1, mb: 2 }}>
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <AllInclusiveIcon fontSize="small" color={consistencyColor === "green" ? "success" : "error"} />
        </Grid>
        <Grid item xs>
          <Typography variant="body1" fontWeight="bold" color={consistencyColor}>
            {t("consistency")}
          </Typography>
        </Grid>
      </Grid>
      <Typography variant="body2">
        {verification.hsh && verification.check
          ? `${t("consistency_text_1")} ${t("unaltered")} ${t("consistency_text_2")}`
          : verification.hsh && !verification.check
          ? `${t("consistency_text_1")} ${t("altered")} ${t("consistency_text_3")}`
          : t("not_available")}
      </Typography>
      <Box mt={2}>
        <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
          <strong>{t("record_hash")}:</strong> <span style={{ color: verification.check ? "green" : "red" }}>{verification.record_hash}</span>
        </Typography>
        <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
          <strong>{t("source_hash")}:</strong> <span style={{ color: verification.check ? "green" : "red" }}>{verification.hsh}</span>
        </Typography>
      </Box>
    </Box>
  );

  // Sezione Accuracy
  const accuracyColor = verification.txid && verification.tdr ? "green" : "red";
  const accuracyCard = (
    <Box sx={{ borderLeft: `4px solid ${accuracyColor}`, p: 1, mb: 2 }}>
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <GpsFixedIcon fontSize="small" color={accuracyColor === "green" ? "success" : "error"} />
        </Grid>
        <Grid item xs>
          <Typography variant="body1" fontWeight="bold" color={accuracyColor}>
            {t("accuracy")}
          </Typography>
        </Grid>
      </Grid>
      <Typography variant="body2">{verification.txid && verification.tdr ? `${t("accuracy_text_1")} ${verification.tagname || verification.tdr}` : t("not_available")}</Typography>
    </Box>
  );

  const content = (
    <Stack spacing={5}>
      {certificationCard}
      {timelinessCard}
      {validityCard}
      {consistencyCard}
      {accuracyCard}
    </Stack>
  );

  return isMobile ? (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      sx={{
        "& .MuiDrawer-paper": {
          width: "100%",
          height: "90%",
          borderTopLeftRadius: "4%",
          borderTopRightRadius: "4%",
        },
      }}
    >
      <Puller />
      <Box sx={{ p: 3 }}>
        <Typography variant="h6" fontWeight="bold" textAlign="center" mb={3} color="text.primary">
          {t("certification_details")}
        </Typography>
        {content}
      </Box>
    </SwipeableDrawer>
  ) : (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="md"
      fullWidth
      sx={{
        "& .MuiPaper-root": {
          borderRadius: 2,
          p: 2,
          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <DialogTitle>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h6" fontWeight="bold" color="text.primary">
              {t("certification_details")}
            </Typography>
          </Grid>
          <Grid item>
            <Tooltip title={t("close")} placement="top">
              <IconButton onClick={() => setOpen(false)} color="error" edge="end" size="small">
                <CloseOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>{content}</DialogContent>
      <DialogActions>
        <Button variant="outlined" sx={getButtonStyle("close")} onClick={() => setOpen(false)}>
          {t("close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DatapointIntegrityInspector;
