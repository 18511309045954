// react
import React, { useState } from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// context
import { UserAuth } from "context/AuthContext";

// @mui
import { Snackbar, Alert } from "@mui/material";

// ui-components
import CDNFileTable from "./CDNFileTable";
import CDNPreservationProofDialog from "./CDNPreservationProofDialog";
import FileViewer from "ui-components/FileViewer";

// event-logger
import logEvent from "event-logger/logEvent";

// utils
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

const CDNFileViewer = ({ tag, isTagGroupMember, isTagGroupMemberReadWrite, isTagGroupMemberReadOnly, openUploadGenDrawer }) => {
  const { user } = UserAuth();
  const { t } = useTranslation();

  const [record, setRecord] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [xmlContent, setXmlContent] = useState(null);
  const [textContent, setTextContent] = useState(null);
  const [selectedFileType, setSelectedFileType] = useState(null);
  const [numPages, setNumPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isPreservationProofDialogOpen, setIsPreservationProofDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");

  const handleOpenPreservationProofDialog = async (record) => {
    setRecord(record);
    setIsPreservationProofDialogOpen(true);
  };

  const handleClosePreservationProofDialog = () => {
    setIsPreservationProofDialogOpen(false);
  };

  const handleCloseDialog = () => {
    setSelectedFile(null);
    setXmlContent("");
    setTextContent("");
    setCurrentPage(1);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleFileClick = async (fileUrl, fileName) => {
    const timestamp = unixTimestampInSeconds();
    try {
      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error(`Failed to fetch file. HTTP status: ${response.status}`);
      }
      const blob = await response.blob();
      const fileType = blob.type;
      setSelectedFileType(fileType);

      if (fileType === "application/pdf") {
        setSelectedFile(fileUrl);
      } else if (fileType === "text/xml" || fileType === "application/xml") {
        const xmlText = await blob.text();
        setXmlContent(xmlText);
        setSelectedFile(fileUrl);
      } else if (fileType === "text/plain" || fileType === "text/csv") {
        const txt = await blob.text();
        setTextContent(txt);
        setSelectedFile(fileUrl);
      } else if (fileType === "application/json") {
        const jsonText = await blob.text();
        try {
          const parsedJson = JSON.parse(jsonText);
          setTextContent(parsedJson);
        } catch (error) {
          throw new Error("Invalid JSON format");
        }
        setSelectedFile(fileUrl);
      } else if (fileType.startsWith("image/") || fileType.startsWith("video/")) {
        setSelectedFile(fileUrl);
      } else {
        throw new Error("Unsupported file type");
      }

      setSnackbarMessage(t("success_opening_file"));
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      await logEvent(tag.id, "event_archive_open_file_success", timestamp, `${fileName} opened successfully.`, null, user.uid, null, "success");
    } catch (error) {
      console.error("Error fetching and parsing file:", error.message);
      setSnackbarMessage(t("error_opening_file"));
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      await logEvent(tag.id, "event_archive_open_file_error", timestamp, `${fileName} open error.`, null, user.uid, null, "error");
    }
  };

  const renderFileDialog = () => {
    if (!selectedFileType) return null;
    return (
      <FileViewer
        open={Boolean(selectedFile)}
        onClose={handleCloseDialog}
        fileType={selectedFileType}
        fileUrl={selectedFile}
        xmlContent={xmlContent}
        textContent={textContent}
        currentPage={currentPage}
        numPages={numPages}
        onPageChange={handlePageChange}
      />
    );
  };

  return (
    <>
      {tag && (
        <CDNFileTable
          tag={tag}
          isTagGroupMember={isTagGroupMember}
          isTagGroupMemberReadWrite={isTagGroupMemberReadWrite}
          isTagGroupMemberReadOnly={isTagGroupMemberReadOnly}
          openUploadGenDrawer={openUploadGenDrawer}
          handleFileClick={handleFileClick}
          handleOpenPreservationProofDialog={handleOpenPreservationProofDialog}
        />
      )}

      {isPreservationProofDialogOpen && record && (
        <CDNPreservationProofDialog record={record} open={isPreservationProofDialogOpen} setOpen={setIsPreservationProofDialogOpen} onClose={handleClosePreservationProofDialog} />
      )}

      {renderFileDialog()}
      
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} variant="filled" sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CDNFileViewer;
