// react
import React from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// react-pdf
import { Document, Page, pdfjs } from "react-pdf";

// react-xml-viewer
import XMLViewer from "react-xml-viewer";

// @mui
import { useMediaQuery, Dialog, Box, IconButton, Typography, Tooltip } from "@mui/material";
import { KeyboardArrowLeftOutlined as KeyboardArrowLeftOutlinedIcon, KeyboardArrowRightOutlined as KeyboardArrowRightOutlinedIcon, CloseOutlined as CloseOutlinedIcon } from "@mui/icons-material";

// A ---------------------------------------------------------------------- M

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const FileViewer = ({ fileType, fileUrl, xmlContent, textContent, currentPage, numPages, onPageChange, open, onClose }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleDocumentLoadSuccess = ({ numPages }) => {
    onPageChange(1, numPages);
  };

  switch (fileType) {
    case "application/pdf":
      return (
        <Dialog
          open={open}
          onClose={onClose}
          fullWidth
          maxWidth="sm"
          sx={{
            "& .MuiPaper-root": {
              borderRadius: "15px",
              padding: 1,
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
            },
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Tooltip placement="top" title={t("close")}>
              <IconButton onClick={onClose} color="error" aria-label="close">
                <CloseOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Document file={fileUrl} onLoadSuccess={handleDocumentLoadSuccess}>
            <Page pageNumber={currentPage} width={isMobile ? 300 : 600} renderAnnotationLayer={false} renderTextLayer={false} />
          </Document>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <IconButton onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1} aria-label="previous page">
              <KeyboardArrowLeftOutlinedIcon />
            </IconButton>
            <Typography>{`${currentPage} / ${numPages}`}</Typography>
            <IconButton onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === numPages} aria-label="next page">
              <KeyboardArrowRightOutlinedIcon />
            </IconButton>
          </Box>
        </Dialog>
      );

    case "text/xml":
    case "application/xml":
      return (
        <Dialog
          open={open}
          onClose={onClose}
          fullWidth
          maxWidth="sm"
          sx={{
            "& .MuiPaper-root": {
              borderRadius: "15px",
              padding: 1,
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
            },
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Tooltip placement="top" title={t("close")}>
              <IconButton onClick={onClose} color="error" aria-label="close">
                <CloseOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Box sx={{ p: 3 }}>
            <XMLViewer xml={xmlContent} />
          </Box>
        </Dialog>
      );

    case "text/plain":
      return (
        <Dialog
          open={open}
          onClose={onClose}
          fullWidth
          maxWidth="sm"
          sx={{
            "& .MuiPaper-root": {
              borderRadius: "15px",
              padding: 1,
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
            },
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Tooltip placement="top" title={t("close")}>
              <IconButton onClick={onClose} color="error">
                <CloseOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Box sx={{ p: 3 }}>
            <Typography>{textContent}</Typography>
          </Box>
        </Dialog>
      );

    case "text/csv":
      try {
        const rows = textContent.split("\n").map((row) => row.split(","));
        return (
          <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="sm"
            sx={{
              "& .MuiPaper-root": {
                borderRadius: "15px",
                padding: 1,
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
              },
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Tooltip placement="top" title={t("close")}>
                <IconButton onClick={onClose} color="error" aria-label="close">
                  <CloseOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Box sx={{ p: 3 }}>
              <table>
                <tbody>
                  {rows.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex}>{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
          </Dialog>
        );
      } catch {
        return (
          <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="sm"
            sx={{
              "& .MuiPaper-root": {
                borderRadius: "15px",
                padding: 1,
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
              },
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Tooltip placement="top" title={t("close")}>
                <IconButton onClick={onClose} color="error" aria-label="close">
                  <CloseOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Box sx={{ p: 3 }}>
              <Typography>{textContent}</Typography>
            </Box>
          </Dialog>
        );
      }

    case "application/json":
      return (
        <Dialog
          open={open}
          onClose={onClose}
          fullWidth
          maxWidth="sm"
          sx={{
            "& .MuiPaper-root": {
              borderRadius: "15px",
              padding: 1,
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
            },
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Tooltip placement="top" title={t("close")}>
              <IconButton onClick={onClose} color="error" aria-label="close">
                <CloseOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Box sx={{ p: 3 }}>
            <pre>{JSON.stringify(textContent, null, 2)}</pre>
          </Box>
        </Dialog>
      );

    case "image/png":
    case "image/jpg":
    case "image/jpeg":
      return (
        <Dialog
          open={open}
          onClose={onClose}
          fullWidth
          maxWidth="sm"
          sx={{
            "& .MuiPaper-root": {
              borderRadius: "15px",
              padding: 0,
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
            },
          }}
        >
          <Tooltip placement="top" title={t("close")}>
            <IconButton
              onClick={onClose}
              color="error"
              sx={{
                position: "absolute",
                top: "0%",
                right: "0%",
                zIndex: 1,
              }}
              aria-label="close"
            >
              <CloseOutlinedIcon />
            </IconButton>
          </Tooltip>
          <img src={fileUrl} alt="Preview" style={{ width: "100%" }} />
        </Dialog>
      );

    case "video/webm":
    case "video/x-matroska":
      return (
        <Dialog
          open={open}
          onClose={onClose}
          fullWidth
          maxWidth="sm"
          sx={{
            "& .MuiPaper-root": {
              borderRadius: "15px",
              padding: 0,
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
            },
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Tooltip placement="top" title={t("close")}>
              <IconButton onClick={onClose} color="error" aria-label="close">
                <CloseOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Box sx={{ p: 3 }}>
            <video
              src={fileUrl}
              controls
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
              }}
            >
              Your browser does not support the video tag.
            </video>
          </Box>
        </Dialog>
      );

    default:
      return null;
  }
};

export default FileViewer;
