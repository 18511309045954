// react
import React, { useEffect } from "react";

// context
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// qrcode.react
import { QRCodeSVG } from "qrcode.react";

// @mui
import { Box, SwipeableDrawer, Dialog, DialogTitle, DialogContent, DialogActions, Grid, Button, List, ListItem, ListItemText, IconButton, Typography, useMediaQuery, Tooltip } from "@mui/material";
import { CloseOutlined as CloseOutlinedIcon, Print as PrintIcon } from "@mui/icons-material";

// ui-components
import { Puller } from "ui-components/Puller";

// A ---------------------------------------------------------------------- M

const getButtonStyle = (action) => ({
  py: 0.5,
  borderColor: "divider",
  color: action === "close" ? "error.main" : "primary.main",
  textTransform: "none",
  boxShadow: "none",
  borderRadius: 10,
  "&:hover": {
    borderColor: action === "close" ? "error.dark" : "primary.dark",
    backgroundColor: "action.hover",
  },
});

const TagQRCode = ({ tag, open, setOpen }) => {
  const { conservSostL1 } = UserAuth();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  // Function to print the QR code and instructions
  const handlePrint = () => {
    const printWindow = window.open("", "", "width=600,height=400");
    const qrCode = document.querySelector("#qr-code-svg");
    printWindow.document.open();
    printWindow.document.write(`
      <html>
        <head>
          <title>Print Tag QR Code</title>
          <style>
            @media print {
              body * { visibility: hidden; }
              #qr-code-svg, #qr-code-svg * { visibility: visible; }
              #qr-code-svg { position: absolute; left: 250px; top: 50px; }
            }
            .close-button {
              position: fixed;
              top: 20px;
              right: 20px;
              padding: 12px 24px;
              font-size: 45px;
              font-weight: bold;
              background-color: #e0e0e0;
              border: none;
              cursor: pointer;
            }
          </style>
        </head>
        <body>
          <button class="close-button" onclick="window.close()">Close window</button>
          ${qrCode.outerHTML}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
    setOpen(false);
  };

  useEffect(() => {
    const handlePopstate = () => {
      if (open) {
        setOpen(false);
      }
    };
    window.addEventListener("popstate", handlePopstate);
    return () => window.removeEventListener("popstate", handlePopstate);
  }, [open, setOpen]);

  // Instructions and QR code layout (common for mobile and desktop)
  const printForm = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1">{t("qr_code_steps_1")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">{t("qr_code_steps_2")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">{conservSostL1 ? t("qr_code_steps_3") : t("qr_code_steps_4")}</Typography>
      </Grid>
      <Grid item container justifyContent="center" sx={{ mt: 2 }}>
        <QRCodeSVG id="qr-code-svg" style={{ height: 200, width: 200 }} value={`${process.env.REACT_APP_ENVIRONMENT_URL}/${tag}`} />
      </Grid>
    </Grid>
  );

  // Mobile version using SwipeableDrawer
  if (isMobile) {
    return (
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        sx={{
          "& .MuiDrawer-paper": {
            width: "100%",
            height: "90%",
            borderTopLeftRadius: "4%",
            borderTopRightRadius: "4%",
          },
        }}
      >
        <Puller />
        <Box sx={{ p: 3 }}>
          <Typography variant="h6" fontWeight="bold" textAlign="center" mb={3} color="text.primary">
            {t("qr_code")}
          </Typography>
          {printForm}
          <Grid container spacing={1} mt={3}>
            <Grid item xs={12}>
              <Button variant="contained" fullWidth startIcon={<PrintIcon />} onClick={handlePrint} sx={getButtonStyle("open")}>
                {t("print")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </SwipeableDrawer>
    );
  }

  // Desktop version using Dialog
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="sm"
      fullWidth
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "15px",
          p: 1,
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
        },
      }}
    >
      <DialogTitle>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h6" fontWeight="bold">
              {t("qr_code")}
            </Typography>
          </Grid>
          <Grid item>
            <Tooltip title={t("close")} placement="top">
              <IconButton onClick={() => setOpen(false)} color="error" edge="end">
                <CloseOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>{printForm}</DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => setOpen(false)} sx={getButtonStyle("close")}>
          {t("close")}
        </Button>
        <Button variant="outlined" onClick={handlePrint} sx={getButtonStyle("open")}>
          {t("print")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TagQRCode;
