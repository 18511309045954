// react
import React from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import { Box, Grid, Typography, Divider, CardContent } from "@mui/material";

// A ---------------------------------------------------------------------- M

const CustomerDetails = ({ client }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <CardContent sx={{ p: 0 }}>
        <Grid container spacing={3}>
          {/* Company Information */}
          <Grid item xs={12}>
            <Typography variant="subtitle1" fontWeight="bold" color="text.secondary" gutterBottom>
              {t("company")}
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Grid container spacing={1}>
              <Grid item xs={12} md={4}>
                <Typography variant="body2">
                  <strong>{t("company_name")}:</strong> {client.companyName || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="body2">
                  <strong>{t("vat_number")}:</strong> {client.VATNumber || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="body2">
                  <strong>{t("email")}:</strong> {client.email || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="body2">
                  <strong>{t("phone")}:</strong> {client.phone || "N/A"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {/* Administrator Information */}
          <Grid item xs={12}>
            <Typography variant="subtitle1" fontWeight="bold" color="text.secondary" gutterBottom>
              {t("administrator")}
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Grid container spacing={1}>
              <Grid item xs={12} md={4}>
                <Typography variant="body2">
                  <strong>{t("first_name")}:</strong> {client.adminUser?.firstName || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="body2">
                  <strong>{t("last_name")}:</strong> {client.adminUser?.lastName || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="body2">
                  <strong>{t("email")}:</strong> {client.adminUser?.email || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="body2">
                  <strong>{t("phone")}:</strong> {client.adminUser?.phone || "N/A"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {/* Address Information */}
          <Grid item xs={12}>
            <Typography variant="subtitle1" fontWeight="bold" color="text.secondary" gutterBottom>
              {t("address")}
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Grid container spacing={1}>
              <Grid item xs={12} md={4}>
                <Typography variant="body2">
                  <strong>{t("street")}:</strong> {client.address?.street || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="body2">
                  <strong>{t("city")}:</strong> {client.address?.city || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="body2">
                  <strong>{t("postal_code")}:</strong> {client.address?.postalCode || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="body2">
                  <strong>{t("country")}:</strong> {client.address?.country || "N/A"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Box>
  );
};

export default CustomerDetails;
